<template>
  <div
    ref="carouselMainContainer"
    class="base-exhibition-carousel"
  >
    <!-- MAIN CONTAINER -->
    <div class="carousel-full-width-container">
      <!-- CENTRAL AREA -->
      <div
        v-if="items.length > 0"
        ref="centralArea"
        class="central-area"
      >
        <div 
          ref="slideshowContent"
          class="slideshow-content"
          :style="{
            marginLeft: currentLeftOffset
          }"
        >
          <div 
            v-for="(slide, slideIdx) in slides"
            :key="'slidec'+slideIdx"
            class="slide-container"
          >
            <figure
              :key="'slide'+slideIdx"
              :class="[
                'slide',
                { 'slide-active': slideIdx == currentIndex }
              ]"
            >
              <div class="calendar-container">
                <p>{{ slide.startDate }}</p>
                <p>
                  <img
                    class="calendar-arrow"
                    src="../../assets/img/svgicons/arrow-right.svg"
                  >
                  {{ slide.endDate }}
                </p>
              </div>
              <div class="details-container">
                <img
                  v-if="slide.media && slide.media.picture"
                  class="slide-img"
                  :src="slide.media.picture.web"
                  alt="TODO"
                >
                <div
                  v-else
                  class="img-placeholder"
                />
                <figcaption>
                  <h3 class="slide-title">
                    {{ slide.name }}
                  </h3>
                  <p class="slide-description">
                    {{ slide.description }}
                  </p>
                  <BaseCTAtxt
                    label="Discover exhibition"
                    @click="onExhibitionClick(slide.id)"
                  />
                </figcaption>
              </div>
            </figure>
          </div>
        </div>

        <div class="controls">
          <div class="page-current">
            {{ currentSlide }} / {{ items.length }}
          </div>
          <div class="arrows">
            <div
              class="arrow"
              @click="showPreviousPicture"
            >
              <img src="../../assets/img/svgicons/arrow-left.svg">
            </div>
            <div
              class="arrow"
              @click="showNextPicture"
            >
              <img src="../../assets/img/svgicons/arrow-right.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseExhibitionCarousel',
  props: {
    /**
     * An array of 'pictures' Objects like
     * `{src: <url>, caption: <string>, href: <url>}`
     */
    items: {
      type: Array,
      required: true,
      default: ()=>{ return null }
    },
    /**
     * If set to true, slide show will cycle
     * as defined by timer autoSlideInterval 
     */
    autoSlide: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Time before showing next slide in milliseconds
     * `if ( autoSlide == true )`
     */
    autoSlideInterval: {
      type: Number,
      required: false,
      default: 3000
    },
    /**
     * Time before first automatic slide change.
     * Set to 0 to disable this delay.
     */
    autoSlideDelay: {
      type: Number,
      required: false,
      default: 1000
    }
  },
  data: function () {
    return {
      currentIndex: 0,
      autoSlideTimer: {},
    }
  },
  computed: {
    currentSlide: function() { 
      return this.currentIndex + 1
    },
    slides: function(){
      let s = [...this.items]
      /* on desktop we add a former element */
      if (!this.isSmall){
        let t = s.pop()
        s.unshift(t)
        s = [...s, t, s[0]]
      }
      s = s.map(slide=>{
        slide.startDate = this.getFormattedDate(slide.start_date)
        slide.endDate = this.getFormattedDate(slide.end_date)
        return slide
      })
      return s
    },
    nextIndex: function(){
      return (this.currentIndex + 1) % this.items.length
    },
    previousIndex: function(){
      return ((this.currentIndex - 1) % this.items.length + this.items.length) 
        % this.items.length
    },
    isMobile: function () {
      if (!this.$store) return false /* for storybook */
      return this.$store.state.ui.isMobile
    },
    isSmall: function () {
      if (!this.$store) return false /* for storybook */
      return this.$store.state.ui.isSmall
    },
    currentLeftOffset: function () {
      if (this.isSmall){
        return `0px`
      } else {
        return `calc(calc(0px - var(--default-h-padding) - 100%) * ${1 + this.currentIndex})`
      }
    }
  },
  mounted: function(){
    this.adjustSlidesWidth()
    addEventListener('resize', this.adjustSlidesWidth)
    if (this.autoSlide){
      setTimeout(this.initAutoSlide, this.autoSlideDelay)
    }
  },
  beforeDestroy: function (){
    clearTimeout(this.autoSlideTimer)
    removeEventListener('resize', this.adjustSlidesWidth)
  },
  methods: {
    showThisPicture: function (index){
      this.currentIndex = index
    },
    showNextPicture: function (){
      this.currentIndex = this.nextIndex
      if (this.isSmall){
        this.scrollBy(1)
      }
    },
    showPreviousPicture: function (){
      this.currentIndex = this.previousIndex
      if (this.isSmall){
        this.scrollBy(-1)
      }
    },
    initAutoSlide: function () {
      this.autoSlideTimer = setInterval(()=>{
        this.showNextPicture()
      }, this.autoSlideInterval)
    },
    onExhibitionClick: function (exhibId) {
      this.$router.push({name: 'DetailsView', params:{modelName:'event', id:exhibId }})
    },
    getFormattedDate: function (d){
      d = new Date(d)
      d = d.toLocaleDateString("en", {year: '2-digit', month: 'numeric', day: 'numeric'})
      return d.replaceAll('/','.')
    },
    adjustSlidesWidth: function (){
      if (this.isMobile || this.isSmall) return null
      this.$nextTick(()=>{
        const w = this.$refs.centralArea.offsetWidth + 'px'
        document.querySelectorAll('.slide').forEach(el=>{
          el.style.width = w 
        })
      })
    },

    scrollBy: function (idx){
      this.$refs.slideshowContent.scrollBy({
        left: idx * this.$refs.centralArea.offsetWidth,
        behavior: 'smooth'
      })
    }
  }
}
</script>

<style scoped>
.carousel-full-width-container{
  /* --carousel-width: 1105px; */
  --slide-height: 343px;
  --controls-width: 90px;
  --image-width: 457px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px var(--default-h-padding) var(--controls-width) var(--default-h-padding);
  border-top: 1px solid var(--ruler-color);
}
.central-area{
  position: relative;
  box-sizing: border-box;
}
.slideshow-content{
  display: flex;
  transition: .25s ease-in-out;
}
.slide-container{
  /* border-top: 1px solid var(--ruler-color); */
}
.slide{
  box-sizing: border-box;
  margin-right: 30px;
  display: flex;
}
.slide-img{
  /* width: 100%; */
  width: var(--image-width);
  height: var(--slide-height);
  object-fit: cover;
}
.details-container{
  display: flex;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--ruler-color);
  border-left: 1px solid var(--ruler-color);
  flex-grow: 1;
  width: 69%;
}
.slide figcaption{
  margin-left: 45px;
}
.slide-container.slide-active{

}
.slide-container:last-child{

}
.calendar-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(25% + 1px);
  height: var(--slide-height);
  box-sizing: border-box;
  padding-top: 80px;
  padding-left: 50px;
  font-family: 'Filson Pro Regular';
  font-size: var(--h2);
  line-height: var(--h2-lh);
}
.calendar-arrow{
  width: 25px;
  height: 20px
}
.controls{
  position: absolute;
  border: var(--default-ruler);
  bottom: calc(-1px - var(--controls-width) / 2);
  left: calc(25% - var(--controls-width));
  width: var(--controls-width);
  background-color: var(--white);
  user-select: none;
}
.page-current{
  height: var(--controls-width);
  line-height: var(--controls-width);
  vertical-align: middle;
  text-align: center;
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  letter-spacing: var(--h7-ls);
}
.arrows{
  border-top: 1px solid var(--ruler-color);
  display: flex;
}
.arrow{
  width: calc(var(--controls-width) / 2);
  height: calc(var(--controls-width) / 2);
  display: flex;
  justify-content: center;  
  align-items: center;
  cursor: pointer;
}
.arrow:first-child{
  border-right: 1px solid var(--ruler-color);
}
.arrow:hover{
  background-color: var(--water-blue);
}
.arrow:hover img{
  filter: invert(1);
}
.details-container figcaption{

}
.slide-title{
  font-family: 'Filson Pro Bold';
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1.08px;
  margin-bottom: 12px;
}
.slide-description{
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  margin-bottom: 30px;
}
/* WE GOT NO WAY OF RETRIEVING EXHIBITION PICTURE FROM SET
SHUOLD WE GET IT BY PINGING EXHIBID ? */
.img-placeholder{
  width: var(--image-width);
  height: var(--slide-height);
  background-color: var(--grey-medium);
}

/*
.-.              . .     .-.    .    .     .        .     
(   )             | |    (   )   |\  /|     |     o  |     
 `-. .--.--. .-.  | |    .--'    | \/ | .-. |.-.  .  | .-. 
(   )|  |  |(   ) | |   (   `.)  |    |(   )|   ) |  |(.-' 
 `-' '  '  `-`-'`-`-`-   `---'`  '    ' `-' '`-'-' `-`-`--'
 */
.small-theme .carousel-full-width-container{
  padding: 0px;
  border: none;
  width: 100%;
  --image-width: 100%;
}
.small-theme .central-area{
  width: 100%;
}
/* ---- slideshow content  ---- */
.small-theme .slideshow-content{
  width: var(--full-width);
}
.small-theme .slideshow-content{
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}
.small-theme .slideshow-content::-webkit-scrollbar {
  height: 8px;
}
.small-theme .slideshow-content::-webkit-scrollbar-track {
  background-color: var(--grey-medium);
}
.small-theme .slideshow-content::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}
.small-theme .slideshow-content{
  scrollbar-width: thin; /* pixel values still bug */
  scrollbar-color: var(--primary-color) var(--grey-medium);
}
/* ---- SLIDE container  ---- */
.small-theme .slide-container{
  scroll-snap-align: center;
}
.small-theme .slide{
  position: relative;
  margin-right: 0px;
  width: var(--full-width);
}
.small-theme .slide-description{
  display: none;
}
.small-theme .slide figcaption{
  margin-top: 15px;
  margin-left: 0px;
  width: 100%;
}
.small-theme .calendar-container{
  position: absolute;
  left: calc(100% / 3);
  width: calc(100% / 3);
  height: auto;
  margin: 0px;
  padding: 15px;
  border: var(--default-ruler);
  background-color: var(--white);
  font-size: var(--h7);
  line-height: var(--h7);
  align-items: center;
}
.small-theme .calendar-arrow{
  width: 20px;
  height: 15px;
}
.small-theme .details-container{
  flex-direction: column;
  border: none;
  padding: var(--default-h-padding) 0px;
  width: 100%;
}
/* ---- CONTROLS ---- */
.small-theme .controls{
  top: calc(50% - calc(var(--slide-height) / 4) + var(--controls-width) / 2);
  left: 0px;
  height: calc(var(--controls-width) / 2);
  width: 100%;
  border: none;
  background-color: transparent;
}
.small-theme .controls .page-current{
  display:none;
  border: none;
}
.small-theme .controls .arrows{
  border-top: none;
  position: relative;
}
.small-theme .arrow{
  position: absolute;
  border: var(--default-ruler);
  background-color: var(--white);
}
.small-theme .arrow:hover{
  background-color: var(--primary-color);
}
.small-theme .arrow:last-child{
  right: 0px;
}
</style>
