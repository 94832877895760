<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.artist"
      field-type="string"
      label="Artist"
      disabled
    />
    <BaseInputContainer
      v-model="item.tax_receipt"
      field-type="select"
      label="Tax receipt (when possible)"
      placeholder="Pick a choice"
      :options="TAX_RECEIPT_CHOICES"
      @update="onFieldUpdate"
      @input="onFieldInput"
    />
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: 'TaxReceiptForm',
  mixins: [modelForms],
  props: {},
  computed: {},
  methods: {}
}
</script>

<style src="../../assets/css/forms.css" scoped></style>

<style scoped>
section {
    display: flex;
    flex-direction: row;
}
section .base-input-container{
    flex: 1 0 0;
}
</style>
