<template>
  <section
    v-if="item"
    :class="['metadata-form', { fold: fold }, { 'no-fold': !fold }]"
  >
    <header>
      <h2>Metadata</h2>
      <div
        class="fold-button"
        @click="fold = !fold"
      >
        <img
          v-if="fold"
          src="../base/assets/img/svgicons/maximize-2.svg"
        >
        <img
          v-else
          src="../base/assets/img/svgicons/minimize-2.svg"
        >
      </div>
      <!-- <div class="details"> -->
      <!-- <p>{{ hintUpdateSeo }} - {{ fold }}</p> -->

      <!-- </div> -->
    </header>
    <section class="foldable-content">
      <section class="metadata-status">
        <h3>Publication</h3>
        <BaseInputContainer
          v-model="item.status"
          placeholder="Choose a status..."
          field-type="select"
          label="Status"
          help-text="Item status (admin)"
          :options="statusChoices"
          :required="fields.status.required"
          :errors="errors.status"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
    </section>
  </section>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'
// import { mapState } from 'vuex'

export default {
  name: 'MetadataForm',
  mixins: [modelForms],
  props: {
    hintUpdateSeo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      fold: true,
    }
  },
  computed: {
    fields: function () {
      return this.$parent.availableFields.metadata.children
    },
    statusChoices: function() {
      return this.$store.state.choices.STATUS_CHOICES
    },
  },
  mounted: function () {
    if (Object.keys(this.errors).length) {
      this.fold = false
    }
  }
}
</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>
.metadata-form {
  border: var(--default-admin-border);
  padding: 10px;
  margin-bottom: var(--field-v-margin);
}
.metadata-form.fold {
  align-items: center;
}
.metadata-form header h2 {
  margin-bottom: var(--field-v-margin);
}
.metadata-form.fold header h2 {
  margin-bottom: 0px;
}
.form section .metadata-status {
  padding-bottom: 50px;
}
.fold .foldable-content {
  overflow: hidden;
  margin: 0px;
  animation: 0.1s fold ease-in-out forwards;
}
.no-fold .foldable-content {
  overflow: visible;
  animation: 0.1s unfold ease-in-out forwards;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

h3 {
  display: flex;
  align-items: center;
  /* width: 30%; */
}
.fold-button {
  /* margin: 0 10px; */
  cursor: pointer;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fold-button img {
  width: 20px;
}
.fold-button:hover {
  filter: invert(1);
  box-shadow: 0px 0px 5px #707070;
}
.hint {
  font-family: 'Rubik Regular';
  display: flex;
  align-items: center;
  background-color: #fff;
  animation: 1s flashout;
}
.hint img {
  width: 20px;
  margin: 20px;
}
@keyframes flashout {
  0% {
    filter: invert(1);
  }
  100% {
    filter: invert(0);
  }
}
@keyframes fold {
  0% {
    height: auto;
  }
  1% {
    height: 800px;
  }
  100% {
    height: 0px;
  }
}
@keyframes unfold {
  0% {
    height: 0px;
  }
  99% {
    height: 800px;
  }
  100% {
    height: auto;
  }
}
</style>
