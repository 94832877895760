import Vue from 'vue'
import VueRouter from 'vue-router'

import AccountRedirection from '@/views/AccountRedirection'
import Dashboard from '@/views/Dashboard'
import DetailsView from '@/views/DetailsView'
import ImporterView from '@/views/ImporterView'
import ListView from '@/views/ListView'
import Login from '@/views/Login'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/account_redirection',
    name: 'AccountRedirection',
    component: AccountRedirection,
    props: (route) => ({
      accountProcess: route.query.process,
      tk1: route.query.tk1,
      tk2: route.query.tk2,
      tkd: route.query.tkd,
      token: route.query.token,
      ok: route.query.ok,
    }),
  },
  // Artists
  {
    path: '/artists',
    name: 'artist-list',
    component: ListView,
    props: {
      model: 'artist',
    },
  },
  {
    path: '/artist/:id',
    name: 'artist-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'artist',
      id: route.params.id,
    }),
  },
  // Artworks
  {
    path: '/artworks',
    name: 'artwork-list',
    component: ListView,
    props: {
      model: 'artwork',
    },
  },
  {
    path: '/artwork/new',
    name: 'artwork-creation',
    component: DetailsView,
    props: {
      modelName: 'artwork',
      creation: true,
      id: null,
    },
  },
  {
    path: '/artwork/:id',
    name: 'artwork-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'artwork',
      id: route.params.id,
    }),
  },
  /* TRANSACTIONS */
  {
    path: '/transactions',
    name: 'transaction-list',
    component: ListView,
    props: {
      model: 'transaction'
    }
  },
  {
    path: '/transaction/:id',
    name: 'transaction-view',
    component: DetailsView,
    props: route => ({
      modelName: 'transaction',
      id: route.params.id
    })
  },  
  {
    path: '/import',
    name: 'importer-view',
    component: ImporterView,
    props: (route) => ({
      modelName: 'artwork',
    }),
  },
  // Galleries
  {
    path: '/my-gallery/:id',
    name: 'gallery-view',
    component: DetailsView,
    props: (route) => ({
      modelName: 'gallery',
      id: route.params.id,
    }),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// router.beforeEach((to, from, next) => {
//   window.scrollTo(0,0)
//   next()
// })

export { routes, router }
