export const columns = {
  artist: {
    portrait: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    status: {
      label: 'Status',
      sortable: false,
    },
  },
  artwork: {
    portrait: {
      label: '',
      sortable: false,
    },
    title: {
      label: 'Title',
      sortable: true,
    },
    artist: {
      label: 'Artists',
      sortable: true,
    },
    status: {
      label: 'Status',
      sortable: true,
    },
  },
  event: {
    portrait: {
      label: '',
      sortable: false,
    },
    name: {
      label: 'Name',
      sortable: true,
    },
    artists: {
      label: 'Artists',
      sortable: false,
    },
    galleries: {
      label: 'Galleries',
      sortable: false,
    },
    institutions: {
      label: 'Institutions',
      sortable: false,
    },
  },
  transaction: {
    picture: {
      label: '',
      sortable: false,
    },
    status: {
      label: 'Status',
      sortable: false,
    },
    total: {
      label: 'Total price',
      sortable: true,
    },
    description: {
      label: 'Artwork',
      sortable: false,
    },
    date: {
      label: 'Transaction date',
      sortable: true,
    },
    paymentLink: {
      label: 'Payment',
      sortable: false
    }
    // artwork: {
    //   label: 'Artwork',
    //   sortable: false,
    // },
    // project: {
    //   label: 'Pledge to',
    //   sortable: false,
    // },
  },  
}
