<!--
    .                   ,.     .             ,--,--'      
    |-. ,-. ,-. ,-.    / |   ,-| ,-,-. . ,-. `- | ,-. ,-. 
    | | ,-| `-. |-'   /~~|-. | | | | | | | |  , | ,-| | | 
    ^-' `-^ `-' `-' ,'   `-' `-^ ' ' ' ' ' '  `-' `-^ `-| 
                                                      ,| 
                                                      `' 
-->
<template>
  <div
    :class="[
      'base-admintag-container',
      { disabled: disabled },
      ...additionalStyles,
    ]"
    :title="helpText"
    @click="onClick"
  >
    <!-- TAG CONTENT CONTAINER -->
    <div class="label">
      <!-- LABEL IS A SHORT TEXT -->
      <span>{{ textContent }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTag',
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {
        return null
      },
    },
    /**
     * Disabled tags won't allow any user interaction.
     * No event will be emitted.
     * (from CSS, look at property `pointer-events: none;`)
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * active state
     */
    tagType: {
      type: String,
      required: false,
      default: '',
    },
  },
  // data: function () { return {}; },
  computed: {
    isPublicationStatus: function () {
      return this.tagType == 'publication-status'
    },
    isItemStatus: function () {
      return this.tagType == 'item-status'
    },
    isTransactionStatus: function () {
      return this.tagType == 'transaction-status'
    },
    isAvailabilityStatus: function () {
      return this.tagType == 'availability-status'
    },
    additionalStyles: function () {
      if (this.isPublicationStatus) {
        return this.item.metadata.published
          ? 'status-published'
          : 'status-not-published'
      }
      if (this.isItemStatus){
        return `status-${this.item.metadata.status}`
      }
      if (this.isTransactionStatus){
        return 'transaction-status'
        // return `status-trans-${this.item.status}`
      }
      if (this.isAvailabilityStatus){
        return 'availability-status'
      }
      return []
    },
    textContent: function () {
      if (this.isPublicationStatus) {
        return this.item.metadata.published ? 'Published' : 'Not published'
      }
      if (this.isItemStatus) {
        if (!this.$store) return 'mock'
        return this.$store.state.choices.STATUS_CHOICES.find(
            (c)=> c.value == this.item.metadata.status
          ).display_name
      }
      if (this.isTransactionStatus) return this.item.status
      if (this.isAvailabilityStatus){
        if (!this.$store) return 'mock'
        return this.$store.state.choices.AVAILABILITY_CHOICES.find(
            (c)=> c.value == this.item.availability
          ).display_name
      }
      return ''
    },
    helpText: function () {
      if (this.isPublicationStatus) return 'publication status'
      if (this.isItemStatus) return 'item status'
      if (this.isTransactionStatus) return 'transaction status'
      if (this.isAvailabilityStatus) return 'availability status'
      return ''
    }
  },
  // mounted: function(){},
  methods: {
    onClick: function () {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style src="../assets/css/base.css" scoped></style>

<style scoped>
.base-admintag-container {
  --tag-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 5px 10px;
  height: calc(1.5 * var(--paragraph));
  border-radius: 40px;
  color: var(--tag-color);
  font-family: 'Filson Pro Regular';
  font-size: var(--h7);
  border: 1px solid;
  border-color: var(--tag-color);
  /* cursor: pointer; */
  user-select: none;
}
/* ---- disabled ---- */
.base-admintag-container.disabled {
  pointer-events: none;
  color: var(--disabled-color);
  background-color: var(--disabled-secondary-color);
  border: 1px solid var(--disabled-color);
}
/* ------------- TAG TYPES ------------ */
/* --- publication-status --- */
.status-published {
  --tag-color: var(--green);
}
.status-not-published {
  --tag-color: var(--disabled-color);
}
/* --- item-status --- */
.status-D,
.status-V{
  --tag-color: var(--disabled-color);
}
.status-E{
  --tag-color: var(--red);
}
/* ---- transaction status ---- */
.transaction-status{
  --tag-color: #000;
}
</style>
