<template>
  <div
    class="message-row text-cdt"
    :class="[
      { 'remote-user': isRemoteUser },
      { 'system-user': isSystemUser }
    ]"
  >
    <div class="message-timestamp">
      {{ timestamp }}
    </div>
    <div class="message-container">
      <div class="avatar">
        <img
          v-if="message.source == 'U'"
          src="@/base/assets/img/svgicons/user.svg"
        >
        <!-- <img
          v-else
          src="@/base/assets/img/svgicons/activity.svg"
        > -->
      </div>
      <div class="standard-message-container">
        <div class="message-details">
          <div class="message-author">
            {{ userName }}
          </div>
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="message-content text-cdt"
          disabled
          v-html="message.message"
        />
        <!--eslint-enable-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseComment',
  props: {
    message: {
      type: Object,
      required: false,
      default: () => {
        return null
      },
    },
    currentUsername: {
      type: String,
      required: true,
      default: null,
    },
  },
  computed: {
    isSystemUser: function (){
      return this.message.source == 'S'
    },
    isRemoteUser: function (){
      const sameUser = this.message.user.username == this.currentUsername
      const isUser = this.message.source == 'U'
      return isUser && !sameUser
    },
    timestamp: function () {
      const d = new Date(this.message.date)
      return d.toLocaleTimeString()
    },
    userName: function () {
      if (this.message.user.first_name || this.message.user.last_name) {
        return `${this.message.user.first_name} ${this.message.user.last_name}`
      }
      return this.message.user.username
    },
  },
}
</script>


<style scoped>
.message-row {

}
.remote-user.message-row {
  align-self: flex-end;
}
.system-user.message-row{
  align-self: center;
}
.message-container{
  display: flex;
}

.avatar {
  width: 16px;
  height: 16px;
  padding: 5px;
  min-width: 16px;
  background-color: var(--bg-color);
  border: var(--default-ruler);
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 5px;
}
.avatar img {
  width: 100%;
  object-fit: contain;
  opacity: 0.5;
}
.remote-user .avatar {
  order: 2;
  margin-left: 5px;
  margin-right: 0px;
}
.system-user .avatar{
  display: none;
}
.standard-message-container {
  background-color: var(--grey-medium);
  padding: 10px;
  border-radius: 12px 12px 12px 0px;
  box-sizing: border-box;
  margin-right: 20px;
}
.remote-user .standard-message-container {
  margin-right: initial;
  margin-left: 20px;
  /* color: white; */
  border-radius: 12px 12px 0px 12px;
  background-color: var(--water-blue-light);
  filter: saturate(0.2);
}
.system-user .standard-message-container{
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 12px;
  background-color: transparent;
  border: var(--default-ruler);
  text-align: center;
  color: var(--grey-dark);
}
.message-timestamp {
  font-family: 'Rubik Light';
  font-size: 80%;
  /* text-transform: lowercase; */
  text-align: center;
  /* margin-left: -20px; */
  /* border-bottom: 1px solid var(--grey-dark); */
}
.remote-user .message-timestamp{
  /* text-align: left; */
}
.message-author {
  font-family: 'Rubik Medium';
  /* margin-bottom: 0.5rem; */
}
.message-content {
  white-space: pre-wrap;
}
</style>
