<template>
  <div
    class="base-file-upload"
    @click="onBoxClick"
  >
    <input
      id="file-input"
      ref="fileInput"
      type="file"
      @change="onFileChange"
    >
    <img src="../../assets/img/svgicons/file.svg">
    <p v-if="uploadedFileName">
      {{ uploadedFileName }}
    </p>
    <p v-else>
      Choose file
    </p>
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'
export default {
  name: 'BaseFileUpload',
  mixins: [baseinputs],
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return null
      },
    },
  },
  data: function () {
    return {
      uploadedFileName: ''
    }
  },
  methods: {
    onBoxClick: function () {
      this.$refs.fileInput.click()
    },
    onFileChange: function (event) {
      console.debug('On upload')
      console.debug(event)
      let files = event.target.files || event.dataTransfer.files
      if (!files.length) {
        console.debug('no files')
      } else {
        let fr = new FileReader()
        fr.onloadend = (e) => {
          console.debug('file loadend', files[0].name)
          this.$emit('update', { file: e.target.result, name: files[0].name })
          this.uploadedFileName = files[0].name
        }
        fr.readAsDataURL(files[0])
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>

<style scoped>
.base-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  border: var(--default-ruler);
  cursor: pointer;
  box-sizing: border-box;
  height: 2.5rem;
}
.base-file-upload p {
  margin: 0 .5em;
}

input {
  display: none;
}
</style>
