<template>
  <div
    v-if="item"
    :class="['base-map']"
  >
    <template>
      <l-map
        style="height: 300px"
        :zoom="zoom"
        :center="center"
      >
        <l-tile-layer
          :url="url"
          :attribution="attribution"
        />
        <l-marker
          v-if="locations.length > 0"
          :lat-lng="locations"
          :icon="markerOptions.icon"
        >
          <l-popup>
            <div>
              {{ item.address1 }}
            </div>
          </l-popup>
        </l-marker>
        <div
          v-else
          id="map-display-informations"
        >
          <h3 class="title-level-3">
            Unable to load location
          </h3>
          <p class="text-cdt">
            Please verify the first address ligne
          </p>
        </div>
      </l-map>
    </template>
  </div>
</template>

<script>
import { latLng, Icon, DivIcon, Point, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet'

export default {
  name: 'BaseMap',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  data: function () {
    return {
      zoom: 3,
      center: latLng(25.41322, -21.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      locations: [],
    }
  },
  computed: {
    /**
     * Put an icon
     */
    markerOptions: function () {
      let options = {}
      const customicon = icon({
        iconUrl: require('../assets/img/map/marker/marker.png'),
        shadowUrl: require('../assets/img/map/shadow.png'),
        iconSize: [40, 50],
        iconAnchor: [20, 55],
        shadowSize: [20, 6],
        shadowAnchor: [10, 3],
      })
      options = {
        icon: customicon,
      }
      return options
    },
  },
  created: function () {
    // TODO MICKA UPDATE THIS WHEN BACK IS UP
    this.initLocations()
  },
  methods: {
    /**
     * Example result "SRID=4326;POINT (10 10)"
     * Here created function need to take the numbers in parentheses so it will be use regex and parseFloat to get number of the string
     * if POINT is EMPTY, it displayed "Unable to load location"
     */
    initLocations: function () {
      const isLocR = /\((.*)\)/
      const valueR = /[+-]?\d+(\.\d+)?/g
      if (this.item.location) {
        const valueLocation = this.item.location.match(isLocR)
        if (valueLocation) {
          this.locations = valueLocation[1].match(valueR)
            .map((value) => {
              return parseFloat(value)
            })
          this.center = this.locations
          this.zoom = 17
        }
      }
    }
  },
}
</script>

<style src="../assets/css/base.css" scoped></style>

<style scoped>
@import url('~leaflet/dist/leaflet.css');
</style>

<style scoped>
.base-map {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  margin-right: var(--field-h-margin);
  margin-bottom: var(--field-v-margin);
}
#map-display-informations {
  background-color: #ffffffb5;
  position: absolute;
  z-index: 401;
  top: 40px;
  width: 100%;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
