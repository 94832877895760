/*
 ______   __     __         ______   ______     ______     ______    
/\  ___\ /\ \   /\ \       /\__  _\ /\  ___\   /\  == \   /\  ___\   
\ \  __\ \ \ \  \ \ \____  \/_/\ \/ \ \  __\   \ \  __<   \ \___  \  
 \ \_\    \ \_\  \ \_____\    \ \_\  \ \_____\  \ \_\ \_\  \/\_____\ 
  \/_/     \/_/   \/_____/     \/_/   \/_____/   \/_/ /_/   \/_____/ 
                                                                     
Add all the recurring String and Objects transformations here.
Filters are loaded and installed in Vue as a "helpers" plugin from /src/helpers/index.js
*/

const truncate = function (value, maxLength = 100, suffix = '...') {
  if (!value) return ''
  if (value.length > maxLength) {
    const text = value.substring(0, maxLength) + suffix
    return text
  }
  return value
}

/**
 * Best pluralize function in the whole world.
 * @param {string} words - a word which will get an additional s
 * @param {Array} [words] - or an array like ['country, 'countries']
 * @param {number} quantity - how many of these ?
 * @returns {string} - pluralized word
 */
const pluralize = function (words, quantity) {
  const q = Math.abs(quantity)
  if (typeof words == 'string') {
    return (q > 1) ? `${words}s` : words
  }
  if (words && words.length > 1) {
    return `${words[(q > 1) ? 1 : 0]}`
  }
  return '(invalid)'
}

/**
 * Formats a PhysicalState object into a string.
 * Default behaviour is to display only SI units
 * This can be changed using si and uk parameters
 * @param {Object} dims - a PhysicalState object
 * @param {boolean} [si=true] - should display in SI (cm)
 * @param {boolean} [uk=false] - should display in Imperial units 
 * @returns 
 */
const formatDimensions = function (dims, si = true, uk = false) {
  let str = ''
  if (si == true) {
    const cmstr =
      [dims.width_cm, dims.height_cm, dims.length_cm]
        .filter((dim) => dim != 0)
        .join(' × ')
    if (cmstr.length > 0)
      str += cmstr + ' cm'
    else
      return ''
  }
  if (si == true && uk == true) {
    str += ' | '
  }
  if (uk == true) {
    const instr =
      [dims.width_in, dims.height_in, dims.length_in]
        .filter((dim) => dim != 0)
        .join(' × ')
    if (instr.length > 0)
      str += instr + ' in.'
    else
      return ''
  }
  return str
}

/**
 * Formats a Number for american standard. 
 * Read more here: 
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 * @param {number} quantity 
 * @param {number} [decimals=2] - maximum number of decimals to display (default 2)
 * @param {Array} [forms] - an optional array of words like ['country', 'countries']
 * @returns 
 */
const formatNumber = function (quantity, decimals = 2, forms = null) {
  let parts = []

  try {
    parts = [
      new Intl.NumberFormat('en-US', {
        maximumFractionDigits: decimals
      }).format(quantity)
    ]
  } catch {
    parts = [quantity]
  }

  if (forms != null) {
    parts.push(quantity > 1 ? forms[1] : forms[0])
  }
  const str = parts.join(' ')
  return str
}

/**
 * formats a price for american standard 
 * @param {number} value - the price to format
 * @param {string} [currency="EUR"] - a currency code ISO format
 * @param {number} [decimals=0] - how many decimals to the price
 * @returns {string} - the formatted price value as string
 */
const formatPrice = function (value, currency = 'EUR', decimals = 0) {
  try {
    const s = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: decimals
    }).format(value)
    return s
  } catch {
    try {
      // return currency + " " + formatNumber(value, decimals)
      return `${currency} ${formatNumber(value, decimals)}`
    } catch {
      // return currency + " " + value
      return `${currency} ${value}`
    }
  }
}

/**
* TODO : add documentation
*/
const formatEdition = function(details, edition) {
  if (edition == 'U') return 'Unique'
  if (edition == 'L') {
    let editions = ''
    let aps = ''
    if (details.num_edition > 0) {
      editions = `Edition ${details.num_edition}/${details.total_edition}`
      aps = (details.total_ap > 0) ? ` + ${details.total_ap} ` + pluralize("AP", details.total_ap) : ''
      return `${editions}${aps}`
    }
    if (details.total_edition > 1) {
      editions = `(edition of ${details.total_edition})`
    }
    if (details.total_ap > 1) {
      aps = `AP ${details.num_ap}/${details.total_ap}`
    }
    else if (details.num_ap > 0) {
      aps = `AP ${details.num_ap}`
    }
    return `${aps} ${editions}`
  }
  return ''
}


/**
 * Predefined formatting for date strings.
 * 
 * const defined formats documentation here
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * 
 * @param {string, Array} inputDate - as unique string or Array [string, string] with date string compatible with Date()
 * @param {string} format - one of `availableFormats`
 * @param {string} separator - caracter or string for multiple date separation (usually startdate <--> enddate)
 */
const formatDateTime = function(inputDate, format = 'SHORT_DATETIME', separator = undefined){
  const defaultSeparator = ' – '
  const availableFormats = {
    SHORT_TIME: {hour: '2-digit', minute: '2-digit'}, 
    LONG_TIME: {hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'},
    LONG_DATE: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
    SHORT_DATE: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' },
    NUMERIC_DATE: { year: 'numeric', month: 'numeric', day: 'numeric' },
    SHORT_NUMERIC_DATE: { year: '2-digit', month: 'numeric', day: 'numeric' },
    SHORT_DATETIME: {hour: '2-digit', minute: '2-digit', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
    LONG_DATETIME: {hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short', weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
    SHORT_NUMERIC_DATETIME: {hour: '2-digit', minute: '2-digit', year: 'numeric', month: 'numeric', day: 'numeric'},
    /* add more custom formats here */
    // ...
  }
  /* output format selection */
  let selectedFormat = availableFormats[format]
  if (!selectedFormat) selectedFormat = 'SHORT_DATETIME'
  /* date, time or datetime output selection */
  const isDate = format.includes('_DATE')
  const isTime = format.includes('_TIME')
  const isDateTime = !isDate && !isTime
  /* one date, time or datetime output method */
  function getFormatted(dateToDisplay){
    if (isDate) return dateToDisplay.toLocaleDateString('en', selectedFormat)
    if (isTime) return dateToDisplay.toLocaleTimeString('en', selectedFormat)
    if (isDateTime){
      const d = dateToDisplay.toLocaleDateString('en', selectedFormat)
      const t = dateToDisplay.toLocaleTimeString('en', selectedFormat)
      return `${d} ${t}`
    }
  }
  if (Array.isArray(inputDate)){
    /* multiple date, time or datetime output */
    const date1 = getFormatted(new Date(inputDate[0]))
    const date2 = getFormatted(new Date(inputDate[1]))
    return `${date1}${separator || defaultSeparator}${date2}`
  } else {
    /* single date, time or datetime output */
    const dateToDisplay = new Date(inputDate)
    return getFormatted(dateToDisplay)
  }
}

const formatTime = function(start, end = undefined, format= undefined, separator = undefined) {
  // define some useful formats
  // https://reference.codeproject.com/Book/javascript/reference/global_objects/date/tolocaledatestring
  const SHORT_TIME_FORMAT = {hour: '2-digit', minute: '2-digit'}
  const LONG_TIME_FORMAT = {hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'}

  // define default separator
  const TIME_SEPARATOR = "&nbsp;&ndash;&nbsp;"

  // function to retrieve formatting options according to a string
  // 'str' can be "short" or "long".
  // All other values are treated as "short"
  function selectFormat(str) {    
    return {
      "short": SHORT_TIME_FORMAT, 
      "long": LONG_TIME_FORMAT
    }[str] || SHORT_TIME_FORMAT
  }

  // function that actually does the job
  // end can be 'undefined' but start must be a valid date string
  // 'YYYY-MM-DD'
  function applyFormat(start, end, format = SHORT_TIME_FORMAT, separator = TIME_SEPARATOR) {
    if (start == undefined)
      return ""
    // display only time
    let startDate = new Date(start)
    if (end == undefined) {
      return startDate.toLocaleTimeString("en", format)
    }
    // display two times
    let endDate = new Date(end)
    // format two times with separator
    return [
      startDate.toLocaleTimeString("en", format), 
      separator,
      endDate.toLocaleTimeString("en", format)
    ].join("")
  }
  return applyFormat(start, end, selectFormat(format), separator)
}

/**
 * function that actually does the job
 * end can be 'undefined' but start must be a valid date string
 * 'YYYY-MM-DD'
 */
const formatDate = function (start, end = undefined, format = undefined, separator = undefined) {
  
  // define some useful formats
  // https://reference.codeproject.com/Book/javascript/reference/global_objects/date/tolocaledatestring
  const LONG_DATE_FORMAT = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  const SHORT_DATE_FORMAT = { year: 'numeric', month: 'short', day: 'numeric' }
  const NUMERIC_DATE_FORMAT = { year: 'numeric', month: 'numeric', day: 'numeric' }
  const NUMERIC_SHORT_DATE_FORMAT = { year: '2-digit', month: 'numeric', day: 'numeric' }
  
  // define default separator
  const DATE_SEPARATOR = "&nbsp;&ndash;&nbsp;"

  function selectFormat(str) {
    return {
      "short": SHORT_DATE_FORMAT,
      "numeric": NUMERIC_DATE_FORMAT,
      "numshort": NUMERIC_SHORT_DATE_FORMAT,
      "long": LONG_DATE_FORMAT
    }[str] || LONG_DATE_FORMAT
  }

  function applyFormat(start, end, format = LONG_DATE_FORMAT, separator = DATE_SEPARATOR) {

    if (start == undefined)
      return ""
    // display only one date
    let startDate = new Date(start)
    if (end == undefined) {
      return startDate.toLocaleDateString("en", format)
    }
    // display two dates
    let endDate = new Date(end)
    let first_format = Object.assign({}, format);
    // shorten first date if day/month/year are identical
    // ultimately, does not display end at all if start == date
    if (startDate.getYear() == endDate.getYear()) {
      delete first_format.year
      if (startDate.getMonth() == endDate.getMonth()) {
        delete first_format.month
        if (startDate.getDay() == endDate.getDay()) {
          return startDate.toLocaleDateString("en", format)
        }
      }
    }
    // format two dates with separator
    return [
      startDate.toLocaleDateString("en", first_format),
      separator,
      endDate.toLocaleDateString("en", format)
    ].join("")
  }
  return applyFormat(start, end, selectFormat(format), separator)
}


// TODO: Handling this in the serializers is more secure (but maybe overkill)
const stripHTML = function(html) {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

export default {
  truncate,
  pluralize,
  formatDimensions,
  formatNumber,
  formatPrice,
  formatEdition,
  formatTime,
  formatDate,
  formatDateTime,
  stripHTML
}
