<template>
  <section v-if="item && item.tracking">
    <!-- -------- DELIVERY BY GALLERY -------- -->
    <template v-if="item.mode == 'G' && editable">
      <section>
        <BaseInputContainer
          v-model="item.status"
          field-type="select"
          label="status"
          :options="SHIPMENT_STATUS_CHOICES"
          required
          :errors="errors.status"
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="item.price"
          :errors="errors.price"
          field-type="integer"
          label="price"
          required
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <section>
        <BaseInputContainer
          v-model="item.currency"
          field-type="select"
          label="Currency"
          reverse-field="code"
          :options="CURRENCY_CHOICES"
          :errors="errors.currency"
          required
          @input="onFieldInput"
          @update="onFieldUpdate"
        />
        <BaseInputContainer
          v-model="trackingValue.carrier_name"
          :errors="errorTracking ? errorTracking.carrier_name : null"
          field-type="string"
          label="Carrier name"
          required
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <section>
        <BaseInputContainer
          v-model="trackingValue.number"
          :errors="errorTracking ? errorTracking.number : null"
          field-type="string"
          label="Number"
          required
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="trackingValue.last_location"
          :errors="errorTracking ? errorTracking.last_location : null"
          field-type="string"
          label="Last location"
          required
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
      </section>
      <section>
        <BaseInputContainer
          v-model="trackingValue.summary"
          :errors="errorTracking ? errorTracking.summary : null"
          field-type="string"
          label="Summary"
          required
          @update="onFieldUpdate"
          @input="onFieldInput"
        />
        <BaseInputContainer
          v-model="trackingValue.url"
          field-type="url"
          label="Link"
          help-text=""
          :errors="errorTracking ? errorTracking.url : null"
          required
          @input="onFieldInput"
          @update="onFieldUpdate"
        />
      </section>
      <slot name="subform-controls" />
    </template>
    <!-- -------- NON EDITABLE DELIVERY BUT NOT PICKUP -------- -->
    <template v-else-if="item.mode != 'P'">
      <table class="tabular-data">
        <!-- ARTA PARTNER -->
        <tr>
          <td class="field-cell">
            Partner
          </td>
          <td>
            <BasePartnerLogo
              v-if="item.mode == 'A'"
              partner-name="arta"
              class="inline"
            />
            <template v-else>
              Gallery
            </template>
          </td>
        </tr>
        <!-- --- DELIVERY STATUS --- -->
        <tr>
          <td class="field-cell">
            Status
          </td>
          <td>
            {{ item.status ? item.status : "Unknown delivery status" }}
          </td>
        </tr>
        <tr>
          <td class="field-cell">
            Last update
          </td>
          <td>
            {{ tracking.last_update | formatDateTime('SHORT_NUMERIC_DATETIME') }}
          </td>
        </tr>
        <tr>
          <td class="field-cell">
            Price
          </td>
          <td>
            {{ item.price | formatPrice(item.currency.code) }}
          </td>
        </tr>
        <tr>
          <td class="field-cell">
            Carrier name
          </td>
          <td>
            {{ tracking.carrier_name }}
          </td>
        </tr>
        <tr>
          <td class="field-cell">
            Number
          </td>
          <td>
            {{ tracking.number }}
          </td>
        </tr>        
        <tr>
          <td class="field-cell">
            Last location
          </td>
          <td>
            {{ tracking.last_location }}
          </td>
        </tr>        
        <tr>
          <td class="field-cell">
            Summary
          </td>
          <td>
            {{ tracking.summary }}
          </td>
        </tr>        
        <tr>
          <td class="field-cell">
            Url
          </td>
          <td>
            {{ tracking.url }}
          </td>
        </tr>       
      </table>
    </template>
    <!-- -------- NON EDITABLE DELIVERY BUT NOT PICKUP -------- -->
    <template v-else>
      <section>
        <table class="tabular-data">
          <tr>
            <td class="field-cell">
              Status
            </td>
            <td>
              {{ item.status ? item.status : "Unknown delivery status" }}
            </td>
          </tr>
        </table>
      </section>
    </template>
  </section>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'

export default {
  name: 'ForthForm',
  mixins: [modelForms],
  props: {
    /**
     * Special delivery editable form
     * for tap-gallery
     */
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      trackingValue: {
        carrier_name: '',
        number: '',
        last_location: '',
        summary: '',
        url: '',
        last_update: ''
      },
    }
  },
  computed: {
    tracking: function () {
      return this.item.tracking
    },
    errorTracking: function () {
      return this.errors.tracking
    },
  },
  mounted: function () {
    if (this.item && !this.item.tracking) {
      /* initializes tracking form values if empty */
      this.item.tracking = {...this.trackingValue}
    } else if (this.item && this.item.tracking) {
      /* updates tracking form values with received values */
      this.trackingValue = this.item.tracking
    }
  },
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
section {
  display: flex;
  flex-direction: row;
}
section .base-input-container {
  flex: 1 0 0;
}
table.tabular-data{
  margin-bottom: var(--field-h-margin);
  border-spacing: 10px 5px;
}
.field-cell{
  text-transform: lowercase;
  font-variant: small-caps;
  font-family: 'Rubik SemiBold';
}
</style>
