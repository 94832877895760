<!--
  88""Yb    db    .dP"Y8 888888 888888    db     dP""b8 
  88__dP   dPYb   `Ybo." 88__     88     dPYb   dP   `" 
  88""Yb  dP__Yb  o.`Y8b 88""     88    dP__Yb  Yb  "88 
  88oodP dP""""Yb 8bodP' 888888   88   dP""""Yb  YboodP 
-->
<!-- TODO: functional if perf needed -->
<template>
  <div 
    :class="[
      'base-tag-container',
      {'active': active},
      {'disabled': disabled},
    ]"
    @click="onClick"
  >
    <router-link
      class="dynamic-underline"
      :to="linkParams"
    >
      <!-- TAG CONTENT CONTAINER -->
      <div class="label">
        <!-- LABEL IS A SHORT TEXT -->
        <span>{{ item.name }}</span>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseTag',
  props: {
    item : {
      type: Object,
      required: false,
      default: () => { return null }
    },
     /**
     * Disabled tags won't allow any user interaction.
     * No event will be emitted.
     * (from CSS, look at property `pointer-events: none;`)
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * active state
     */
    active: {
      type: Boolean,
      required: false,
      default: false
    },

  },
  // data: function () { return {}; },
  computed: {
    linkParams: function () {
      return {
        name: 'AllView',
        params: {
          itemId: this.item.id,
          modelName: 'tag',
          slug: this.item.metadata && this.item.metadata.slug || '#'
        }
      }
    }
  },
  // mounted: function(){},
  methods: {
    onClick: function(){
      if (!this.disabled){
        this.$emit('click')
      }
    }
  },
};
</script>

<style src="../assets/css/base.css" scoped></style>

<style scoped>
.base-tag-container{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* height: 50px; */
  height: calc(2.35 * var(--paragraph));
  width: fit-content;
  /* padding: 13px 20px; */
  border: 1px solid var(--primary-color);
  border-radius: 70px;
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  color: var(--primary-color);
  cursor: pointer;
  user-select: none;
}
.base-tag-container:hover .label{
  /* text-decoration: underline; */
}
.base-tag-container a,
.base-tag-container a:visited{
  color: var(--primary-color);
  margin: auto 20px auto 20px;
}
/* ---- active ---- */
.base-tag-container.active{
  color: #fff;
  background-color: var(--primary-color);
}
/* ---- disabled ---- */
.base-tag-container.disabled{
  pointer-events: none;
  color: var(--disabled-color);
  background-color: var(--disabled-secondary-color);
  border: 1px solid var(--disabled-color);
}
</style>
