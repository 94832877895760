/*   ___  _ _  _ _  ___  _  ___  ___  _                  
    | . \| | || | |/ __>| ||  _>| . || |                 
    |  _/|   |\   /\__ \| || <__|   || |_                
    |_|  |_|_| |_| <___/|_|`___/|_|_||___|               
                                                        
    ___  ___  _ _  _ _  ___  ___  ___  _  ___  _ _  ___ 
    |  _>| . || \ || | || __>| . \/ __>| || . || \ |/ __>
    | <__| | ||   || ' || _> |   /\__ \| || | ||   |\__ \
    `___/`___'|_\_||__/ |___>|_\_\<___/|_|`___'|_\_|<___/   */
                                                     

var Fraction = require('fraction.js');

const ratio = 0.3937008

const cmToInch = function(cmInput) {
  // convert
  const value = new Fraction(cmInput).mul(ratio).abs()
  // format to 16th
  const result =  new Fraction(Math.round(16 * value.valueOf()), 16)
  // return string
  return result.toFraction(true)
}

const inchToCm = function(inchInput) {
  /* looking for fractions in string */
  const fracPartTestPattern = /\d+\/\d+/
  try{
    /* split integer and fractional part in string*/
    inchInput = inchInput.split(' ')
    const integerPart = parseInt(inchInput[0])
    let fracPart = inchInput[1]
    /* exclude malformed fractions while typing */
    if (fracPart == undefined || !fracPartTestPattern.test(fracPart)){
      fracPart = 0
    }
    /* convert */
    const value0 = integerPart / ratio
    const value1 = new Fraction(fracPart).div(ratio).abs()
    /* return float */
    const result = new Number(value0 + value1).toFixed(1)
    return parseFloat(result)
  } catch(e){
    console.debug('METRICS: error', e)
  }
}
export default{
  inchToCm,
  cmToInch
}
