<!-- 
usage: 
<BasePreview :thumb="<url>" 
     [:zoom1="<url>"]
     [:zoom2="<url>"] >
<slot> 
</BasePreview>
-->
<template>
  <div
    v-if="thumb"
    class="thumbnail"
  >
    <img
      :src="thumb"
      @mouseover="state = 'over'"
    >
    <div
      v-if="zoom1 && state == 'over'"
      class="preview"
      @click="state = 'clicked'"
      @mouseout="state = 'off'"
    >
      <figure>
        <img :src="zoom1">
        <figcaption>
          <slot name="caption1" />
        </figcaption>
      </figure>
    </div>
    <div
      v-if="zoom2 && state == 'clicked'"
      class="fullscreen"
      @click="state = 'off'"
    >
      <figure>
        <BaseSquareButton
          icon="x"
          color="CTA-1"
        />
        <img :src="zoom2">
        <figcaption @click.stop="state = 'clicked'">
          <slot />
        </figcaption>
      </figure>
    </div>
  </div>
  <div
    v-else
    class="thumbnail empty-preview"
  />
</template>

<script>
  export default {
    name: "BasePreview",
    props: {
      thumb: {
        type: String,
        required: false,
        default: null,
      },
      zoom1: {
        type: String,
        required: false,
        default: null,
      },
      zoom2: {
        type: String,
        required: false,
        default: null,
      },
    },
    data: function() {
      return {
        state: 'off',
      }
    },
  }
</script>

<style scoped>
/* *{
  box-sizing: border-box;
}
.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}
.empty-preview {
  width: 40px;
  height: 40px;
  margin: 5px;
  border: 1px dashed var(--off-color);
  background-color: transparent;
}
.thumbnail > img {
  max-width: 300px;
  max-height: 300px;
}
.preview {
  position: absolute;
  left: 0;
  z-index: var(--overall-z-index);
  background-color: #fff;
  box-shadow: 0 0 10px #999;
  padding: 1.0rem;
  animation: vignette .2s;
}
.preview img {
  max-width: 400px;
  max-height: 400px;
} */
/* animation */
/* @keyframes vignette {
    from { opacity: 0; }
    to { opacity: 1; }
} */
/* .fullscreen{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--overall-z-index);
  background: rgba(0,0,0,0.8);
} */
.fullscreen figure{
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  margin: 0;
  /*background: #fff url() 0 0 no-repeat;*/
  background-color: white;
  padding: 10px;
  transform: translate(-50%,-50%);
}
/* .fullscreen > figure img {
  display: block;
  width: auto;
  max-height: calc(100vh - 250px);
} */
.square-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  /* z-index: 10; */
}
/* figcaption {
  user-select: text;
} */
</style>
