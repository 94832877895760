<template>
  <div 
    :class="['base-toggle', { checked: internalValue }, { disabled: disabled }]"
    @click="toggleValue"
  >
    <div class="toggle-container">
      <div class="label label-left">
        {{ falseValueLabel }}
      </div>
      <div class="toggle-button-container">
        <div class="chip" />
      </div>
      <div class="label label-right">
        {{ trueValueLabel }}
      </div>
    </div>
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'

export default {
  name: 'BaseToggle',
  mixins: [baseinputs],
  props: {
    /**
     * Received v-model value
     */
    value: {
      type: [Boolean, String, Object, Number],
      required: false,
      default: () => {
        return false
      },
    },
    /**
     * Field label
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * (optionnal)
     * Provide an Array like
     * [
     *  { label: "text label", value: <falseValue> },
     *  { label: "text label", value: <truthValue> },
     * ]
     * with first item being falsy value, and second one truthy value.
     */
    options: {
      type: Array,
      required: false,
      default: () => {
        return null
      },
    },
  },
  data: function () {
    return {}
  },
  computed: {
    falseValueLabel: function () {
      if (!this.options) return null
      return this.options[0].label
    },
    trueValueLabel: function () {
      if (!this.options) return null
      return this.options[1].label
    },
    internalValue: function () {
      if (this.options) {
        return this.options.find((o) => o.value == this.value).value
      } else {
        return this.value
      }
    },
  },
  mounted: function () {},
  methods: {
    toggleValue: function () {
      if (!this.disabled) {
        let newval = !this.internalValue
        if (this.options) {
          const valIdx = newval ? 1 : 0
          this.$emit('input', this.options[valIdx].value)
        } else {
          this.$emit('input', newval)
        }
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
/* variables definition */
.base-toggle {
  --default-toggle-height: 20px;
  --filet: 1px;
  --toggle-color: #000;
}
.base-toggle.checked {
  --toggle-color: var(--primary-color);
}
.base-toggle.disabled {
  cursor: not-allowed;
  filter: saturate(0.5) opacity(0.5);
}
.toggle-container {
  display: flex;
  align-items: center;
}
.label {
  font-family: 'Rubik Regular';
  margin: 10px;
}
.label-left {
  margin-left: 0px;
}
.label-right {
  margin-right: 0px;
}
.toggle-button-container {
  height: var(--default-toggle-height);
  width: calc(2 * var(--default-toggle-height));
  border: 1px solid var(--toggle-color);
  border-radius: calc(var(--default-toggle-height));
  /* box-sizing: border-box; */
  display: flex;
  align-items: center;
}
.chip {
  box-sizing: border-box;
  border: 1px solid var(--white);
  height: var(--default-toggle-height);
  width: var(--default-toggle-height);
  border-radius: 50%;
  background-color: var(--toggle-color);
}
.checked .chip {
  margin-left: var(--default-toggle-height);
}
</style>
