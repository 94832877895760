<template>
  <div :class="['base-floatinput-container', ...styles]">
    <input
      type="number"
      step="0.01"
      min="0.0"
      :placeholder="placeholder"
      v-bind="$attrs"
      :value="value"
      v-on="inputListeners"
    >
  </div>
</template>

<script>
import {baseinputs} from '../../mixins/baseinputs'

export default {
  name: 'BaseFloatInput',
  mixins: [baseinputs],
  props: {
    value: {
      type: Number,
      required: false,
      default: 0
    },
    placeholder: {
      type: Number,
      required: false,
      default: -1
    },
  },
  computed: {
    inputListeners: function(){
      let vm = this
      return {
        ...this.$listeners,
        input: function(event){
          vm.$emit('input', Number(event.target.value))
        }
      }
    }
  },
  methods: {},
};
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-floatinput-container{
  width: 100%;
}
</style>
