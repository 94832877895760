/* __   _   .--.  .---.  _ .--.  
  [  | | | ( (`\]/ /__\\[ `/'`\] 
  |  \_/ |, `'.'.| \__., | |     
  '.__.'_/ [\__) )'.__.'[___]    STORE */

const state = {
  tapApi: {},
  currentUser: {},
  isAuthenticated: false,
  stripeConnectLink: {},
}

const mutations = {
  setCurrentUser: function(state, value) {
    state.currentUser = value
  },
  setIsAuthenticated: function(state, value) {
    state.isAuthenticated = value
  },
  setStripeConnectLink: function(state, value) {
    state.stripeConnectLink = value
  },
  setTapApi: function(state, tapApi) {
    state.tapApi = tapApi
  },
}

const actions = {
  initStore: function({ commit }, tapApi) {
    commit('setTapApi', tapApi)
  },
  checkAuth: async function({ commit, dispatch }) {
    return state.tapApi.auth
      .getUser()
      .then((response) => {
        console.debug('USER: ✔ auth ok', response.data)
        commit('setCurrentUser', response.data)
        commit('setIsAuthenticated', true)
        dispatch('getStripeConnectLink')
        return true
      })
      .catch((error) => {
        console.debug('userCheck failed', error)
        commit('setIsAuthenticated', false)
        return false
      })
  },
  tryLogin: async function({ dispatch }, form) {
    return state.tapApi.auth.logIn(form)
      .then((response) => {
        console.debug('USER: ✔ login success', response)
        dispatch('checkAuth')
        return response
      })
  },
  logout: function({ commit, dispatch }) {
    return state.tapApi.auth.logOut().then((response) => {
      console.debug('USER: ✔ logout success', response)
      commit('setCurrentUser', null)
      commit('setIsAuthenticated', false)
      commit('setStripeConnectLink', {})
    })
  },
  getStripeConnectLink : async function ({ commit, dispatch }) {
    return state.tapApi.stripeConnect.getLink()
      .then((response) => {
        commit('setStripeConnectLink', response.data);
      })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
