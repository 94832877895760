<template>
  <section v-if="item">
    <BaseInputContainer
      v-model="item.picture"
      field-type="image"
      label="image"
      help-text="Provide a picture for this space."
      :errors="errors?errors.picture:null"
      :disabled="disabled"
      @input="onFieldInput"
      @update="onPictureUpdate"
    />
    <div class="column">
      <BaseInputContainer
        v-model="item.video"
        field-type="url"
        label="Video"
        help-text="A full URL to a media platform (Dailymotion, Youtube, others...)"
        :errors="errors?errors.video:null"
        :disabled="disabled"
        @input="onFieldInput"
        @update="onFieldUpdate"
      />
      <BaseInputContainer
        v-model="item.caption"
        field-type="rte"
        label="Caption"
        help-text="A caption for this media. SEO"
        :styles="['small-rte']"
        simple-mode
        :errors="errors?errors.caption:null"
        required
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.copyright"
        field-type="rte"
        label="Copyright"
        help-text="Media owner details"
        :styles="['small-rte']"
        simple-mode
        :errors="errors?errors.copyright:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      /> 
      <BaseInputContainer
        v-model="item.preview"
        field-type="toggle"
        label="Preview"
        help-text="Is used for 2D preview"
        :errors="errors?errors.preview:null"
        @input="onFieldInput"
        @update="onFieldUpdate"
      />          
    </div>
  </section>
</template>

<script>
import {modelForms} from '@/mixins/modelForms.js'

export default {
  name: 'MediaForm',
  mixins: [modelForms],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
  },
  methods: {
    onPictureUpdate: function (event){
      console.log('picture udpate ok', event)
    }
  }
}
</script>

<style src="../assets/css/forms.css" scoped></style>
<style scoped>
</style>
