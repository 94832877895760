<template>
  <svg
    id="bevel-vector"
    ref="bevelVectors"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <!-- two-edges bevel -->
    <!-- <path d="M100,0 L100,90 L0,100 L0,10" /> -->
    <!-- one-edge bevel -->
    <path d="M100,0 L100,90 L0,100 L0,0" />
  </svg>
</template>

<script>
export default {
  name: 'BaseBevel',
  props: {},
  data: function () {
    return {}
  },
  mounted: function(){},
  methods: {},
}
</script>

<style scoped>
#bevel-vector {
  width: 100%;
  height: 100%;
}
#bevel-vector path {
  fill: #fff;
}
</style>

