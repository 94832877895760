<template>
  <svg
    :id="uniqueArrowId"
    class="base-arrow"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <!-- --------- SHAPES ---------- -->
    <g :id="`pointing-${uniqueArrowId}`">
      <path
        class="pointing"
        :d="`
          M 7.5, ${(height - pointingHeight) / 2}
          L ${pointingWidth}, ${height / 2}
          L 7.5, ${(height + pointingHeight) / 2}
        `"
      />
    </g>
    <line
      :id="`firstLine-${uniqueArrowId}`"
      class="arrow"
      x1="0"
      :x2="`${width / 2}`"
      :y1="`${height / 2}`"
      :y2="`${height / 2}`"
    />
    <!-- --------- ANIMATIONS ---------- -->
    <animate
      id="hline-grow"
      ref="lineGrow"
      :href="`#firstLine-${uniqueArrowId}`"
      attributeName="x2"
      :to="`${width - 5}`"
      :dur="animDuration"
      fill="freeze"
    />
    <animate
      id="hline-reset"
      ref="lineReset"
      :href="`#firstLine-${uniqueArrowId}`"
      attributeName="x2"
      :to="`${width / 2}`"
      :dur="animDuration"
      fill="freeze"
    />
    <animateTransform
      id="pointing-grow"
      ref="pointingGrow"
      attributeName="transform"
      :href="`#pointing-${uniqueArrowId}`"
      type="translate"
      :to="`${width / 3} 0`"
      :dur="animDuration"
      fill="freeze"
    />
    <animateTransform
      id="pointing-reset"
      ref="pointingReset"
      attributeName="transform"
      :href="`#pointing-${uniqueArrowId}`"
      type="translate"
      to="0 0"
      :dur="animDuration"
      fill="freeze"
    />
  </svg>
</template>

<script>
/* available arrow sizes -- these are maxed out values [height, width] */
const THEMES = {
  default: [20, 30],
  small: [15, 25],
}

const ANIM_DURATION = '200ms'

export default {
  name: 'BaseArrow',
  props: {
    size: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data: function () {
    return {
      animDuration: ANIM_DURATION,
      uniqueArrowId: `base-arrow-${(Math.random()*1e9).toFixed(0).toString(16)}`
    }
  },
  computed: {
    width: function () {
      return THEMES[this.size][1]
    },
    height: function () {
      return THEMES[this.size][0]
    },
    pointingWidth: function () {
      return this.width / 2
    },
    pointingHeight: function () {
      return this.height * 0.618
    },
  },
  mounted: function () {},
  methods: {
    onMouseEnter: function () {
      const lineEl = this.$refs.lineGrow
      const arrowEl = this.$refs.pointingGrow
      lineEl.beginElement()
      arrowEl.beginElement()
    },
    onMouseLeave: function () {
      const lineEl = this.$refs.lineReset
      const arrowEl = this.$refs.pointingReset
      lineEl.beginElement()
      arrowEl.beginElement()
    },
  },
}
</script>


<style scoped>
.base-arrow{
  --stroke-color: #000;
}
.arrow {
  fill: none;
  stroke: var(--stroke-color);
  stroke-width: 1;
}
.pointing {
  fill: none;
  stroke: var(--stroke-color);
  stroke-width: 1;
  stroke-miterlimit: 8;
}
</style>
