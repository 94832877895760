<template>
  <!-- MOBILE VIEW -->
  <div
    v-if="smallScreen"
    class="model-small-container"
  >
    <!-- PORTRAIT -->
    <div class="small-thumb-container">
      <figure class="picture-container">
        <img
          v-if="picture"
          :src="picture.thumbnail"
          class="thumb"
        >
        <div
          v-else
          class="empty-thumb"
        />
      </figure>
    </div>
    <div class="small-info">
      <!-- NAME -->
      <div class="small-artist-name">
        <p>
          {{ object.order.artwork.artist.name }}<br>
          <span class="title">{{ object.order.artwork.title }}</span>, {{ object.order.artwork.year }}
        </p>
      </div>
      <div>
        <p>
          {{ object.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
        </p>
        <p>
          {{ object.status }}
        </p>
      </div>
    </div>
  </div>

  <!-- DESKTOP VIEW -->
  <tr v-else-if="object.order">
    <!-- see ListView for slot declaration -->
    <slot name="listSelectionControls" />
    <td class="thumb-cell">
      <div class="thumb-container">
        <img
          v-if="picture"
          :src="picture.thumbnail"
        >
      </div>
    </td>
    <td>
      <!-- TODO: get display name -->
      {{ object.status }}
    </td>
    <td>
      {{ object.total_price | formatPrice(object.currency.code) }}
    </td>
    <td>
      <router-link
        :to="linkArtist"
        title="Artist details"
      >
        {{ object.order.artwork.artist.name }}<br>
      </router-link>
      <router-link
        :to="linkArtwork"
        title="Artwork details"
      >
        <span class="artwork-title">{{
          object.order.artwork.title | truncate(20)
        }}</span>, {{ object.order.artwork.year }}<br>
      </router-link>
    </td>
    <td>
      {{ object.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
    </td>
    <!-- <td> -->
    <!--   <\!-- payment link -\-> -->
    <!--   <template v-if="stripePaymentDetailsURL"> -->
    <!--     <a -->
    <!--       :href="stripePaymentDetailsURL" -->
    <!--       target="_blank" -->
    <!--     > -->
    <!--       <BasePartnerLogo -->
    <!--         partner-name="stripe" -->
    <!--         class="inline" -->
    <!--       />&nbsp; -->
    <!--       <img -->
    <!--         class="link-icon" -->
    <!--         src="@/base/assets/img/svgicons/link.svg" -->
    <!--       > -->
    <!--     </a> -->
    <!--   </template> -->
    <!--   <template v-else> -->
    <!--     No payment -->
    <!--   </template> -->
    <!-- </td> -->
    <!-- see ListView for slot declaration -->
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'

const STRIPE_ROOT_URL = process.env.VUE_APP_STRIPE_ROOT_URL

export default {
  name: 'TransactionRow',
  mixins: [modelRowUtil],
  computed: {
    // transactionStatus: function () {
    //   const choice = this.$store.state.choices.
    // }
    stripePaymentDetailsURL: function () {
      if (this.object.payment != null) {
        const acct = this.object.payment.connect_id
        const pi = this.object.payment.stripe_id
        return `${STRIPE_ROOT_URL}${acct}/payments/${pi}`
      } else {
        return null
      }
    },
    /* Checks if the transaction has an image */
    artwork: function () {
      return this.object.order && this.object.order.artwork
    },
    picture: function () {
      return (
        this.artwork &&
        this.artwork.media_set &&
        this.artwork.media_set[0] &&
        this.artwork.media_set[0].picture
      )
    },
    linkArtist: function () {
      return {
        name: 'artist-view',
        params: { id: this.object.order.artwork.artist.id },
      }
    },
    linkArtwork: function () {
      return {
        name: 'artwork-view',
        params: { id: this.object.order.artwork.id },
      }
    },
  },
}
</script>

<style src="@/assets/css/listrows.css" scoped></style>
<style scoped>
.artwork-title {
  font-family: 'Rubik Medium Italic';
}
</style>
