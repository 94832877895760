import defaultOptions from '../default/options.js'
import { defaultRequestInterceptor } from '../default/interceptors.js'
import DefaultMethods from '../default/methods'
// bindings
import store from '@/store'
import { router } from '@/router'

/* --------------- Service static configuration ------------- */
// Axios requests settings
const config = {
  ...defaultOptions,
  timeout: 8000,
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'x-csrftoken',
  withCredentials: true,
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  },
}

// API base URL
const baseURL = {
  protocol: process.env.VUE_APP_API_PROTOCOL,
  host: process.env.VUE_APP_API_HOST,
  port: process.env.VUE_APP_API_PORT,
  root: process.env.VUE_APP_API_ROOT,
}

// API models URLs
const modelURLs = {
  address: '/admin/common/address/',
  artist: '/admin/art/artist/',
  artwork: '/admin/art/artwork/',
  cause: '/admin/causes/cause/',
  country: '/common/country/',
  currency: '/common/currency/',
  event: '/admin/art/event/',
  gallery: '/admin/art/gallery/',
  project: '/admin/causes/project/',
  seller: '/account/seller/',
  tag: '/admin/common/tag/',
  transaction: '/admin/transaction/transaction/',
}

// API not-model URLs ("special")
const specialURLs = {
  auth: '/auth/',
  choices: '/choices/',
  conversations: '/admin/conversations/',
  settings: '/admin/common/settings/',
  stripeConnect: '/admin/account/seller/stripe_connect_link/',
  transactionStatus: '/admin/transaction/transaction/',
  // ... add special routes here
}

/* --------------- Interceptors customization ------------ */
const interceptors = {
  request: {
    ...defaultRequestInterceptor,
    configInterceptor: function (config) {
      /* Note ab: this is quite an ugly test to set timeout off
       when uploading artworks. To be discussed */
      if (config.method == 'post' && config.url.includes("upload")) {
        return { ...config, timeout: 0 }
      }
      return config
    }
  }, // We inject the default interceptors as needed
  response: {
    responseInterceptor: function(response) {
      return response
    },
    errorInterceptor: function(error) {
      // Here we customize response error handling for 404
      // maybe we don't want to Reject 404 errors.
      if (error.response) {
        if (error.response.status === 404) {
          console.error('NOT FOUND', error.config.url, error.config.params)
          return null
        }
        if (error.response.status == 401) {
          console.debug('API: ❌ User is not authentified -- not authorized')
          store.dispatch('user/logout')
          /* disabling login redirection for some views (avoid infinite loop) */
          const noRedirectionFor = ['Login', 'AccountRedirection']
          if (!noRedirectionFor.includes(router.history.current.name)) {
            router.push({ name: 'Login' })
          }
        }
      }
      console.error('API error\n', error)
      return Promise.reject(error)
    },
  },
}

/* --------------- Model methods ------------ */
/*  Here the default base CRUD methods are added.
    Custom model methods (other actions than CRUD) can be added in `custom`    */
const modelMethods = (handler, modelName, url) => {
  const baseMethods = new DefaultMethods(handler, modelName, url)
  const defaultChoicesMethods = {
    /**
     * method used in selectors to provide well-formatted choices
     */
    searchFast: (query) =>
      handler.get(`${url}as_choices/`, { params: { ...query, limit: 20 } }),
    /**
     * method used by selectors to prepopulate remoteOptions
     * with correct display_name for already set values
     * @param {Array} query - an Array of form [{field: value}, {field: value}]
     * @returns {Function}
     */
    preloadOptions: function(query) {
      const parms = query.map((pf) => {
        const key = Object.keys(pf)[0]
        const val = Object.values(pf)[0]
        return [key, val]
      })
      /* this makes the URL string query ?field=value&field=value */
      const urlParms = new URLSearchParams(parms)
      return handler.get(`${url}as_choices/`, { params: urlParms })
    },
  }
  const custom = {
    artist: {
      ...defaultChoicesMethods,
    },
    event: {
      ...defaultChoicesMethods,
    },
    gallery: {
      ...defaultChoicesMethods,
      artworkUploadTemplate: (id) =>
        handler.get(`${url}${id}/upload_artworks/`),
      artworkUpload: (id, payload) => 
        handler.post(`${url}${id}/upload_artworks/`, payload)
    },
    // organisation: {
    //   ...defaultChoicesMethods,
    // },
    // institution: {
    //   ...defaultChoicesMethods,
    // },
    project: {
      ...defaultChoicesMethods,
    },
    tag: {
      ...defaultChoicesMethods,
    },
    seller: {

    }
  }
  return { ...baseMethods, ...custom[modelName] }
}

/* ---------------- "Special" methods ---------- */
/*  Special methods are API calls that do not need CRUD.
    They still benefit from url prefixing                 */
const specialMethods = (handler, specialName, url) => {
  const special = {
    system: {
      availability: () => handler.options(url),
      // stripeConnect: () =>
      //   handler.get(`${url}admin/account/seller/stripe_connect_link/`),      
    },
    choices: () => handler.get(url),
    // ... add special method here
    auth: {
      getUser: () => handler.get(`${url}user/`),
      logIn: (form) => handler.post(`${url}login/`, form),
      logOut: () => handler.post(`${url}logout/`),
      getPasswordStrength: (pw) =>
        handler.post(`${url}password_score`, { password: String(pw) }),
      askPasswordReset: (email) =>
        handler.post(`${url}password/reset/`, {email: String(email) }),
      confirmPasswordReset: (payload) =>
        handler.post(`${url}password/reset/confirm/`, payload),
      validateAccount: (payload) => handler.post(`${url}account_validation/`, payload)
    },
    conversations: {
      get: () => handler.get(url)
    },
    settings: {
      get: () => handler.get(url),
      patch: (form) => handler.patch(url, form),
    },
    stripeConnect: {
      getLink: () => handler.get(url)
    },
    transactionStatus: {
      getTransactionStatusChoices: () => handler.get(`${url}transaction_status_choices/`),
      getShipmentStatusChoices: () => handler.get(`${url}shipment_status_choices/`),
    },
  }
  return special[specialName]
}

export default {
  config,
  baseURL,
  interceptors,
  modelURLs,
  modelMethods,
  specialURLs,
  specialMethods,
}
