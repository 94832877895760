<template>
  <div :class="['base-dateinput', { disabled: disabled }]">
    <template v-if="!disabled">
      <DatePicker
        :value="value"
        :is-required="required"
        v-on="$listeners"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <input
            :value="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            v-on="inputEvents"
          >
        </template>
      </DatePicker>
    </template>
    <template v-else>
      <span class="text-cdt">
        {{ value | formatDateTime('SHORT_NUMERIC_DATETIME') }}
      </span>
    </template>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { baseinputs } from '../../mixins/baseinputs'

export default {
  name: 'BaseDateInput',
  components: { DatePicker },
  mixins: [baseinputs],
  props: {
    value: {
      type: [String, Date],
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: [String],
      required: false,
      default: () => {
        return null
      },
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {}
  },
  computed: {},
  mounted: function () {},
  methods: {},
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-dateinput.disabled {
  background-color: var(--flat-color);
  cursor: default;
  color: var(--grey-dark);
}
</style>
