<template>
  <div
    :class="[/*'base-select',*/ 'base-multiselect', ...styles, {'disabled': disabled}]"
    @mouseenter="mouseOverBox = true"
    @mouseleave="mouseOverBox = false"
    @keydown.down="onUpDownKey(1)"
    @keydown.up="onUpDownKey(-1)"
    @keydown.enter="onKeyEnter"
    @keydown.esc="closeList"
  >
    <!-- @click="onBoxClick" -->
    <div class="first-row">
      <div
        class="selected-options"
        @click="openList"
      >
        <!-- 
          one or more rows of small boxes with a cross 
          representing selected options
        -->
        <template v-for="idx in selectedOptionsIndexes">
          <div
            :key="'sol' + idx"
            class="selected-option-tag"
          >
            <!-- the cross to remove element -->
            <img
              v-if="!disabled"
              class="remove-cross"
              src="../../assets/img/svgicons/x.svg"
              @click="removeElement(idx)"
            >
            <!-- selected option display content -->
            <div
              :key="'opt'+idx"
              class="option-display"
              :title="availableOptions[idx].display_name"
            >
              <template v-if="displayId">
                {{ availableOptions[idx].value }}.&nbsp;
              </template>
              {{ availableOptions[idx].display_name | truncate(20) }}
            </div>
          </div>
        </template>
        <!-- A text input field to autocomplete/search -->
        <div
          v-show="showInput"
          class="search-input-container"
        >
          <BaseTextInput
            v-model="searchQueryInput"
            :placeholder="placeholderText"
            v-on="inputListeners"
            @focus="onTextInputFocus"
            @blur="onTextInputBlur"
            @keydown.tab="closeList"
          />
        </div>
      </div>
      <!-- Controls handle dropdown display state -->
      <div class="controls">
        <img
          v-show="!disabled && !showOptions && value.length == 0"
          class="add-cross"
          src="../../assets/img/svgicons/plus.svg"
          @click="openList"
        >
        <img
          v-show="!disabled && !showOptions && value.length > 0"
          class="remove-all-cross"
          src="../../assets/img/svgicons/x.svg"
          @click="resetValue"
        >
      </div>
    </div>
    <!-- THE REAL OPTIONS LIST -->
    <div
      v-if="showOptions"
      ref="optionsListEl"
      class="options-list"
    >
      <template v-if="isLoading">
        <div class="option-container">
          <div class="option-text">
            L O A D I N G
          </div>
        </div>
      </template>
      <template v-else>
        <template v-for="(option, oIdx) in displayableOptions">
          <div
            :key="'opc-' + oIdx"
            :ref="'opc-' + oIdx"
            :class="[
              'option-container',
              { 'force-hover': oIdx == forceHoverIdx },
            ]"
            @click="onOptionClick(oIdx)"
          >
            <template v-if="option.htmlDisplayName">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="option-text formatted-option"
                v-html="option.htmlDisplayName"
              />
              <!--eslint-enable-->
            </template>
            <template v-else>
              <div class="option-text">
                <template v-if="displayId">
                  {{ option.value }}.&nbsp;
                </template>
                {{ option.display_name }}
              </div>
            </template>
          </div>
        </template>
        <template v-if="displayableOptions.length == 0">
          <div class="option-container">
            <div class="option-text">
              No selection available.
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'
import { baseselectors } from '../../mixins/baseselectors'

export default {
  name: 'BaseMultiSelect',
  mixins: [baseinputs, baseselectors],
  props: {
    value: {
      type: [Array],
      required: false,
      default: () => {
        return []
      },
    },
    /**
     * displays the 'value' (usually ID) of each option in addition 
     * to its 'display_name'. Useful for SDGs
     */
    displayId: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: function () {
    return {
      multiSelect: true,
      newValue: [],
    }
  },
  computed: {
    /**
     * @returns {Array}
     *    selected options are a subset of all availables options.
     *    (it's an array of indexOf)
     */
    selectedOptionsIndexes: function () {
      if (this.selectedOptions == null || this.selectedOptions.length == 0) {
        return []
      }
      return this.selectedOptions.map((o) => this.availableOptions.indexOf(o))
    },
    showInput: function () {
      const noValue = !(this.value.length > 0)
      const valueAndMenuOpen = (!noValue && this.showOptions)
      const shouldShow = noValue || valueAndMenuOpen
      return shouldShow
    },
  },
  methods: {
    openList: function () {
      if (this.dataSource) this.searchRemoteOptions()
      this.showOptions = true
      this.$nextTick(() => {
        this.$el.querySelector('input').focus()
      })
    },
    closeList: function () {
      this.searchQueryInput = ''
      this.showOptions = false
    },
    /**
     * Emits an event to parent like a normal html input field.
     * Used by BaseInputContainer as parent.
     */
    selectValue: function () {
      this.$emit('input', this.newValue)
    },
    /**
     * Clicking on a list option adds the item
     * to newValue then submits `input` event to parent
     * by selectValue()
     * @param oIdx - ⚠ this is the all-available-options index
     */
    onOptionClick: function (oIdx) {
      const sel = this.displayableOptions[oIdx]
      // console.debug(
      //   `Selected! Index: ${oIdx}`,
      //   `Valeur: ${sel.value}`,
      //   `display_name: ${sel.display_name}`
      // )
      if (this.valueIsObject) {
        this.newValue = [...this.value, { [this.reverseField]: sel.value }]
      } else {
        this.newValue = [...this.value, sel.value]
      }
      this.selectValue()
      this.closeList()
    },
    /**
     * removes an element and updates field.
     */
    removeElement: function (optionIdx) {
      // console.debug('you want to get rid of', optionIdx, this.availableOptions[optionIdx])
      const optionToRemoveIdx = this.availableOptions[optionIdx].value
      if (this.valueIsObject) {
        const vpc = this.value.filter((o) => {
          const selOptionIdx = o[this.reverseField]
          return selOptionIdx != optionToRemoveIdx
        })
        this.newValue = [...vpc]
      } else {
        /* not-object-values are direct values so optionToRemoveIdx is also  direct value */
        const vpc = this.value.filter((o) => o != optionToRemoveIdx)
        this.newValue = [...vpc]
      }
      this.selectValue()
    },
    /**
     * Focus related things should be handled
     * in the parent baseinputcontainer
     */
    onTextInputBlur: function (event) {
      this.$emit('blur')
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style src="../../assets/css/selectors.css" scoped></style>
<style scoped>
.base-multiselect {
  position: relative;
  width: 100%;
  /* margin: 5px 20px; */
  margin: var(--field-default-padding);
  --option-tag-height: calc(var(--field-default-height) - 2 * var(--field-default-v-padding));
}
.first-row {
  display: flex;
  justify-content: space-between;
}
.selected-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: -8px; /* compensates selected-option-tag margin-top */
  width: 100%;
}
.selected-option-tag {
  box-sizing: border-box;
  border-radius: 4px;
  height: var(--option-tag-height);
  background-color: var(--grey-medium);
  display: flex;
  align-items: center;
  margin-right: 8px;
  margin-top: 8px;
  padding: 5px 10px 5px 8px;
  /* max-width: 150px; */
  cursor: default;
}
.remove-cross {
  height: calc(0.6*var(--option-tag-height));
  margin-right: 6px;
  cursor:no-drop;
  transition: transform 0.2s ease-in-out;
}
.remove-cross:hover{
  transform: scale(1.5);
}
.search-input-container {
  height: var(--option-tag-height);
  margin-right: 10px;
  margin-top: 10px;
  min-width: 163px;
  width: 100%;
  display: flex;
  align-items: center;
}
.base-textinput-container {
  width: 100%;
}
.disabled {
  pointer-events:none;
  background-color: rgb(250,250,250);
}
</style>
