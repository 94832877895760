<template>
  <div :class="['base-palette']">
    <div
      v-for="(color, colorIdx) in palette"
      :key="'palblk' + colorIdx"
      class="palette-block-container"
    >
      <p>{{ color.name }}</p>
      <div
        :key="'colpal' + colorIdx"
        class="palette-block"
        :style="{ backgroundColor: `var(--${color.name})` }"
      />
      <p>{{ color.hex }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePalette',
  data: function () {
    return {
      colorNames: [
        'water-blue',
        'water-blue-medium',
        'water-blue-light',
        'water-blue-dark',
        'black',
        'grey-dark',
        'grey-medium',
        'grey-light',
        'white',
        'green',
        'red'
      ],
      palette: {},
    };
  },
  mounted: function () {
    // Getting value of CSS variables needs the component to be mounted
    this.palette = this.colorNames.map((c) => {
      return {
        name: c,
        hex: getComputedStyle(this.$el).getPropertyValue('--' + c),
      };
    });
  },
  methods: {},
};
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.base-palette {
  /* width: 500px; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-family: 'Rubik Regular';
}
.palette-block {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.palette-block-container p {
  text-align: center;
}
</style>
