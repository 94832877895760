<template>
  <div :class="['base-textinput-container', ...styles]">
    <input
      ref="passinput"
      type="password"
      :placeholder="placeholder"
      v-bind="$attrs"
      :value="value"
      :autocomplete="autocomplete"
      v-on="inputListeners"
    >
    <div 
      class="visibility-toggle"
      @click="toggleVisibility"
    >
      <img 
        v-if="showPass == false"
        src="../../assets/img/svgicons/eye.svg"
      >
      <img 
        v-if="showPass == true"
        src="../../assets/img/svgicons/eye-off.svg"
      >
    </div>
  </div>
</template>

<script>
import {baseinputs} from '../../mixins/baseinputs'

export default {
  name: 'BasePasswordInput',
  mixins: [baseinputs],
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Type something ...'
    },
    /** 
     * facultative and not enabled by default
     * allows browser and forms manager to make suggestions
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
     */
    autocomplete: {
      type: String,
      required: false,
      default: null, // 'on', 'username', 'email', etc... see mdn doc.
    }
  },
  data: function () {
    return {
      showPass: false,
    };
  },
  computed: {

  },
  methods: {
    toggleVisibility: function (){
      this.showPass = !this.showPass
      this.$refs.passinput.type = (this.showPass)?'text':'password'
    }
  },
};
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-textinput-container{
  display: flex;
  align-items: center;
}
.visibility-toggle{
  height: 1rem;
  margin-left: 4px;
}
.visibility-toggle img{
  height: 100%;
}
</style>
