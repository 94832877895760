<template>
  <figure class="push-redirection">
    <div class="push-redirection-img-container">
      <router-link :to="linkPush">
        <img 
          v-if="item.picture"
          :src="item.picture && item.picture.web" 
          :alt="item.name"
        >
      </router-link>
    </div>
    <figcaption
      class="push-redirection-caption-container"
    >
      <h4>
        {{ item.name | truncate(30) }} 
      </h4>
    </figcaption>
  </figure>
</template>

<script>
export default{
  name: 'BasePushRedirection',
  props: {
    /**
     * the item Object
     */
     item: {
      type: Object,
      required: true,
      default: () => { return {}},
    },
  },
  computed: {
    /**
     * This weak method tries to compute
     * a prefiltered list view's name and details.
     */
    linkPush: function (){
      if (!this.item.content_type) return null
      const modelName = this.item.content_type.model
      let viewName = modelName.slice(0,1).toLocaleUpperCase() /*😫*/
      viewName += modelName.slice(1) + 'sList' /*😲*/
      return {
        name: viewName,
        params: {
          modelName: modelName,
          preFilters: {
            [this.item.field_name]: this.item.field_value
          }
        }
      }
    }
  },
  methods: {},

}
</script>

<style scoped>
.push-redirection{
  position: relative;
  background-color: var(--flat-color);
  margin: 0;
}
.push-redirection-img-container img{
  width: 100%;
  aspect-ratio: 5 / 2; /* yeah, right, images are to be 5/2 */
}
.push-redirection-caption-container{
  position: absolute;
  z-index: 1;
  bottom: 0px;
  left: 0px;
  padding: 10px 30px;
  background-color: white;
  text-align: center;
  font-family: 'Filson Pro Bold';
  font-size: var(--paragraph);
  line-height: 25px;
  text-transform: uppercase;
}
</style>
