<template>
  <div class="base-strength-bar">
    <div class="label-container">
      <div class="label">
        Password strength
      </div>
      <div class="strength-value">
        {{ passwordStrengthText }}
      </div>
    </div>
    <div
      class="strength-bars-container"
      :class="[`strength-${strength}`]"
    >
      <div
        v-for="(bar, barIdx) in bars"
        :key="'bar' + barIdx"
        :class="['bar', { on: strength > barIdx }]"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseStrengthBar',
  props: {
    strength: {
      type: Number,
      required: true,
      default: -1,
    },
    totalBars: {
      type: Number,
      required: false,
      default: 4,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    bars: function () {
      return new Array(this.totalBars)
    },
    passwordStrengthText: function () {
      const messages = [
        'No password provided',
        'Not secure enough',
        'Not secure enough',
        'Insufficient',
        'Insufficient',
        'Very secure',
      ]
      let idx = 0
      if (this.strength >= 0){
        idx = Math.floor(this.strength +1)
      }
      if (idx >= messages.length){
        idx = messages.length - 1
      }
      return messages[idx]
    },
  },
  watch: {
    strength: function () {
      if (this.strength == this.totalBars) {
        this.$emit('valid')
      } else {
        this.$emit('invalid')
      }
    },
  },
  mounted: function () {},
  methods: {},
}
</script>

<style scoped>
/* PASSWORD STRENGTH */
.base-strength-bar {
  width: 100%;
  font-family: 'Rubik Medium';
  font-size: var(--h7);
}
.label-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 5px;
}
.strength-value {
  text-decoration: underline;
}
.strength-bars-container {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}
.bar {
  width: calc(100% / 3);
  margin-right: 5px;
  height: 5px;
  background-color: var(--grey-medium);
}
.bar:last-child {
  margin-right: 0px;
}
.bar.on{
  background-color: var(--green);
}
.strength-1 .bar.on{
  filter: saturate(0.1);
}
.strength-2 .bar.on{
  filter: saturate(0.3);
}
.strength-3 .bar.on{
  filter: saturate(0.6);
}
</style>


