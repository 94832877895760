<template>
  <div
    v-if="myGallery && myGallery.id"
    class="navbar"
  >
    <template v-for="(menuLink, lIdx) in menuLinks">
      <router-link
        :key="'menulink' + lIdx"
        :to="menuLink.to"
        :class="[
          'menu-link',
          {
            'router-link-active':
              menuLink.to.name.split('-')[0] == $route.name.split('-')[0],
          },
        ]"
      >
        <div class="menu-element">
          <div
            v-if="smallScreen"
            class="element-icon"
          >
            <img
              :src="require(`@/base/assets/img/svgicons/${menuLink.icon}.svg`)"
            >
          </div>
          <div
            v-if="!smallScreen"
            class="element-label"
          >
            {{ menuLink.label }}
          </div>
        </div>
      </router-link>
    </template>
    <div class="action-buttons">
      <BaseButton
        label="Log out"
        @click="onLogoutButtonClick"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MenuAdmin',
  components: {},
  props: {},
  data: function () {
    return {}
  },
  computed: {
    ...mapState('ui', {
      smallScreen: (state) => state.isSmall,
      // menuSmall: state=>state.menuSmall,
      mainMenuOpen: (state) => state.mainMenuOpen,
      // isSmall: state=>state.isSmall
    }),
    ...mapState('gallery', {
      myGallery: (state) => state.myGallery
    }),
    menuLinks: function () {
      return [
        { label: 'Dashboard', icon: 'monitor', to: { name: 'dashboard' } },
        { label: 'Artists', icon: 'user', to: { name: 'artist-list' } },
        { label: 'Artworks', icon: 'image', to: { name: 'artwork-list' } },
        { label: 'Transactions', icon:'shopping-cart', to: { name: 'transaction-list' } },
        { label: 'Import', icon:'upload', to: { name: 'importer-view' } },
        {
          label: 'My gallery',
          icon: 'settings',
          to: { name: 'gallery-view', params: { id: this.myGallery.id } },
        },
      ]
    },
  },
  methods: {
    onLogoutButtonClick: function () {
      this.$store.dispatch('user/logout').then((r) => {
        this.$router.push({ path: '/login' })
      })
    },
    onProfileButtonClick: function () {
      this.$router.push({ name: 'ProfileSettings' })
    },
    toggleMenuSize: function () {
      this.$store.dispatch('ui/toggleMenuSize')
    },
  },
}
</script>

<style scoped>
.navbar {
  display: flex;
  transition: width 0.1s ease-in-out;
  margin-left: auto;
  align-items: center;
}
.menu-element {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}
.menu-element:hover {
  background-color: var(--flat-color);
}
.element-icon {
  display: flex;
  align-items: center;
}
.element-label {
  flex-grow: 1;
  display: flex;
  align-items: center;
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  letter-spacing: var(--h7-ls);
  text-transform: uppercase;
}
a {
  text-decoration: none;
  color: #000;
}
.router-link-active .menu-element {
  background-color: var(--primary-color);
  color: var(--white);
}
.action-buttons {
  display: flex;
  flex-direction: column;
}
.small-theme .action-buttons .base-button-container{
  width: 50px;
  text-align: center;
}

/* MENU IS SMALL */
.menu-small .menu-element {
  padding: 10px;
}
.menu-small .element-label {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  /* font-weight: bold;
  font-size: large; */
}
</style>
