<!--
          .-.. .  .  .-..--
          `-.|-| /_\ |-'|- 
          `-'' ''   ''`-'--
          .  ..--. .. .    
          |\/||- |\|| |    
          '  ''--' '`-'    
-->
<template>
  <div
    :class="['base-share']"
  >
    <BaseButton
      id="share-button"
      label="Share"
      :active="menuIsOpen"
      @click="onShareClick"
    >
      <template slot="special-icon">
        <svg
          id="link-icon"
          viewBox="0 0 24 24" 
        >
          <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
          <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
        </svg>
      </template>
    </BaseButton>
    <!-- :icon="linkIcon" -->
    <div :class="['share-menu', { 'menu-open': menuIsOpen }]">
      <div
        v-for="(rs, rsIdx) in shareNetworks"
        :key="'rsic' + rsIdx"
        class="icon-container"
      >
        <ShareNetwork
          :key="'rsshare' + rsIdx"
          :network="rs"
          :url="currentURL"
          :title="pageTitle"
          :description="pageDescription"
          :quote="pageTitle"
          :hashtags="hashtags"
          :popup="popupOptions"
        >
          <BaseSocialIcon
            class="social-icon"
            :network="rs"
          />
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * THIS HEAVILY DEPENDS ON AN EXTERNAL DEPENDENCY
 * PLEASE READ DOC at https://github.com/nicolasbeauvais/vue-social-sharing
 */
export default {
  name: 'BaseShare',
  props: {
    shareItem: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    shareNetworks: {
      type: Array,
      required: false,
      default: () => {
        return ['twitter', 'facebook', 'linkedin']
      },
    },
  },
  data: function () {
    return {
      menuIsOpen: false,
      hashtags: "TheArtPledge",
      linkIcon: 'link',
      popupOptions: {
        width: 626,
        height: 426
      }
    }
  },
  computed: {
    currentURL: function () {
      // return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST}:${process.env.VUE_APP_PORT}${this.$route.path}`
      return window.location.href
    },
    pageTitle: function () {
      return this.shareItem.metadata
        ? `The Art Pledge | "${this.shareItem.metadata.seo_title}"`
        : ''
    },
    pageDescription: function () {
      return this.shareItem.metadata
        ? `The Art Pledge | "${this.shareItem.metadata.seo_description}"`
        : ''
    },
  },
  mounted: function () {
    window.addEventListener('click', this.onWindowClick)
  },
  beforeDestroy: function () {
    window.removeEventListener('click', this.onWindowClick)
  },
  methods: {
    openMenu: function () {
      this.menuIsOpen = true
    },
    closeMenu: function () {
      this.menuIsOpen = false
    },
    onShareClick: function () {
      this.menuIsOpen = !this.menuIsOpen
      // console.debug('You have clicked. It\'s nice.')
    },
    onWindowClick: function (e) {
      /* https://developer.mozilla.org/en-US/docs/Web/API/Node/contains */
      if (!this.$el.contains(e.target)) {
        this.closeMenu()
      }
    },
  },
}
</script>

<style scoped>
.base-share {
  position: relative;
}
#share-button{
  width: 124px;
}
.share-menu {
  position: absolute;
  box-sizing: border-box;
  width: 124px;
  height: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  background-color: var(--white);
  z-index: 2;
}
.share-menu.menu-open {
  height: auto;
  /* padding: 15px 12px 0px 12px; */
  padding: 5px;
  border: var(--default-ruler);
}
.icon-container {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#link-icon{
  stroke: #000;
  fill: none;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: var(--default-transition);
}
.base-share:hover #link-icon{
  stroke: var(--primary-color);
}
</style>


