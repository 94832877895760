<template>
  <!-- MOBILE VIEW -->
  <div
    v-if="smallScreen"
    class="model-small-container"
  >
    <!-- PORTRAIT -->
    <div class="small-thumb-container">
      <figure class="picture-container"> 
        <img
          v-if="object.portrait"
          :src="object.portrait.thumbnail"
          class="thumb"
        >
        <div
          v-else
          class="empty-thumb"
        />
      </figure>
    </div>
    <div class="small-info">
      <!-- NAME -->
      <div class="small-artist-name">
        <p>{{ object.name }}</p>
        <p>{{ status }} &mdash; {{ published }}</p>
      </div>
    </div>
  </div>

  <!-- DESKTOP VIEW -->
  <tr v-else>
    <!-- see ListView for slot declaration -->
    <slot name="listSelectionControls" />
    <!-- PORTRAIT -->
    <td class="thumb-cell">
      <div class="thumb-container">
        <img
          v-if="object.portrait"
          :src="object.portrait.thumbnail"
          class="thumb"
        >
      </div>
    </td>
    <!-- NAME -->
    <td>
      {{ object.name }}
    </td>
    <!-- STATUS -->
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <!-- see ListView for slot declaration -->
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'

export default {
  name: 'ArtistRow',
  mixins: [modelRowUtil],
}
</script>

<style src="@/assets/css/listrows.css" scoped></style>
