<template>
  <!-- THIS CONTAINER IS 100VW 100VH -->
  <div 
    :class="[
      'modal-container', 
      ...styles
    ]"
  >
    <!-- MODAL IS A SMALL WINDOW IN SCREEN CENTER -->
    <div class="modal-box">
      <!-- ---- TITLE ---- -->
      <header>
        <img :src="require('@/base/assets/img/svgicons/' + icon + '.svg')">
        <h2 class="title-level-4">
          {{ title }}
        </h2>
      </header>
      <!-- ---- MAIN CONTENT ---- -->
      <main class="text-cdt">
        <p>{{ message }}</p>
      </main>
      <!-- ---- BUTTONS LIKE OK,CANCEL ---- -->
      <section class="controls">
        <BaseButton
          label="OK"
          :styles="['CTA']"
          @click.native="onSubmit"  
        />
        <template v-if="isCancellable">
          <BaseButton
            label="Cancel"
            @click.native="onCancel"
          />
        </template>
      </section>
      <!-- ---- DO YOU SEE THE X IN THE TOP RIGHT CORNER ? ---- -->
      <img 
        class="close-cross"
        src="@/base/assets/img/svgicons/x.svg"
        @click="onCancel"
      >
    </div>
  </div>
</template>

<script>
// Store
import { mapState } from 'vuex';
/**
 * Can be used to put more configuration,
 * like other 'theme' icons, but also styles,
 * buttons or whatever will be needed.
 */
const themeMap = {
  success: {
    icon: 'check-circle'
  },
  info: {
    icon: 'info'
  },
  error: {
    icon: 'frown'
  },
  warning: {
    icon: 'alert-triangle'
  },
  bichoice: {
    icon: 'help-circle'
  }
}
export default {
  name: 'Modal',
  props: {
    styles: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

  },
  data: function () {
    return {};
  },
  computed: {
    /** 
     * Computes an icon from themeMap or default
     * could have been left in template but it's too long.
     */
    icon: function(){
      let icon = 'info'
      if (this.theme && themeMap[this.theme]) icon = themeMap[this.theme].icon
      return icon
    },
    /** 
     * Getting all the modal parameters from store.
     * If it gets too heavy, this will need a store on its own.     * 
     */
    ...mapState('ui',{
      title: state=>state.modalTitle,
      theme: state=>state.modalTheme,
      message: state=>state.modalMessage,
      successCallback: state=>state.modalSuccessCallback,
      cancelCallback: state=>state.modalCancelCallback,
    }),
    /** 
     * We don't always need the 'cancel' button.
     */
    isCancellable: function (){
      if (this.cancelCallback) return true
      return false
    }
  },
  mounted: function(){
  },
  /**
   * Modal is destroyed each time it disappears.
   * This could be useful if multiple modals should follow... 
   */
  destroyed: function(){
    // console.log('modal destroyed')
  },
  methods: {
    modalClose: function(){
      this.$store.dispatch('ui/closeModal')
    },
    onSubmit: function (){
      if (this.successCallback){
        this.successCallback()
      }
      this.modalClose()
    },
    onCancel: function(){
      if (this.isCancellable){
        this.cancelCallback()
      }
      this.modalClose()
    }
  },
};
</script>

<style scoped>
/*
  Container is fullscreen. 
  It needs a high z-index and some prayers 
  that nothing will get an other layer context.
*/
.modal-container{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: #7070701A;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.modal-box{
  position: relative;
  min-width: min(458px, 80vw);
  min-height: 200px; 
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #FFF;
  border: 1px solid black;
}
header{
  display: flex;
  align-items: center;
}
header h2{
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
header img{
  width: 20px;
  margin-right: 10px;
}
.controls{
  display: flex;
  justify-content: space-between;
}
.controls .base-button-container{
  margin-right: 40px;
}
.close-cross{
  position: absolute;
  top: 20px;
  right: 20px;
  width: 20px;
}
.close-cross:hover{
  border-radius: 50%;
  box-shadow: 0px 0px 4px #000;
}
</style>
