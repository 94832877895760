<template functional>
  <div :class="['base-logo', props.theme]">
    <img
      v-if="props.small"
      src="../assets/img/logo/LOGO_B_TAP.png"
    >
    <img
      v-else
      src="../assets/img/logo/LOGO_B_SMALL.png"
    >
  </div>
</template>

<script>
export default {
  name: 'BaseLogo',
  props: {
    theme: {
      type: String,
      required: false,
      default: null
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.base-logo{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.base-logo img{
  object-fit: contain;
  width: 100%;
}
</style>
