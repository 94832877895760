import { Workbox } from 'workbox-window'

let wb

if ('serviceWorker' in navigator) {
  wb = new Workbox(`${process.env.BASE_URL}tap-service-worker.js`)

  wb.addEventListener('controlling', () => {
    console.debug('SW: controlling state')
    // window.location.reload()
  })

  wb.addEventListener('waiting', (event) => {
    console.log(`A new service worker has installed, but it can't activate` +
        `until all tabs running the current version have fully unloaded.`);
  });

  wb.register()
} else {
  wb = null
}

export default wb
