<!-- 
  DetailsView handles all the general burden of managing a formulaire.
  It passes the item to form, but also :
  - watches for user interaction with form (dirty, focus events, cancel, ...)
  - handles API calls, success and errors.
  - keep user from losing values by showing a modal as he tries to leave some uncommitted values

  This is a general component for CREATING and EDITING object-model content.
  Forms are mapped inside `modelForm` computed, by using `modelName` as key.
-->
<template>
  <div class="details-view">
    <!-- A LOADER IS SHOWN WHILE FETCHING DATA -->
    <BaseLoader v-if="appLoading" />
    <template v-else>
      <template v-if="withComments">
        <div id="panel-selector-container">
          <div id="panel-selector">
            <!-- Edit details -->
            <div
              class="panel-selector-tab"
              :class="{'active-tab': activePanel == 'form'}"
              @click="activePanel = 'form'"
            >
              <img src="@/base/assets/img/svgicons/edit-3.svg">
            </div>
            <!-- go to Comments -->
            <div
              class="panel-selector-tab"
              :class="[
                {'active-tab': activePanel == 'conversation'},
                {'message-draft': messageDraft }
              ]"
              @click="activePanel = 'conversation'"
            >
              <img src="@/base/assets/img/svgicons/message-square.svg">
              <template v-if="conversationIsActive">
                <div class="active-conversation-pin" />
              </template>
            </div>
          </div>
        </div>
        <div
          v-show="activePanel == 'conversation'"
          id="conversation-panel"
        >
          <BaseConversationPanel
            :item="itemRaw"
            :model-name="modelName"
            :comments="conversationRaw"
            :current-username="$store.state.user.currentUser.username"
            @push-message="onPushMessage"
            @draft-message="onDraftMessage"
            @conversation-status-change="onConversationStatusChange"
          />
        </div>
      </template>
      <div
        v-show="activePanel == 'form'"
        id="details-view-panel"
      >
        <!-- ----- FORMULAIRE ---- -->
        <form
          :is="modelForm"
          id="form-panel"
          :item-raw="itemRaw"
          :item="item"
          :model-name="modelName"
          :errors="errors"
          @dirtyform="onDirty"
          @formok="onFormOk"
          @hintUpdateSeo="onHintUpdateSeo"
        >
          <!-- 
            Controls are placed INSIDE the form as 'named-slots'
            It's enough to declare them here once.
            From inside form, call <slot name="controls" /> in place.
          -->
          <!-- META INFORMATIONS ABOUT ITEM -->
          <template slot="meta-details-container">
            <div class="meta-details-container">
              <div
                v-if="item.metadata"
                class="hint"
              >
                <p>
                  Last updated by
                  <span class="username">{{
                    item.metadata.last_update_user || 'Unknown user'
                  }}</span>
                </p>
                <p>
                  {{ item.metadata.last_update_date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
                </p>
              </div>
              <div class="meta-controls">
                <BaseButton
                  v-if="canDelete"
                  label="Delete"
                  @click="deleteItem"
                />
              </div>
            </div>
          </template>
          <!-- ----- CONTROLS (SAVE/CANCEL) ----- -->
          <template slot="controls">
            <section class="controls">
              <!-- CREATION FORM buttons -->
              <template v-if="creation">
                <BaseButton
                  label="Create"
                  @click="submitCreationForm"
                />
              </template>
              <!-- EDITION FORM buttons-->
              <template v-else>
                <BaseButton
                  label="Cancel"
                  @click="cancelForm"
                />
                <BaseButton
                  label="Save"
                  :disabled="!dirty"
                  :styles="['CTA-nav']"
                  @click="submitForm"
                />
              </template>
            </section>
          </template>
          <!-- ----- METADATA FORM ----- -->
          <template slot="metadata">
            <section v-if="item.metadata">
              <MetadataForm
                v-if="modelForm != 'ArtistForm'"
                :item="item.metadata"
                :errors="errors.metadata"
                :hint-update-seo="hintUpdateSeo"
                @dirtyform="onDirty"
                @formok="onFormOk"
              >
                <section class="controls">
                  <BaseButton
                    label="Cancel"
                    @click="cancelForm"
                  />
                  <!-- SAVE button -->
                  <BaseButton
                    label="Save"
                    :disabled="!dirty"
                    :styles="['CTA-nav']"
                    @click="submitForm"
                  />
                </section>
              </MetadataForm>
            </section>
          </template>
          <!-- ADMIN TAGS -->
          <template slot="admin-tags">
            <div class="admin-tags-container">
              <BaseAdminTag
                v-for="(tag, tagIdx) in adminTags"
                :key="`at-${tagIdx}`"
                :tag-type="tag"
                :item="itemRaw"
              />
            </div>
          </template>
        </form>
      </div>
    </template>
  </div>
</template>

<script>
// Store
import { mapState } from 'vuex'
// Components
import ArtistForm from '@/components/artist/ArtistForm.vue'
import ArtworkForm from '@/components/artwork/ArtworkForm.vue'
import GalleryForm from '@/components/gallery/GalleryForm'
import MetadataForm from '@/components/MetadataForm'
import TransactionForm from '@/components/transaction/TransactionForm'

// Default values for creation form
import defaultModelValues from '@/config/defaultModelValues'

export default {
  name: 'DetailsView',
  components: {
    ArtistForm,
    ArtworkForm,
    GalleryForm,
    MetadataForm,
    TransactionForm,
  },
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null,
    },
    modelName: {
      type: String,
      required: false,
      default: 'artist',
    },
    creation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      item: {} /* used as v-model inside form (dynamic) */,
      itemRaw: {} /* raw data from API (not modified) */,
      errors: {} /* errors come from API */,
      dirty: false /* dirty state means user interacted with form */,
      hintUpdateSeo: false,
      conversationRaw: [],
      messageDraft: false,
      activePanel: 'form',
    }
  },
  computed: {
    ...mapState({
      state(state) {
        return state[this.modelName]
      },
      endpoint(state) {
        return state[this.modelName].endpoint
      },
    }),
    /**
     * mapping the good form for model
     */
    modelForm: function () {
      return {
        artist: 'ArtistForm',
        artwork: 'ArtworkForm',
        gallery: 'GalleryForm',
        transaction: 'TransactionForm',
      }[this.modelName]
    },
    /**
     * used to decide if show the `Loader`
     */
    ...mapState('ui', ['appLoading']),
    /**
     * Not all items include conversations
     * This flags true the ones that do
     */
    withComments: function () {
      const withComments = [
        'artwork-view', 'artist-view', 'gallery-view', 'transaction-view',
      ]
      if (withComments.includes(this.$route.name)) {
        return withComments.includes(this.$route.name)
      } else {
        return withComments.includes(this.$route.name)
      }
    },
    conversationIsActive: function (){
      if (!this.item.metadata) return false
      return this.item.metadata.conversation_active
    },
    canDelete: function () {
      const modelIsDeletable = !['artist', 'gallery'].includes(this.modelName)
      return !this.creation && modelIsDeletable
    },
    /**
     * Makes a list of admin-tags to 
     * display inside the admin-tags slot (inside form header)
     */
    adminTags: function () {
      /* default admin-tags applied to all forms */
      let defaultTags = [
        'item-status',
        'publication-status',
      ]
      /* custom admin-tags by modelform */
      let modelTags = {
        transaction: ['transaction-status']
      }[this.modelName]

      if (modelTags && modelTags.length) return modelTags
      return defaultTags
    }
  },
  watch: {
    /**
     * itemRaw changes only at first mount,
     * and more ofter when new value comes from API
     * when changed we want to reset form state
     */
    itemRaw: function () {
      this.initForm()
    },
    modelName: function () {
      this.getItem()
    },
  },
  mounted: async function () {
    /**
     * Deciding this view mode : creation or edition ?
     */
    if (!this.creation) {
      this.$store.dispatch('ui/setAppLoading', true)
      await this.getItem()
      this.getComments()
    } else {
      this.initCreationForm()
    }
    this.setListPageTitle()
  },
  /**
   * beforeRouteLeave is a navigation guard
   * it's used here to warn user by showing a blocking modal
   * when trying to leave a form with not-submitted values.
   */
  beforeRouteLeave: function (to, from, next) {
    if (!this.creation && this.dirty) {
      this.$store.dispatch('ui/openModal', {
        title: 'Are you sure ?',
        message:
          'The form has been modified, if you leave, it will not be saved.',
        theme: 'warning',
        successCallback: next,
        cancelCallback: () => {},
      })
    } else {
      next()
    }
  },
  methods: {
    /**
     * Formulaire values initialisation
     * at this point `itemRaw` is either a Promise from API,
     * or set by `initCreationForm`
     */
    initForm: function () {
      // this.item = {...this.itemRaw}
      this.item = JSON.parse(JSON.stringify(this.itemRaw))
      this.errors = {}
      this.dirty = false
      this.hintUpdateSeo = false
      this.setListPageTitle()
    },
    initCreationForm: function () {
      /* Special case for Artwork form -- gallery defaults
        to myGallery */
      if (this.modelName == 'artwork'){
        this.itemRaw = {
          ...defaultModelValues,
          gallery: {id:this.$store.state.gallery.myGallery.id}
        }
      } else {
        this.itemRaw = { ...defaultModelValues[this.modelName] }
      }
    },
    getItem: async function () {
      this.$store.dispatch('ui/setAppLoading', true)
      await this.endpoint.getByID(this.id).then((response) => {
        this.itemRaw = response.data
        this.$store.dispatch('ui/setAppLoading', false)
      })
    },
    onDirty: function () {
      this.dirty = true
    },
    onFormOk: function () {
      this.dirty = false
    },
    submitCreationForm: function () {
      this.endpoint
        .create(this.item)
        .then((response) => {
          // console.debug('DETAILSVIEW: ok creation form response', response)
          this.$store.dispatch('ui/openModal', {
            title: 'ok !',
            message: `A new ${this.modelName} have been successfully created.`,
            theme: 'info',
            successCallback: () => {
              const urlTo = {
                name: this.modelName + '-view',
                params: { id: response.data.id },
              }
              this.$store.dispatch(this.modelName + '/search')
              console.debug('DETAILSVIEW: creation success !', urlTo, this.$router)
              this.$router.push(urlTo)
            },
            cancelCallback: () => {},
          })
        })
        .catch((error) => {
          this.notifyError(error)
        })
    },
    submitForm: function () {
      this.$store.dispatch('ui/setAppLoading', true)
      this.endpoint
        .update(this.id, this.item)
        .then((response) => {
          // console.debug('DETAILSVIEW: ok form update', response)
          this.itemRaw = response.data
          this.dirty = false
          this.$store.dispatch('ui/openModal', {
            title: 'Update success',
            message: 'Changes have been saved.',
            theme: 'success',
          })
          this.$store.dispatch(this.modelName + '/search')
          this.errors = {}
        })
        .catch((error) => {
          this.notifyError(error)
        })
        .finally(() => {
          this.$store.dispatch('ui/setAppLoading', false)
        })
    },
    cancelForm: function () {
      this.getItem().then(() => {
        this.initForm()
      })
    },
    setListPageTitle: function () {
      this.$nextTick(() => {
        const name =
          this.item.name ||
          this.item.title ||
          this.modelName + ' ' + this.item.id
        const pageTitle = name.charAt(0).toLocaleUpperCase() + name.slice(1)
        this.$store.dispatch('ui/setAppTitle', 'TAP-' + pageTitle)
      })
    },
    notifyError: function (error) {
      console.debug('DETAILSVIEW: form error', error)
      if (error.response && typeof error.response.data == 'object') {
        const errfields = Object.keys(error.response.data)
        this.$store.dispatch('ui/openModal', {
          title: 'Error ' + error.response.status,
          message: 'Unable to save ' + errfields.join(', ') + '.',
          theme: 'error',
          successCallback: () => {},
          // cancelCallback: ()=>{}
        })
      } else {
        this.$store.dispatch('ui/openModal', {
          title: 'Error ' + error.response.status,
          message: 'Message: ' + error.response.statusText,
          theme: 'error',
          successCallback: () => {},
          // cancelCallback: ()=>{}
        })
      }
      this.errors = error.response.data
    },
    onHintUpdateSeo: function (val) {
      this.hintUpdateSeo = val
    },
    goSearch: async function () {
      this.$store.commit(this.modelName + '/updateSearchQuery')
      return this.$store.dispatch(this.modelName + '/search')
    },
    deleteItem: function () {
      this.$store.dispatch('ui/openModal', {
        title: 'Are you sure ?',
        message: `Do you really want to delete ${this.modelName} ${
          this.item.name || this.item.title
        } ?`,
        theme: 'warning',
        successCallback: () => {
          this.$TapGallery[this.modelName].destroy(this.item.id).then(() => {
            this.goSearch()
            this.$router.push({ name: this.modelName + '-list' })
          })
        },
        cancelCallback: () => {},
      })
    },
    /**
     * CONVERSATION
     * refresh comments
     */
    getComments: async function () {
      if (this.item.id && this.withComments) {
        return this.endpoint
          .getComments(this.item.id)
          .then((response) => {
            if (response) {
              this.conversationRaw = [...response.data.results]
            }
            return true
          })
          .catch((error) => {
            return error
          })
      } else {
        return false
      }
    },
    /**
     * CONVERSATION
     * send message
     */
    onPushMessage: async function (value) {
      this.endpoint.addComment(this.item.id, value)
        .then((response) => {
          if (response) {
            this.conversationRaw = [...response.data.results]
          }
        })
    },
    /**
     * CONVERSATION 
     * draft status
     */
    onDraftMessage: function (value){
      this.messageDraft = value
      if (value) this.dirty = true
    },
    /**
     * CONVERSATION
     * change conversation status
     */
    onConversationStatusChange: function (value) {
      this.endpoint.conversationStatus(this.item.id, value)
        .then((response) => {
          if (response) {
            this.getComments()
          }
        })
      // .catch((error) => {
      //       return error
      // })
    },
  },
}
</script>

<style scoped>
.details-view{ }

#form-panel {
  margin-bottom: 100px;
}
section.controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  /* margin: 0px 0px 20px 0px; */
}
section.controls .base-button-container {
  margin-right: 20px;
}
.meta-details-container {
  /* display: block; */
  /* justify-content: space-between; */
}
.meta-controls {
  margin-top: 12px;
}
.hint {
  font-family: 'Rubik Regular';
  /* display: flex; */
  /* align-items: center; */
  background-color: #fff;
  animation: 1s flashout;
}
.username {
  font-weight: bold;
}
/* PANEL SELECTOR */
#panel-selector-container{
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  display: flex;
  z-index: 10;
}
#panel-selector{
  margin: auto;
  /* transform-origin:left;
  transform: translateX(100%) translateY(-20px) rotate(90deg);   */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 32px;
  margin-bottom: 10px;
  margin-top: -10px;
  /* border-bottom: var(--default-ruler); */
  font-family: 'Filson Pro Regular';
}
.active-conversation-pin{
  position: relative;
  top: -1rem;
  width: 5px;
  height: 5px;
  margin-right: -5px;
  border-radius: 50%;
  background-color: var(--primary-color);
}
.panel-selector-tab{
  background-color: var(--flat-color);
  padding: 10px;
  display: flex;
  align-items: center;
  user-select: none;
  box-sizing: border-box;
}
.panel-selector-tab:hover{
  background-color: #fff;
  cursor: pointer;
}
.panel-selector-tab.active-tab{
  background-color: var(--grey-medium);
  border-bottom: 1px solid black;
}
.panel-selector-tab.message-draft{
  filter: invert(1);
}
.admin-tags-container{
  display: flex;
}
</style>