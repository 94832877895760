export const baseselectors = {
  props: {
    /**
     * Options is an Array of choices.
     * Choices are of the form {display_name:"..", value: <pk>|<value> }
     */
    options: {
      type: Array,
      required: false,
      default: () => {
        return [{ value: null, display_name: '---' }]
      }
    },
    /**
     * For choices that are objects with multiple keys
     * we need to designate a key that assures equality when
     * comparing actual values to available choices.
     * If reverseField is not defined, direct values will be assumed.
     */
    reverseField: {
      type: String,
      required: false,
      default: null
    },
    /**
     * dataSource should be an API-model-Object that provides
     * 'searchFast' and 'proloadOptions' methods
     */
    dataSource: {
      type: Object,
      required: false,
      default: () => { return null }
    },
  },
  data: function() {
    return {
      mouseOverBox: false,
      searchQueryInput: '',
      showOptions: false,
      valueMappedRemoteOptions: [],
      remoteOptions: [],
      isLoading: false,
      /* keyboard selection */
      forceHoverIdx: null,
      /* debouncing */
      // isTyping: false,
      // startTypeAt: 0,
      // typeTimeoutID: null,
      // DEBOUNCE_TIMEOUT: 1000 /* time before firing datasource (ms) */,
    }
  },
  computed: {
    /**
     * Maps the correct 'choice' for the selected value(s).
     * @returns {} - an Array if multiselect, a direct value or PK if not
     */
    selectedOptions: function () {
      if (this.value === null || this.value === undefined || this.value.length == 0){
        return null
      }
      if (this.valueIsObject) {
        if (this.multiSelect){
          const selectedPKs = this.value.map((v) => v[this.reverseField])
          const s = this.availableOptions.filter((o) =>
            selectedPKs.includes(o.value)
          )
          return s
        } else {
          const selectedPK = this.value[this.reverseField]
          if (!selectedPK) return null
          const s = this.availableOptions.find((o) => selectedPK == o.value)
          return s
        }
      } else {
        if (this.multiSelect){
          return this.availableOptions.filter((o) => this.value.includes(o.value))
        } 
        return this.availableOptions.find((o) => o.value == this.value)
      }
    },
    /**
     * Returns fetched values if dataSource provided, else provided options.
     * @returns {Array} - a list of choices
     */
    availableOptions: function() {
      if (this.dataSource) {
        return [...this.valueMappedRemoteOptions, ...this.remoteOptions]
      } else {
        return this.options
      }
    },
    /**
     * Computes a list of options suitable to display.
     * @returns {Array} - a list of choices
     */    
    displayableOptions: function () {
      /* no value and no research in progress, so return all available options */
      if (!this.selectedOptions && !this.searchQueryInput) return this.availableOptions
      let selectedPKs = (this.multiSelect) ? [] : null
      let notSelected = []
      if (this.multiSelect){
        /* MULTISELECT */
        if (this.selectedOptions){
          selectedPKs = this.selectedOptions.map(o=>o.value)
        }
        notSelected = this.availableOptions.filter(o=>!selectedPKs.includes(o.value))
      } else {
        /* BASESELECT */
        if (this.selectedOptions){
          selectedPKs = this.selectedOptions.value
        }
        notSelected = this.availableOptions.filter(o=>o.value != selectedPKs)
      }
      if (!this.searchQueryInput){
        return notSelected
      } else {
        const displayableHTML = this.getHTMLDisplayable(notSelected)
        return displayableHTML
      }
    },
    /**
     * We derivate the text input event to avoid it being bubbled
     * up and mess with baseinputcontainer v-model
     * events binding expectations.
     * We could have decoupled all events,
     * but we rely upon textinput focus/blur events.
     */
    inputListeners: function() {
      let vm = this
      return {
        ...this.$listeners,
        input: function(value) {
          // vm.$emit('input', value)
          // Hey, let's do nothing instead !

          /* fetches new results if user inputs text */
          if (vm.dataSource && vm.searchQueryInput.length){
            vm.searchRemoteOptions()
          }
        }
      }
    },
    /**
     * Text included in textinput, makes a cheap waitloader.
     * @returns {string}
     */
    placeholderText: function() {
      const loadingMessage = 'Loading selection ...'
      return this.isLoading ? loadingMessage : this.placeholder
    },
    /**
     * Base multiselect can handle direct values
     * or object values. We need to know for correct mapping
     * of choices and value content.
     * @returns {boolean}
     */
    valueIsObject: function () {
      return this.reverseField != null
    },
  },
  watch: {
    value: function (nv, ov){
      // console.debug(this.$parent.label, 'value has changed from', ov, 'to', nv)
      if (nv === null || nv === undefined || nv.length == 0){
        this.searchQueryInput = ''
      }
    }
  },
  mounted: async function (){
    if (this.dataSource){
      await this.preloadRemoteOptions()
    }
    window.addEventListener('click', this.onWindowClick)
  },
  beforeDestroy: function () {
    window.removeEventListener('click', this.onWindowClick)
  },
  methods: {
    resetValue: function () {
      this.newValue = (this.multiSelect)?[]:undefined
      this.selectValue()
    },
    onWindowClick: function (event){
      if (!this.$el.contains(event.target) && this.showOptions) {
        // console.debug('clicked out of', this.$parent.label, event.target, this.$el.contains(event.target))
        event.preventDefault()
        this.closeList()
      }
    },
    preloadRemoteOptions: async function () {
      /* no need to preload options if no value */
      if (this.value === null || this.value === undefined){
        this.valueMappedRemoteOptions = []
        return null
      }
      this.isLoading = true
      /* creates an Array of PKs to preload */
      let preloadValues = []
      if (this.valueIsObject){
        if (this.multiSelect){
          preloadValues = this.value.map(v=>{
            return { [this.reverseField]: v[this.reverseField] }
          })
        } else {
          preloadValues = [{[this.reverseField]: this.value[this.reverseField]}]
        }
      } else {
        preloadValues = [{ [this.reverseField]: this.value }]
      }
      /* fetching data */
      return this.dataSource.preloadOptions(preloadValues)
        .then((response)=>{
          this.valueMappedRemoteOptions = [...response.data.results]
          this.isLoading = false
        })
    },
    searchRemoteOptions: async function (){
      this.isLoading = true
      // console.debug('selector GOINGTOFETCH', this.$parent.label)
      return this.dataSource.searchFast({search: this.searchQueryInput})
        .then((response) => {
          // console.debug(this.$parent.label, 'selector GOTANSWER', response)
          /* excluding preloaded to avoid duplicates */
          const preloadedPKs = this.valueMappedRemoteOptions.map(o=>o.value)
          const withoutPreloadedPKs = response.data.results.filter(o=>{
            return !preloadedPKs.includes(o.value)
          })
          this.remoteOptions = [...withoutPreloadedPKs]
          this.isLoading = false
        })
    },
    onTextInputFocus: function() {
      if (!this.showOptions) this.openList()
    },
    // startTimer: function(nv, ov) {
    //   this.typeTimeoutID = setTimeout(() => {
    //     this.isTyping = false
    //     if (nv != ov && this.dataSource) {
    //       this.updateRemoteOptions()
    //     }
    //   }, this.DEBOUNCE_TIMEOUT)
    // },
    /**
     * step is -1 or +1
     * defines the forceHoverIdx index
     * for keyboard selection
     */
    onUpDownKey: function(step) {
      if (this.showOptions) {
        this.forceHoverIdx = Math.max(
          0,
          Math.min(
            this.forceHoverIdx + step,
            this.displayableOptions.length - 1
          )
        )
        this.scrollToOption(this.forceHoverIdx)
      }
    },
    /**
     * handles selection by using 'ENTER' key
     */
    onKeyEnter: function() {
      this.onOptionClick(this.forceHoverIdx)
    },
    /**
     * when using keyboard arrows,
     * handles options-list scrolling to
     * show preselected value
     */
    scrollToOption: function(idx) {
      let optionEl = this.$refs[`opc-${idx}`][0]
      if (optionEl) {
        // console.debug('scrolling to ', idx, optionEl)
        /* scrollin to the option with some space to show surrounding options if any */
        this.$refs.optionsListEl.scrollTop =
          optionEl.offsetTop - optionEl.offsetHeight
      }
    },
    getHTMLDisplayable: function (displayable){
      /* filtering by text input */
      const sq = this.searchQueryInput.toLowerCase()
      const displayableAndReparsed = displayable
        .filter(o=>{
          const matchOk = o.display_name.toString().toLowerCase().indexOf(sq)
          return matchOk >= 0
        })
        .map(o=>{
          const orig = o.display_name.toString()
          const idx = orig.toLowerCase().indexOf(sq)
          const spanStart = `<span style="font-weight: bold;">`
          const spanEnd = `</span>`
          let before = orig.substring(0,idx)
          if (this.displayId) before = `${o.value}.&nbsp${before}`
          const matching = orig.substr(idx, sq.length)
          const after = orig.substr(idx+sq.length, orig.length -1)
          // console.table({before:before, matching:matching, after:after})
          return {
            ...o,
            htmlDisplayName: `${before}${spanStart}${matching}${spanEnd}${after}`
          }
        })
      return displayableAndReparsed
    }
    // getHTMLDisplayable: function (displayable){
    //   /* filtering by text input */
    //   const regstr = '.*' + this.searchQueryInput.split('').map(c=>`(${c})`).join('.*') + '.*'
    //   const reg = new RegExp(regstr, 'igd')
    //   const displayableAndReparsed = displayable
    //     .filter(o=>{
    //       const matchOk = o.display_name.toString().match(reg)
    //       return matchOk
    //     })
    //     .map(o=>{
    //       const orig = o.display_name.toString()
    //       const matches = [...orig.matchAll(reg)]
    //       if (this.searchQueryInput.length && matches.length){
    //         // console.log(matches)
    //         const m = matches[0].indices
    //         let ns = orig.slice(0, m[1][0])
    //         for (let i = 1; i<m.length;i++){
    //           ns += `<span style="font-weight: bold;">${orig.slice(m[i][0],m[i][1])}</span>`
    //           if (i<m.length -1){
    //             ns += orig.slice(m[i][1], m[i+1][0])
    //           }
    //         }
    //         ns += orig.slice(m[m.length-1][1])
    //         // console.log(ns)
    //         return {...o, htmlDisplayName: ns}
    //       }
    //       return o
    //     })
    //   return displayableAndReparsed
    // }
  }
}
