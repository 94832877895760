<template>
  <div
    :class="['base-select', ...styles, {'disabled': disabled}]"
    @mouseenter="mouseOverBox = true"
    @mouseleave="mouseOverBox = false"
    @click="onBoxClick"
    @keydown.down="onUpDownKey(1)"
    @keydown.up="onUpDownKey(-1)"
    @keydown.enter="onKeyEnter"
    @keydown.esc="closeList"
  >
    <!-- FIRST ROW IS SHOWN WHEN NOTHING HAPPENS -->
    <div
      ref="firstRow"
      class="first-row"
    >
      <div class="selected-option">
        <div
          ref="selectedOptionText"
          class="option-text"
        >
          <template v-if="selectedOptions && !showInput">
            <template v-if="displayId">
              {{ selectedOptions.value }}.&nbsp;
            </template>
            {{ selectedOptions.display_name }}
          </template>
        </div>
      </div>
      <!-- A text input field to autocomplete/search -->
      <div
        v-show="showInput"
        class="search-input-container"
      >
        <BaseTextInput
          ref="inputElement"
          v-model="searchQueryInput"
          :placeholder="placeholderText"
          v-on="inputListeners"
          @focus="onTextInputFocus"
          @blur="onTextInputBlur"
        />
      </div>
      <!-- Controls handle dropdown display state -->
      <div
        class="controls"
        @mouseenter="mouseOverControls = true"
        @mouseleave="mouseOverControls = false"
      >
        <img
          v-if="!disabled && selectedOptions && !showOptions"
          src="../../assets/img/svgicons/x.svg"
          class="remove-all-cross"
          @click="resetValue"
        >
        <img
          v-if="!disabled"
          src="../../assets/img/svgicons/chevron-down.svg"
          :class="['arrow', { 'flip-arrow': showOptions }]"
          @click="onArrowClick"
        >
      </div>
    </div>
    <!-- THE REAL OPTIONS LIST -->
    <div
      v-if="showOptions"
      ref="optionsListEl"
      class="options-list"
    >
      <template v-if="isLoading">
        <div class="option-container">
          <div class="option-text">
            L O A D I N G
          </div>
        </div>
      </template>
      <template v-else>
        <template v-for="(option, oIdx) in displayableOptions">
          <div
            :key="'opc-' + oIdx"
            :ref="'opc-' + oIdx"
            :class="[
              'option-container',
              { 'force-hover': oIdx == forceHoverIdx },
            ]"
            @click="onOptionClick(oIdx, ...arguments)"
          >
            <!-- enhanced results regexp highlights -->
            <template v-if="option.htmlDisplayName">
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="option-text"
                v-html="option.htmlDisplayName"
              />
              <!--eslint-enable-->
            </template>
            <!-- standard results -->
            <template v-else>
              <div class="option-text">
                <template v-if="displayId">
                  {{ option.value }}.&nbsp;
                </template>
                {{ option.display_name }}
              </div>
            </template>
          </div>
        </template>
        <template v-if="displayableOptions.length == 0">
          <div class="option-container">
            <div class="option-text">
              No selection available.
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'
import { baseselectors } from '../../mixins/baseselectors'

export default {
  name: 'BaseSelect',
  mixins: [baseinputs, baseselectors],
  props: {
    value: {
      type: [Object, Array, String, Number, Date],
      required: false,
      default: () => {
        return null
      },
    },
    /**
     * displays the 'value' (usually ID) of each option in addition 
     * to its 'display_name'. Useful for SDGs
     */
    displayId: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: function () {
    return {
      mouseOverControls: false,
      multiSelect: false,
      newValue: {},
    }
  },
  computed: {
    showInput: function () {
      const noValue = this.selectedOptionIdx < 0
      const listClosed = !this.showOptions
      const shouldShow = !listClosed || (noValue && listClosed)
      return shouldShow
    },
    selectedOptionIdx: function () {
      return this.availableOptions.indexOf(this.selectedOptions)
    },
  },
  methods: {
    /**
     * Options list display management
     */
    openList: function () {
      if (this.dataSource) this.searchRemoteOptions()
      this.showOptions = true
      this.$nextTick(() => {
        this.forceHoverIdx = 0
        this.$el.querySelector('input').focus()
      })
    },
    closeList: function () {
      this.searchQueryInput = ''
      this.$nextTick(() => {
        this.forceHoverIdx = null
        this.showOptions = false
      })
    },
    /**
     * Option selectors, handle different cases.
     * Select, Reset
     */
    selectValue: function () {
      if(this.disabled){
        return null
      }else{
        if (this.newValue == null) {
          this.$emit('input', null)
        } else {
          if (this.valueIsObject) {
            this.$emit('input', this.newValue)
          } else {
            this.$emit('input', this.newValue.value)
          }
        }
      }
    },
    onOptionClick: function (oIdx, event) {
      const sel = this.displayableOptions[oIdx]
      // console.debug(
      //   'BASESELECT: '
      //   `Selected! Index: ${oIdx}`,
      //   `Valeur: ${sel.value}`,
      //   `display_name: ${sel.display_name}`,
      // )
      if (this.valueIsObject) {
        this.newValue = { [this.reverseField]: sel.value }
      } else {
        this.newValue = sel
      }
      this.selectValue()
      this.closeList()
    },
    onTextInputBlur: function (event) {
      if(this.disabled){
        return null
      }else{
        if (!this.mouseOverBox) {
          this.closeList()
        }
        this.$emit('blur')
      }
    },
    onArrowClick: function () {
      if (this.showOptions) {
        this.closeList()
      } else {
        this.openList()
      }
    },
    onBoxClick: function (event) {
      const listClosed = !this.showOptions
      const mouseOverOptions = !this.mouseOverBox && !this.mouseOverControls
      const clickedFirstRow =
        event.target == this.$refs.firstRow ||
        event.target == this.$refs.selectedOptionText
      const firstRowIsOption = !this.showInput
      const clickedOnSelected = clickedFirstRow && firstRowIsOption
      if ((listClosed && mouseOverOptions) || clickedOnSelected) {
        this.openList()
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style src="../../assets/css/selectors.css" scoped></style>
<style scoped>
.base-select {
  position: relative;
  /* min-width: 300px; */
  width: 100%;
  --option-tag-height: calc(var(--field-default-height) - 2 * var(--field-default-v-padding));
}
.first-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.search-input-container {
  height: var(--option-tag-height);
  margin-right: 0.5rem;
  min-width: 163px;
  width: 100%;
  display: flex;
  align-items: center;
}
.arrow.flip-arrow {
  transform: rotate(180deg);
}
.options-list {
  border-top: 1px solid #707070;
  top: calc(100% + 5px);
}
.base-textinput-container {
  width: 100%;
}
.selected-option{
  /* white-space: nowrap; */
  /* text-overflow: ellipsis; */
  /* overflow: hidden; */
}


/* ----- 🚧 WIP below 🚧 ----- */
/* Theme : VOLUNTAS */
.voluntas .selected-option .option-text {
  line-height: 2.5rem;
  font-family: 'Rubik Semibold';
  font-size: 2rem;
  text-transform: lowercase;
}
.voluntas .options-list .option-container:hover {
  background-color: #dbdbdb;
}
.voluntas .options-list .option-container:hover .option-text {
  color: #000;
}
.voluntas .controls .cross {
  display: none;
}
.voluntas .base-select .options-list {
  border: 1px solid #dbdbdb;
}
.disabled {
  pointer-events:none;
  background-color: rgb(250,250,250);
  color: var(--grey-dark);
}
/* .voluntas input::placeholder{
  font-family: 'Rubik Semibold Italic';
  font-size: 40px;
  letter-spacing: 2.4px;
} */
</style>
