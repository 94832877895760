<template>
  <div class="dashboard">
    <div class="form-header">
      <div>
        <h2>Import</h2>
        <h1>Artworks</h1>
      </div>
    </div>
    <!-- ------------ CONVERSATIONS ------------ -->
    <div class="process">
      <p class="intro">
        Welcome to the artwork importation process!<br>
        This tool provides a way to add and/or update your artworks in The Art Pledge using a semi-automatic process.<br>
        To do so, follow the steps.
      </p>

      <h3>Read the documentation</h3>

      <a
        v-if="documentationURL"
        :href="documentationURL"
      >  
        <BaseButton label="Read the documentation" />
      </a>
      <p v-else>
        Coming soon...
      </p>

      <h3>Download the template file</h3>

      <p>Click to download the empty excel spreadsheet.</p>
      <a
        v-if="templateURL"
        :href="templateURL"
      >  
        <BaseButton label="Download the template" />
      </a>
      
      <h3>Edit the template file</h3>

      <h4>
        Important reminders:
      </h4>
      <p>
        Add one work per line.<br>
        Columns with red headers are mandatory.<br>
        Use only provided values when drop-down list are provided.<br>
        Invalid works might be imported but will not be published until all errors are fixed.
        Use Excel 2010 format to save your spreadsheet<br>
      </p>
      
      <h3>Upload your file</h3>

      <p>Select your file and click on "Upload artworks". Your file will be uploaded, the artworks imported and a log will be displayed once the process is over.</p>
      <p>Warning: this action cannot be undone.</p>

      <!-- ------------ LOADER ------------ -->
      <BaseLoader v-if="appLoading" />
      <div
        v-else
        class="form"
      >
        <BaseInputContainer
          v-model="spreadsheet"
          :required="true"
          field-type="regularfile"
          @update="onFieldInput"
        />
        <BaseButton
          label="Upload artworks"
          :disabled="!spreadsheet.file"
          @click="uploadSpreadsheet"
        />
      </div>

      <template v-if="!appLoading && Object.keys(logs).length != 0">
        <h3>Review your upload</h3>
        <BaseInputContainer
          v-model="filterActionChoicesValue"
          class="filter-choice"
          field-type="radio"
          label="Show..."
          :options="filterActionOptions"
          @click.native="filterActions"
        /> 
        <BaseInputContainer
          v-model="filterErrorChoicesValue"
          class="filter-choice"
          field-type="radio"
          :options="filterErrorOptions"
          @click.native="filterLogs"
        /> 
        <div
          v-for="(error, rIdx) in filteredByLogs"
          :key="'error-' + rIdx"
          :class="[
            'row',
            { 'created' : error.action == 'created' }, 
            { 'updated' : error.action == 'updated' },
            { 'failed' : error.action == 'failed'}
          ]"
        >
          <div class="line">
            Line {{ error.line }}<br>
            <span class="artwork">{{ error.title }}</span>
          </div>
          <div class="staction">
            <!-- action -->
            <img
              v-if="error.action == 'created'"
              title="Created"
              src="@/base/assets/img/svgicons/plus-square.svg"
            >
            <img
              v-else-if="error.action == 'updated'"
              title="Updated"
              src="@/base/assets/img/svgicons/refresh-cw.svg"
            >
            <img
              v-else
              title="Failed"
              src="@/base/assets/img/svgicons/x-square.svg"
            >
            <!-- status -->
            <img
              v-if="Object.keys(error.errors).length >= 1 "
              title="Error"
              src="@/base/assets/img/svgicons/alert-triangle.svg"
            >
            <img
              v-else
              title="Ok"
              src="@/base/assets/img/svgicons/check-circle.svg"
            >
          </div>
          <ul class="errorlist">
            <li v-if="error.failure">
              {{ error.failure.toUpperCase() }}
            </li>
            <li
              v-for="(message, field) in error.errors"
              :key="'error-' + field"
            >
              <code>{{ field }}</code> &ndash; {{ message }}
            </li>
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ImporterView',
  components: {},
  data: function() {
    return {
      documentationURL: "",
      templateURL: "",
      spreadsheet: {},
      logs: {},
      filterByActions: {},
      filterActionChoicesValue: 'A',
      filterActionOptions: [
        {
          label: 'All',
          value: 'A',
          showHelpText: false
        },
        {
          label: 'Created',
          value: 'created',
          showHelpText: false
        },
        {
          label: 'Updated',
          value: 'updated',
          showHelpText: false
        },
        {
          label: 'Failed',
          value: 'failed',
          showHelpText: false
        },
      ],
      filteredByLogs: {},
      filterErrorChoicesValue: 1,
      filterErrorOptions: [
        {
          label: 'All',
          value: 'A',
          showHelpText: false
        },
        {
          label: 'With Errors',
          value: 1,
          showHelpText: false
        },
        {
          label: 'Without Errors',
          value: 0,
          showHelpText: false
        },
      ]
    }
  },
  computed: {
    ...mapState('gallery', ['myGallery', 'endpoint']),
    ...mapState('ui', ['appLoading']),
  },
  mounted: async function () {
    await this.getTemplate()
  },
  methods: {
    getTemplate: async function () {
      await this.endpoint.artworkUploadTemplate(this.myGallery.id).then((response) => {
        this.templateURL = response.data.template_url
      })
    },
    onFieldInput: function(file) {
      this.spreadsheet = {...file}
    },
    uploadSpreadsheet: async function () {
      this.$store.dispatch('ui/setAppLoading', true)
      await this.endpoint.artworkUpload(
        this.myGallery.id,
        {"spreadsheet": this.spreadsheet}
      ).then((response) => {
        this.$store.dispatch('ui/setAppLoading', false)
        this.logs = response.data.logs
        this.filterActions()
      }).catch(() => {
        this.$store.dispatch('ui/setAppLoading', false)
      })
    },
    filterActions: function() {
      // A for All
      if(this.filterActionChoicesValue == 'A'){
        this.filterByActions = this.logs
        return this.filterLogs()
      } 
      // update, created or failed
      else {
        this.filterByActions = this.logs.filter(error => error.action == this.filterActionChoicesValue)
        return this.filterLogs()
      }
    },
    filterLogs: function() {
      // A for All
      if(this.filterErrorChoicesValue == 'A'){
        return this.filteredByLogs = this.filterByActions
      } 
      // check if errors.columns is not empty
      else if(this.filterErrorChoicesValue >= 1) {
        return this.filteredByLogs = this.filterByActions.filter(error => Object.keys(error.errors).length >= this.filterErrorChoicesValue)
      } 
      else {
        return this.filteredByLogs = this.filterByActions.filter(error => Object.keys(error.errors).length == this.filterErrorChoicesValue)
      }
    },
  }
  // data: function() { },
  // computed: { },
}
</script>

/* for form header */
<style src="../assets/css/forms.css" scoped></style>

<style scoped>
.process {
  font-family: "Rubik Regular";
  max-width: 600px;
  margin: 30px auto;
}

.process h3 {
  margin-top: 1em;
  display: list-item;
  list-style: decimal;
  list-style-position: inside;
}

.process p, .process h4, .process .filter-choice {
  margin: 0 1em 1em 1em;
}
.process p.intro {
  margin: 0;
  font-size: var(--h5);
}

.form {
  display: flex;
}

.row {
  padding: 5px 0 5px 10px;
  border: 1px solid var(--ruler-color);
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.created{
  border-left: 5px solid var(--green);
}
.updated{
  border-left: 5px solid var(--primary-color);
}
.failed{
  border-left: 5px solid var(--red);
}

.line {
  flex: 0 0 25%;
}
.staction {
  flex: 0 0 min-content;
}
.errorlist {
    flex: 0 0 50%;
}

.artwork {
  font-family: 'Rubik Italic';
}

</style>
