import Vue from 'vue'
import axios from 'axios'

/*
  This parses the "api" folder and
  considers every "*.service.js" file as an enabled service.
  ⚠ Please chose service name short and comprehensible and avoid ES keywords.
*/
const availableAPIs = require.context(
  '@/services/api/services',
  false,
  /\.service\.js$/
)

/*
  All found services are initialized
*/
const init = () => {
  availableAPIs.keys().forEach(filename => {
    /* ------- API network configuration ---------- */
    const apiDescription = availableAPIs(filename)
    /*  If filename is "Sample.service.js", service name will be "Sample"
        and it will be accessible at App level by `this.$TapAdmin`          */
    const serviceName = filename.split('/').pop().split('.')[0]
    // We grab the base URL for this API
    const url = apiDescription.default.baseURL
    // Interceptors
    const interceptors = apiDescription.default.interceptors
    // Base URL reconstitution
    const baseURL = [
      url.protocol,
      '://',
      url.host,
      (['', undefined, '80', 80].includes(url.port)) ? '' : ':' + url.port,
      url.root
    ].join('')

    /* ----- Axios instance configuration ----- */
    const config = { ...apiDescription.default.config, baseURL: baseURL }
    const _axios = axios.create(config)
    _axios.interceptors.request.use(interceptors.request.configInterceptor, interceptors.request.errorInterceptor)
    _axios.interceptors.response.use(interceptors.response.responseInterceptor, interceptors.response.errorInterceptor)

    /* ----- API modelization ------------ */
    // "Models" get CRUD and custom methods, "special" for not-model functionalities
    const modelURLs = apiDescription.default.modelURLs
    const specialURLs = apiDescription.default.specialURLs

    const methods = {}
    Object.keys(modelURLs).forEach(k => {
      const modelMethods = apiDescription.default.modelMethods(_axios, k, modelURLs[k])
      methods[k] = modelMethods
    })
    Object.keys(specialURLs).forEach(k => {
      const specialMethods = apiDescription.default.specialMethods(_axios, k, specialURLs[k])
      methods[k] = specialMethods
    })
    console.log(methods) // let's see all the available methods !

    // Eventually we inject the service at Vue prototype level.
    Vue.prototype['$' + serviceName] = methods
  })
}

export default {
  init
}
