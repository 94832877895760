<template>
  <div
    :class="[
      'base-push-element',
      { large: size == 'large' },
      { small: size == 'small' },
      { appear: elementIsMounted },
    ]"
  >
    <div class="item-content">
      <section class="image">
        <router-link :to="mainLink">
          <template v-if="picture">
            <img :src="picture">
          </template>
          <template v-else>
            <div class="nopic" />
          </template>
          <template v-if="itemType == 'project'">
            <div
              v-if="causeIndex"
              class="item-sdg"
            >
              <BaseCauseSquare
                class="cause-overlay-icon"
                :hoverable="false"
                :cause-index="causeIndex"
              />
            </div>
          </template>
        </router-link>
      </section>
      <section
        class="details-container"
        :class="[{ appear: elementIsMounted }]"
      >
        <div class="details">
          <router-link :to="mainLink">
            <!-- ARTIST -->
            <template v-if="itemType == 'artist'">
              <p class="item-name">
                {{ item.name }}
              </p>
              <p class="item-value">
                {{ countryOfBirth + (birthyear ? ', ' + birthyear : '') }}
              </p>
            </template>
            <!-- GALLERY -->
            <template v-if="itemType == 'gallery'">
              <p class="item-name">
                {{ item.name }}
              </p>
              <p class="item-value">
                {{ city }}, {{ country }}
              </p>
            </template>
            <!-- PROJECT -->
            <template v-if="itemType == 'project'">
              <p class="item-name">
                {{ item.name }}
              </p>
              <p class="item-value">
                {{ projectCountries | truncate(30) }}
              </p>
            </template>
            <!-- ARTICLE -->
            <template v-if="itemType == 'article'">
              <p class="item-name">
                {{ item.title }}
              </p>
              <p class="item-value">
                {{ item.header | truncate(30) }}
              </p>
            </template>
          </router-link>
        </div>
        <!-- TRUE follow button -->
        <div
          v-if="slotFollow"
          class="follow-button"
        >
          <slot name="follow-button" />
        </div>
        <!-- MOCKED up follow button -->
        <BaseButton
          v-else
          class="follow-button"
          label="Follow"
        />
      </section>
    </div>
    <!-- THE ANIMATED CURTAIN / BEVEL  -->
    <div
      class="bevel"
      :class="[{ appear: elementIsMounted }]"
    >
      <BaseBevel />
    </div>
  </div>
</template>

<script>
import {animatedBevelMixin} from '@/base/mixins/animatedBevel'
export default {
  name: 'BasePushElement',
  mixins: [animatedBevelMixin],
  props: {
    /**
     * the item Object
     */
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    /**
     * the item type in ['artist', 'gallery', 'project', 'article']
     */
    itemType: {
      type: String,
      required: true,
      default: '',
    },
    /**
     * component size theme
     * in ['large', 'small']
     */
    size: {
      type: String,
      required: false,
      default: 'large',
    },
    disableLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    slotFollow: function () {
      return Object.keys(this.$slots).includes('follow-button')
    },
    picture: function () {
      if (!this.item) return null
      const picMap = {
        article: this.item.media_set && this.item.media_set[0].picture,
        artist: this.item.portrait,
        gallery: this.item.portrait,
        project: this.item.picture,
      }
      if (picMap[this.itemType]) return picMap[this.itemType].web
      console.warn('missing model')
      return null
    },
    birthyear: function () {
      return this.item.birthyear
    },
    city: function () {
      if (!this.item.address) return null
      return this.item.address.city
    },
    country: function () {
      if (!this.item.address && !this.item.address_set) return null
      const adMap = {
        // artist: this.item.address,
        gallery: this.item.address,
        project: this.item.address_set && this.item.address_set[0],
      }
      return adMap[this.itemType].country && adMap[this.itemType].country.name
    },
    projectCountries: function () {
      if (this.item.country_set && this.item.country_set.length) {
        return this.item.country_set.map((p) => p.name).join(', ')
      }
      return null
    },
    countryOfBirth: function () {
      if (!this.item.country_of_birth) return null
      return this.item.country_of_birth.name
    },
    causeIndex: function () {
      let causeId = null
      if (this.item.main_cause) {
        return this.item.main_cause.id
      }
      if (this.item.cause_set && this.item.cause_set.length) {
        causeId = this.item.cause_set[0].id
      }
      return causeId
    },
    /**
     * Main router-link details.
     */
    mainLink: function () {
      if (this.disableLink) return {}
      return {
        name: 'AllView',
        params: {
          itemId: this.item.id,
          modelName: this.itemType,
          slug: (this.item.metadata && this.item.metadata.slug) || '#',
        },
      }
    },
  },
  mounted: function () {},
  methods: {},
}
</script>

<style src="../assets/css/base.css" scoped></style>

<style scoped>
.base-push-element {
  --default-width: 510px;
  --default-height: calc(0.7 * var(--default-width));
  --default-padding: 20px;
  box-sizing: border-box;
  width: var(--default-width);
  --default-parent-width: var(--default-width);
}

.image {
  width: var(--default-width);
  height: var(--default-height);
  overflow: hidden;
  position: relative;
}
.image .nopic,
.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.details-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--default-padding) / 2) 0px;
}
.details {
  margin-right: var(--default-padding);
}
.contact {
  text-align: right;
  width: calc(40%);
}
.item-name,
.item-value {
  font-family: 'Filson Pro Bold';
  font-size: var(--h5);
}
.item-value {
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  letter-spacing: var(--h7-ls);
  text-transform: uppercase;
  margin-top: 2px; /* calc(var(--default-padding) / 2); this is very unique, I think we can use 2px */
}
.base-cause-square.cause-overlay-icon {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: calc(var(--default-parent-width) / 5);
  height: calc(var(--default-parent-width) / 5);
}
</style>

<style src="../assets/css/base-bevelanimations.css" scoped></style>