<template>
  <div
    v-if="state.searchResponse && lastPage > 1"
    class="pagination-container"
  >
    <div class="items-per-page">
      <template v-for="(limit, idx) in limits">
        <BaseButton
          :key="'limit-' + idx"
          :styles="['small-bold']"
          :label="limit.toString()"
          :disabled="limit == state.limit"
          @click="setLimit(limit)"
        />
      </template>
    </div>
    <span class="breakr" />
    <div class="page-controls">
      <BaseButton
        icon="chevron-left"
        :styles="['CTA']"
        @click="gotoPrevious"
      />
      <template v-for="(page, idx) of pages">
        <span
          v-if="page == '...'"
          :key="'split' + idx"
        > ... </span>
        <!-- TODO ADD ACTIVE PAGE STYLING -->
        <BaseButton
          v-else
          :key="'page' + idx"
          :label="page.toString()"
          :styles="['CTA']"
          :disabled="page - 1 == state.offset"
          @click="gotoPage(page - 1)"
        />
      </template>
      <BaseButton
        icon="chevron-right"
        :styles="['CTA']"
        @click="gotoNext"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Pagination',
  props: {
    modelName: {
      type: String,
      required: true,
      default: null,
    },
  },
  data: function () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      state(state) {
        return state[this.modelName]
      },
    }),
    limits: function () {
      return [25, 50, 100, 200].filter(
        (l) => l < this.state.searchResponse.data.count
      )
    },
    lastPage: function () {
      if (this.state.searchResponse.data != undefined) {
        return Math.ceil(
          this.state.searchResponse.data.count / this.state.limit
        )
      }
      return 0
    },
    pages: function () {
      let start = Math.max(1, this.state.offset - 3)
      let maxi = this.lastPage
      let end = Math.min(start + 7, maxi)
      start = Math.max(1, end - 7)

      let array = new Array()
      for (let i = start; i <= end; i++) {
        array.push(i)
      }
      if (array.indexOf(1) == -1) {
        array[1] = '...'
        array[0] = 1
      }
      if (array.length > 4 && array.indexOf(maxi) == -1) {
        array[array.length - 2] = '...'
        array[array.length - 1] = maxi
      }
      return array
    },
  },
  methods: {
    setLimit: function (limit) {
      this.$store.commit(this.modelName + '/setLimit', limit)
      this.$store.commit(this.modelName + '/setOffset', 0)
      this.$store.dispatch(this.modelName + '/search')
    },
    gotoPrevious: function () {
      this.gotoPage(Math.max(this.state.offset - 1, 0))
    },
    gotoNext: function () {
      this.gotoPage(Math.min(this.state.offset + 1, this.lastPage - 1))
    },
    gotoPage: function (page) {
      this.$store.commit(this.modelName + '/setOffset', page)
      this.$store.dispatch(this.modelName + '/search')
    },
  },
}
</script>

<style scoped>
.pagination-container, 
.items-per-page, 
.page-controls {
  display: flex;
  align-items: center;
}
.small-theme .pagination-container{
  flex-direction: column;
}
.breakr {
  margin: 0 15px;
}
.CTA.base-button-container.disabled {
  background-color: var(--primary-color);
}
.CTA.base-button-container.disabled >>> .label {
  color: var(--white);
}
</style>
