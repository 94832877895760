<template>
  <div class="base-carousel">
    <!-- MAIN CONTAINER -->
    <div class="carousel-full-width-container">
      <!-- CENTRAL AREA -->
      <div
        v-if="pictureItems.length > 0"
        ref="centralArea"
        class="central-area"
      >
        <div
          ref="slideshowContent"
          class="slideshow-content"
          :style="{ marginLeft: currentLeftOffset }"
        >
          <div
            v-for="(slide, slideIdx) in slides"
            :key="`slidec-${slideIdx}`"
            class="slide-container"
          >
            <figure
              :key="`slide-${slideIdx}`"
              :class="[
                'slide', 
                { 'slide-active': slideIdx == currentIndex }
              ]"
            >
              <div class="img-container">
                <BaseLoader v-if="!slide.loaded" />
                <img
                  :id="`slide-img-${slide.index}`"
                  class="slide-img"
                  :src="slide.src"
                  @load="onImgLoad"
                >
                <!-- TODO: add alt -->
                <!-- alt="TODO" -->
              </div>
              <figcaption class="figure-copyright">
                <template v-if="slide.displayCaption">
                  <!-- eslint-disable vue/no-v-html -->
                  <div
                    class="figure-caption"
                    v-html="slide.displayCaption"
                  />
                  <!-- eslint-enable -->
                </template>
              </figcaption>
            </figure>
          </div>
        </div>

        <div
          v-if="pictureItems.length > 1"
          class="controls"
        >
          <div class="page-current">
            {{ currentSlide }} / {{ pictureItems.length }}
          </div>
          <div class="arrows">
            <div
              class="arrow"
              @click="showPreviousPicture"
            >
              <img src="../../assets/img/svgicons/arrow-left.svg">
            </div>
            <div
              class="arrow"
              @click="showNextPicture"
            >
              <img src="../../assets/img/svgicons/arrow-right.svg">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCarousel',
  props: {
    /**
     * An array of 'pictures' Objects like
     * `{src: <url>, caption: <string>, href: <url>}`
     */
    pictureItems: {
      type: Array,
      required: true,
      default: () => {
        return null
      },
    },
    /**
     * If set to true, slide show will cycle
     * as defined by timer autoSlideInterval
     */
    autoSlide: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Time before showing next slide in milliseconds
     * `if ( autoSlide == true )`
     */
    autoSlideInterval: {
      type: Number,
      required: false,
      default: 300,
    },
    /**
     * Time before first automatic slide change.
     * Set to 0 to disable this delay.
     */
    autoSlideDelay: {
      type: Number,
      required: false,
      default: 1000,
    },
  },
  data: function () {
    return {
      currentIndex: 0,
      autoSlideTimer: {},
      slides: [],
    }
  },
  computed: {
    isSmall: function () {
      if (!this.$store) return false /* for storybook */
      return this.$store.state.ui.isSmall
    },
    currentSlide: function () {
      return this.currentIndex + 1
    },
    nextIndex: function () {
      return (this.currentIndex + 1) % this.pictureItems.length
    },
    previousIndex: function () {
      return (
        (((this.currentIndex - 1) % this.pictureItems.length) +
          this.pictureItems.length) %
        this.pictureItems.length
      )
    },
    currentLeftOffset: function () {
      const widthCSSVar = getComputedStyle(document.documentElement)
        .getPropertyValue('--slide-width')
      const slideWidth = widthCSSVar || '1105px'
      if (this.isSmall) {
        return `0px`
      } else {
        return `calc(-1 * ${slideWidth} * ${this.currentIndex})`
      }
    },
  },
  watch: {
    pictureItems: function () {
      this.initSlides()
    },
  },
  mounted: function () {
    // if (this.autoSlide){
    //   setTimeout(this.initAutoSlide, this.autoSlideDelay)
    // }
    this.initSlides()
  },
  beforeDestroy: function () {
    clearTimeout(this.autoSlideTimer)
  },
  methods: {
    onImgLoad: function (event) {
      // console.debug('BASECAROUSEL: img loaded', event.target)
      const imgW = event.target.naturalWidth
      const imgH = event.target.naturalHeight
      const imgOrientation = imgW > imgH ? 'landscape' : 'portrait'
      event.target.classList.add(`format-${imgOrientation}`)
      const idx = event.target.id.split('slide-img-')[1]
      const slide = this.slides.find((s) => s.index == idx)
      slide.loaded = true
    },
    initSlides: function () {
      let rawPicItems = [...this.pictureItems]
      for (let i = 0; i < rawPicItems.length; i++) {
        rawPicItems[i] = {
          ...rawPicItems[i],
          index: i,
          loaded: false,
          displayCaption: this.makeSlideCaption(rawPicItems[i]),
        }
      }
      this.slides = [...rawPicItems]
    },
    makeSlideCaption: function (slide) {
      let parts = []
      if (slide.copyright) {
        parts.push(slide.copyright)
      }
      if (slide.caption) {
        parts.push(slide.caption)
      }
      return parts.join("&nbsp;&ndash;&nbsp;")
    },
    showThisPicture: function (index) {
      this.currentIndex = index
    },
    showNextPicture: function () {
      this.currentIndex = this.nextIndex
      if (this.isSmall) {
        this.scrollBy(1)
      }
    },
    showPreviousPicture: function () {
      this.currentIndex = this.previousIndex
      if (this.isSmall) {
        this.scrollBy(-1)
      }
    },
    initAutoSlide: function () {
      this.autoSlideTimer = setInterval(() => {
        this.showNextPicture()
      }, this.autoSlideInterval)
    },
    scrollBy: function (idx) {
      this.$refs.slideshowContent.scrollBy({
        left: idx * this.$refs.centralArea.offsetWidth,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style scoped>
.carousel-full-width-container {
  --slide-width: 1105px;
  --slide-height: 620px;
  --controls-width: 90px;
  /* same as arrow buttons */
  --caption-height: 43px;
}
.central-area {
  position: relative;
  box-sizing: border-box;
  height: calc(var(--slide-height) + var(--caption-height));
  width: var(--slide-width);
  margin: auto;
  overflow: hidden;
}
.slideshow-content {
  display: flex;
  transition: 0.25s ease-in-out;
  width: 100%;
}
@media (prefers-reduced-motion: reduce) {
  .slideshow-content {
    transition: none;
  }
}
.slide-container {
}
.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--slide-width);
  height: calc(var(--slide-height) + var(--caption-height));
}
.img-container {
  box-sizing: border-box;
  width: var(--slide-width);
  height: var(--slide-height);
  padding: var(--padding-unit);
}
.slide img {
  width: 100%;
  height: 100%;
  /* height: var(--slide-height); */
  object-fit: contain;
}
.slide figcaption {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  /* align-items: flex-end; */
  align-items: center;
  font-family: 'Rubik Regular';
  height: var(--caption-height);
}
.slide-container.slide-active {
}
.slide-container:last-child {
}
.controls {
  position: absolute;
  border: var(--default-ruler);
  bottom: 0%;
  /* left: 0%; */
  width: 86px;
  background-color: var(--white);
  user-select: none;
}
.page-current {
  height: 86px;
  line-height: 86px;
  vertical-align: middle;
  text-align: center;
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  letter-spacing: var(--h7-ls);
}
.arrows {
  border-top: var(--default-ruler);
  display: flex;
}
.arrow {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.arrow:first-child {
  border-right: var(--default-ruler);
}
.arrow:hover {
  background-color: var(--water-blue);
}
.arrow:hover img {
  filter: invert(1);
}
.figure-caption {
  display: flex;
}
/* .figure-caption >>> a {
  color: var(--primary-color);
  text-decoration: underline;
  text-underline-offset: 2px;
} */

/*
.-.              . .     .-.    .    .     .        .     
(   )             | |    (   )   |\  /|     |     o  |     
 `-. .--.--. .-.  | |    .--'    | \/ | .-. |.-.  .  | .-. 
(   )|  |  |(   ) | |   (   `.)  |    |(   )|   ) |  |(.-' 
 `-' '  '  `-`-'`-`-`-   `---'`  '    ' `-' '`-'-' `-`-`--'
 */
/* carousel settings */
.small-theme .carousel-full-width-container {
  /* --full-width: calc(100vw - 10px); */
  --full-width: 100vw;
  --slide-width: var(--full-width);
  --slide-height: calc(var(--slide-width) * 0.75);
}
.small-theme .central-area {
  margin: 0px auto;
  height: auto;
}
.small-theme .slide {
  margin: 0px;
  height: initial;
}
.small-theme .slide-img {
  padding: var(--default-h-padding) 0px;
}
.small-theme .slideshow-content {
  width: 100%;
}
.small-theme .slideshow-content {
  padding-bottom: 10px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}
/* scrollbars on small */
.small-theme .slideshow-content::-webkit-scrollbar {
  height: 8px;
}
.small-theme .slideshow-content::-webkit-scrollbar-track {
  background-color: var(--grey-medium);
}
.small-theme .slideshow-content::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}
.small-theme .slideshow-content {
  scrollbar-width: thin; /* pixel values still bug */
  scrollbar-color: var(--primary-color) var(--grey-medium);
}
.small-theme .slide-container figcaption {
  display: none;
}
/* controls */
.small-theme .controls {
  top: calc(50% - 20px);
  left: 0px;
  height: calc(var(--controls-width) / 2);
  width: 100%;
  border: none;
  background-color: transparent;
}
.small-theme .controls .page-current {
  display: none;
  border: none;
}
.small-theme .controls .arrows {
  border-top: none;
  position: relative;
}
.small-theme .arrow {
  position: absolute;
  border: var(--default-ruler);
  background-color: var(--white);
}
.small-theme .arrow:hover {
  background-color: var(--primary-color);
}
.small-theme .arrow:last-child {
  right: 0px;
}
/* ---- SLIDE container  ---- */
.small-theme .slide-container {
  scroll-snap-align: center;
}
</style>
