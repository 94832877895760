<template>
  <article
    :class="[
      'base-editorial-element',
      { large: size == 'large' },
      { small: size == 'small' },
    ]"
    :style="{ 'background-image': 'url(' + picture + ')' }"
  >
    <!-- TODO: ajouter un router-link plutôt que de gérer le click par une méthode -->
    <!-- EDITORIAL MAIN CONTAINER -->
    <figure class="editorial-container">
      <!-- Editorial publication date -->
      <time
        v-if="date"
        :datetime="date"
        class="editorial-date"
      >
        {{ date | formatDate(date) }}
      </time>

      <!-- EDITORIAL DETAILS -->
      <figcaption class="editorial-details">
        <div class="editorial-figwrapper">
          <!-- Editorial title -->
          <template v-if="title">
            <h3>{{ title }}</h3>
          </template>

          <!-- Editorial header -->
          <div class="editorial-header">
            {{ item.content | stripHTML | truncate }}
          </div>

          <!-- Editorial call to action -->
          <BaseCTAtxt
            label="Read more"
            @click="onEditorialClick"
          />
        </div>
      </figcaption>
    </figure>
  </article>
</template>

<script>
export default {
  name: 'BasePushEditorial',
  props: {
    /**
     * the item Object
     */
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    /**
     * the item type in ['artist', 'gallery', 'project']
     */
    itemType: {
      type: String,
      required: true,
      default: '',
    },
    /**
     * component size theme
     * in ['large', 'small']
     */
    size: {
      type: String,
      required: false,
      default: 'large',
    },
  },
  data: function () {
    return {}
  },
  computed: {
    // TODO: change default value for 'date', must be 'null'
    date: function () {
      if (!this.item.date) return `13/03/2021`
      return this.item.date
    },

    // TODO: change default value for 'title', must be 'null'
    title: function () {
      if (!this.item.title) return this.item.name
      return this.item.title
    },

    // TODO: change default value for 'header', must be 'null'
    header: function () {
      if (!this.item.header)
        return `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur, vitae. Incidunt modi explicabo, consequuntur non est dignissimos dolorum sapiente, repellat culpa porro nihil aperiam, aspernatur ab quis. Iusto, quidem laborum.`
      return this.item.header
    },

    // TODO: define item type for 'editorial, must be 'picMap[this.itemType].web'
    picture: function () {
      if (!this.item.media_set[0]) return null
      return this.item.media_set[0].picture.web
    },
  },
  // mounted: function () {
    // console.log('BasePushEditorial', this.item)
  // },
  methods: {
    onEditorialClick: function () {
      this.$emit('click')
      this.$router.push({name: 'AllView', params:{
        modelName: 'article',
        itemId: this.item.id,
        slug: (this.item.metadata && this.item.metadata.slug) || '#' }})
    },
  },
}
</script>

<style src="../assets/css/base.css"></style>
<style scoped>
/* 
Reset
*/
img {
  max-width: 100%;
}

/* 
EDITORIAL MAIN CONTAINER
*/

.base-editorial-element {
  font-family: 'Rubik Regular';
  font-size: var(--text-size-p);
  width: var(--container-width);
  position: relative;
  --text-size-p: 20px;
  --container-width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.editorial-header {
  font-size: small;
}

.small-theme .base-editorial-element {
  --text-size-h2: 30px;
  --container-width: calc(100% / 2 - 30px);
  margin-bottom: 130px;
}

.mobile-theme .base-editorial-element {
  --text-size-h2: 30px;
  --container-width: 100%;
}

.desktop-theme .base-editorial-element {
  --text-size-h2: 50px;
  /* --container-width: calc( 100% / 3 - 15px ); */
  --container-width: 560px;
  flex: 0 0 35%;
  height: 100%;
}

/* .desktop-theme .base-editorial-element:not(:first-child) {
  margin-top: 70px;
  margin-left: 70px;
} */

.editorial-date {
  font-family: 'Rubik Medium';
  font-size: 15px;
  line-height: 1;
  padding: 10px 15px;
  background-color: white;
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 15px;
}

.editorial-details {

}

.desktop-theme .editorial-date {
  top: 20px;
  left: 20px;
}

.small-theme .base-editorial-element:nth-child(2n + 2) {
  /* margin-right: 0; */
  margin-left: var(--default-h-padding);
}

.desktop-theme .base-editorial-element:nth-child(2n + 2) {
  margin-right: 0;
  margin-left: 140px;
}
/* .desktop-theme .base-editorial-element:nth-child(3n + 3){
  margin-right: 0;
  margin-left: var(--default-h-padding);
} */

/* 
EDITORIAL DETAILS
*/
.editorial-details {
  width: calc(100% - 70px);
  padding: 20px;
  margin: 50% auto 60px auto;
  background-color: white;
  position: relative;
  z-index: 1;
  /* min-height: 350px; */
  min-width: 250px;
}

.desktop-theme .editorial-details {
  width: calc(100% - 140px);
  margin: 50% 0 0 140px;
}

.small-theme .editorial-details, .mobile-theme .editorial-details {
  margin: 50% auto -100px auto;
  min-height: initial;
}

.editorial-picture {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
}

.desktop-theme .editorial-picture {
  width: calc(100% - 70px);
}

h3 {
  font-family: 'Filson Pro Regular';
  font-weight: normal;
  font-size: var(--text-size-h3);
  line-height: 1;
  margin-bottom: 15px;
}

.base-cta-txt {
  margin-top: 10px;
}
</style>
