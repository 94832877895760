import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  modelName: 'address',
  modelDisplayNames: ['address', 'addresses'],
}

const mutations = {
  ...baseStore.mutations,
}

const actions = {
  ...baseStore.actions,
}

const getters = {
  ...baseStore.getters
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
