<!--
888~~\                             888~~\             d8     d8                     
888   |   /~~~8e   d88~\  e88~~8e  888   | 888  888 _d88__ _d88__  e88~-_  888-~88e 
888 _/        88b C888   d888  88b 888 _/  888  888  888    888   d888   i 888  888 
888  \   e88~-888  Y88b  8888__888 888  \  888  888  888    888   8888   | 888  888 
888   | C888  888   888D Y888    , 888   | 888  888  888    888   Y888   ' 888  888 
888__/   "88_-888 \_88P   "88___/  888__/  "88_-888  "88_/  "88_/  "88_-~  888  888 

-->
<template>
  <div
    :class="[
      ...styles,
      'base-button-container',
      { disabled: disabled },
      { active: active },
      { 'no-label': label == null },
    ]"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <!-- BUTTON CONTENT CONTAINER -->
    <div class="label">
      <!-- LABEL IS A SHORT TEXT -->
      <span
        v-if="label"
        class="label-text"
      >
        {{ label }}
      </span>
      <!-- SOME BUTTON STYLES ALREADY INCLUDES ICONS -->
      <!-- BUTTON ICON -->
      <img
        v-if="icon"
        class="icon"
        :src="iconFileURL"
      >
      <!-- SPECIAL ICON is a slot for inserting inline SVG icons -->
      <slot name="special-icon" />
      <!-- DYNAMIC ARROW FOR CTA-nav -->
      <BaseArrow
        v-if="isCTANav"
        ref="baseArrow"
        class="arrow"
        size="default"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    /**
     * Label is a short string that should be displayed
     * on button face.
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * General styling
     * Available styles are listed in common/storybook/basebutton
     */
    styles: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    /**
     * Disabled button won't allow any user interaction.
     * No event will be emitted.
     * (from CSS, look at property `pointer-events: none;`)
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * You can specify an icon name that will appear right to the label.
     * Icon names are names of files in /base/assets/svgicons
     * without the .svg extension.
     */
    icon: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Active (???)
     */
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      imgIdx: this.active ? 1 : 0,
      arrowId: (Math.random()*1e9).toFixed(0).toString(16)
    }
  },
  computed: {
    iconFileURL: function () {
      return require(`../assets/img/svgicons/${this.icon}.svg`)
    },
    isCTANav: function () {
      return this.styles.includes('CTA-nav')
    }
  },
  methods: {
    onClick: function () {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
    onMouseEnter: function () {
      if (this.isCTANav){
        this.$refs.baseArrow.onMouseEnter()
      }
    },
    onMouseLeave: function () {
      if (this.isCTANav){
        this.$refs.baseArrow.onMouseLeave()
      }
    }
  },
}
</script>

<style src="../assets/css/base.css" scoped></style>

<style scoped>
/* ---------- CONSTANTS ---------- */
.base-button-container {
  --default-button-height: 2.5rem;
}
/* ---------- BUTTON CONTAINER ---------- */
.base-button-container {
  display: inline-block;
  box-sizing: border-box;
  height: var(--default-button-height);
  padding: var(--field-default-padding);
  border: 1px solid var(--ruler-color);
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  /* transition: background-color 0.1s ease-in-out; */
  transition: var(--default-transition);
  cursor: pointer;
}
.base-button-container.active {
  background-color: var(--primary-color);
  color: #fff;
}
.base-button-container:hover,
.base-button-container.active:hover {
  background-color: #fff;
  color: var(--primary-color);
  text-decoration: underline;
  border: 1px solid var(--primary-color);
}
/* ---------- LABEL ---------- */
.label {
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
/* ---------- ICONS ---------- */
.icon,
.label svg {
  height: 18px;
  margin-left: 10px;
}

/*
  having no label and keeping margin in .icon
  keeps icon from being centered          
*/
.no-label .icon {
  margin-left: 0px;
}

/* ------- DISABLED STATE -------  */
/*  
  disabled button shouldn't emit any event 
  nor display any visual change           
*/
.base-button-container.disabled {
  background-color: var(--grey-medium);
  color: var(--grey-dark);
  pointer-events: none;
  border: var(--grey-medium);
}

/* ------ STYLES ARE KIND OF THEMES ------- */
/*
_  _ ____          ___  ____ ____ ___  ____ ____ 
|\ | |  |    __    |__] |  | |__/ |  \ |___ |__/ 
| \| |__|          |__] |__| |  \ |__/ |___ |  \                                     
*/
.no-border {
  border: none;
}
/*
____ _  _ ____ _    _       ___  ____ _    ___  
[__  |\/| |__| |    |    __ |__] |  | |    |  \ 
___] |  | |  | |___ |___    |__] |__| |___ |__/ 
*/
.small-bold .label {
  font-family: 'Rubik Medium';
  font-size: var(--ultrasmall);
  text-transform: uppercase;
  color: var(--primary-color);
}
.small-bold.base-button-container {
  height: 1.5rem;
  padding: 9px 0.9rem;
  border: 1px solid var(--primary-color);
}
/*
____ ___ ____ 
|     |  |__| 
|___  |  |  | 
*/
.CTA.base-button-container,
.CTA-nav.base-button-container {
  height: var(--default-button-height);
}
.CTA.base-button-container:hover,
.CTA-nav.base-button-container:hover {
  background-color: #fff;
}
.CTA .label,
.CTA-nav .label {
  font-family: 'Filson Pro Regular';
  font-size: var(--paragraph);
  color: var(--black);
}
.CTA.active .label,
.CTA-nav.active .label {
  color: #fff;
}
.CTA:hover .label,
.CTA-nav:hover .label {
  color: var(--primary-color);
}
.CTA.disabled,
.CTA-nav.disabled {
  color: #fff;
  background-color: #7b7b7b;
}
/*
____ ___ ____    _  _ ____ _  _ 
|     |  |__| __ |\ | |__| |  | 
|___  |  |  |    | \| |  |  \/  
*/
.CTA-nav.base-button-container {
  /* width: 184px; */
}
.CTA-nav .label {
  justify-content: space-between;
}
/* SVG DYNAMIC ARROW for CTA-nav*/
.base-button-container:hover .base-arrow{
  --stroke-color: var(--primary-color);
}
/*
 +-+-+-+-+ +-+-+-+-+-+
 |l|i|s|t| |i|c|o|n|s|
 +-+-+-+-+ +-+-+-+-+-+
*/
.list-icon {
  border: none;
  padding: 0px 0.25rem;
  background-color: #ffffff00;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
.list-icon .icon {
  height: 1rem;
  opacity: 0.5;
}
.list-icon.base-button-container:hover {
  background-color: #7b7b7b;
}
.list-icon.base-button-container:hover .icon {
  opacity: 1;
  filter: invert(1);
}
</style>