<template>
  <div :class="['base-textareainput-container', ...styles]">
    <textarea
      ref="inputElement"
      :placeholder="placeholder"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      :maxlength="maxLength"
      v-on="inputListeners"
      @input="checkValidity"
    />
    <!-- <div class="text-length">
      {{ (value)?value.length:'' }}
    </div> -->
  </div>
</template>

<script>
import {baseinputs} from '../../mixins/baseinputs'

export default {
  name: 'BaseTextAreaInput',
  mixins: [baseinputs],
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Type something ...'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: function () {
    return {};
  },
  computed: {

  },
  mounted: function(){
    if (this.required){
      this.$refs.inputElement.required = true
    }
  },
  methods: {
    checkValidity: function(){
      const inputEl = this.$refs.inputElement
      const msg = inputEl.validationMessage
      if (!inputEl.validity.valid){
        this.$emit('invalid', msg)
      } else {
        this.$emit('valid')
      }
    }
  },
};
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-textareainput-container{
  display: flex;
  /* flex-direction: column; */
  justify-content: stretch;
  /* align-items: stretch; */
  box-sizing: border-box;
  min-height: 120px;
  padding: var(--field-default-padding);
  width: 100%;
}
textarea{
  flex-grow: 1;
  width: 100%;
  height: auto;
  border: none;
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
}
textarea:focus-visible{
  outline: none;
}

textarea::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
textarea::-webkit-scrollbar-track {
  background-color: var(--white);
}
textarea::-webkit-scrollbar-thumb {
  background-color: var(--grey-medium);
}
textarea{
  scrollbar-width: thin;
  scrollbar-color: var(--black) var(--grey-medium);
}
::-webkit-resizer{
  display: none;
}
</style>
