<template>
  <div
    v-if="Object.keys(address).length"
    class="address-block"
  >
    <div
      class="address-details"
    >
      <div class="address-header">
        {{ address.name }}
      </div>
      <div class="address-content">
        <p v-if="address.phone != null">
          <img
            class="icon"
            src="../assets/img/svgicons/phone.svg"
          >
          {{ address.phone }}
        </p>
        <p
          v-else
          class="missing-required-info"
        >
          No contact phone provided
        </p>
        <p>{{ address.address1 }}</p>
        <p>{{ address.address2 }}</p>
        <p>{{ address.city }}, {{ address.postal }}</p>
        <p>{{ address.country.name }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  name: 'BaseAddressDisplay',
  components: {},
  props: {
    address: {
      type: Object,
      required: true,
      default: () => {return {}}
    },
  },
  data: function () {
    return {}
  },
  methods: {

  }
}
</script>

<style scoped>
.address-block{
  display: flex;
  justify-content: space-between;
  border: var(--default-ruler);
  margin: 20px 0px;
  padding: 20px;
}
.address-header{
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  line-height: var(--h7-lh);
  letter-spacing: var(--h7-ls);
  text-transform: uppercase;
  margin-bottom: 15px;
}
.address-username{
  font-family: 'Filson Pro Bold';
}
.address-controls{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.address-controls .base-button-container{
  margin-top: 10px;
}
.address-content{
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  letter-spacing: var(--paragraph-ls);
  line-height: var(--paragraph-lh);
}
.address-edit{
  margin-right: 20px;
  flex-grow: 1;
}
.icon{
  height: calc(0.8 * var(--paragraph));
  margin-bottom: -0.05rem;
}
.missing-required-info{
  font-family: 'Rubik Italic';
  color: var(--disabled-color);
}
.mobile-theme .address-controls .base-button-container{
  width: 135px;
  height: auto;
  text-align: center;
}
</style>