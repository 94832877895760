<!--
  BaseUploadImage is an input like the others.
  V-MODEL-compatible, it handles 'input', 'focus' and 'blur' events.
  Only needed prop is the v-model received here as `value`
-->
<template>
  <div
    class="base-upload-image"
  >
    <!-- FILE INPUT here, will open OS file selector on click -->
    <input
      id="image-input"
      ref="imageInput"
      type="file"
      @change="onFileChange"
    >
    <!-- ---- IMAGE PREVIEW ---- -->
    <div 
      v-if="value"
      class="image-preview"
    >
      <img :src="value.screen || value.file || value.source || value">
    </div>
    <!-- ---- TOOL BAR ---- -->
    <div class="tool-bar">
      <!-- ---- button to add file ---- -->
      <div
        v-if="!value"
        class="btn plus"
        @click="addPicture"
      >
        <img src="../../assets/img/svgicons/plus.svg">
      </div>
      <!-- ---- button to change file ---- -->
      <div
        v-if="value"
        class="btn reload"
        @click="addPicture"
      >
        <img src="../../assets/img/svgicons/rotate-ccw.svg">
      </div>
      <!-- ---- button to delete file ---- -->
      <div
        v-if="value"
        class="btn delete"
        @click="deletePicture"
      >
        <img src="../../assets/img/svgicons/trash-2.svg">
      </div>
    </div>
  </div>
</template>

<script>
import {baseinputs} from '../../mixins/baseinputs'

export default{
  name: 'BaseUploadImage',
  mixins: [baseinputs],
  props: {
    value: {
      type: Object,
      required: false,
      default: () => { return null }
    },
  },
  methods: {
    addPicture: function() {
      this.$emit('focus')
      this.$refs.imageInput.click()
    },
    deletePicture: function() {
      this.$emit('input', null)
    },
    onFileChange: function(event) {  
      let files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        console.debug('no files')
        this.$emit('blur')
      } else {
        let fr = new FileReader();
        fr.onloadend = (e)=> {
          // TODO : examinate media input for format, weight constraints
          // and send validation messages
          this.$emit('input',
            {"file": e.target.result, "name": files[0].name}
          )
          this.$emit('blur')
          // notes ab
          //
          // TODO 2: Avoid base64 encoding (readAsBinary instead of readAsDataURL)
          // requires API + front modifications but will be necessary for big images
          // to save ~30% payload
        }
        fr.readAsDataURL(files[0])
      }
    },
  }
}

</script>

<style src="../../assets/css/base.css"></style>

<style scoped>
.base-upload-image {
  position: relative;
  box-sizing: border-box;
  border: 1px dashed #707070;
  min-width: 466px;
  min-height: 350px;
  width: 100%;
  /* max-width: 618px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
input{
  display: none;
}
.image-preview{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.image-preview img{
  max-width: 100%;
  max-height: 100%;
}
.tool-bar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.btn{
  width: 44px;
  height: 44px;
  display:flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  filter: invert(1);
  opacity: 0;
  cursor: pointer;
}
.base-upload-image:hover .btn{
  opacity: 1.0;
}
.btn:hover{
  background-color: #7b7b7b80;
}
.plus{
  opacity: 1.0;
  filter: none;
  border: 1px dashed #707070;
}
</style>