<template>
  <div 
    :class="[
      'base-textinput-container', 
      { disabled: disabled },
      ...styles
    ]"
  >
    <input
      ref="inputElement"
      type="url"
      :placeholder="placeholder"
      :maxlength="maxLength"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      v-on="textInputListeners"
    >
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'

export default {
  name: 'BaseURLInput',
  mixins: [baseinputs],
  props: {
    /**
     * Text input value for an URL.
     * Will be validated in the computed `input` inputListeners.
     */
    value: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * If no URL provided, `<input>` element will
     * show default placeholder.
     */
    placeholder: {
      type: String,
      required: false,
      default: 'Type a valid URL...',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  mounted: function () {
    // Check validity at init
    // if (this.value || this.required){
    //   this.checkValidity(this.value)
    // }
    // I d'ont remember why... seems redundant with the textInputListeners
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
