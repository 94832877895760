
const metadataDefault = {
  // metadata: {
  //   published: false
  // }
}

const artist = {
  ...metadataDefault,
  name: undefined,
  gender: undefined,
  birthyear: undefined,
  city_of_birth: undefined,
  country_of_birth: undefined,
  city_of_residence: undefined,
  country_of_residence: undefined,
  bio: undefined,
  website: undefined,
  visibility: {
    world: 0,
    national: 0,
    media: 0,
    instagram: 0,
    nb_instagram_followers: 0,
    tap: 0,
  },
  cause_set: [],
  project_set: [],
  event_set: [],
  institution_set: [],
  curriculumentry_set: [],
}


const artwork = {
  ...metadataDefault,
  address: {},
  artist: null,
  availability: null,
  caption: '',
  cause_set: [{id:1}],
  certificate: null,
  delivery: [],
  edition: null,
  format: null,
  /* gallery is always myGallery, 
  this value is defined in DetailsView>initCreationForm 
  */
  gallery: undefined,
  genre: null,
  inventory: null,
  media_set: [],
  medium: null,
  movement: null,
  price: 0,
  price_max: null,
  price_min: null,
  signature: '',
  signed: false,
  special_conditions: null,
  tag_set: [],
  title: '',
  year: '',
  physical_state: {
    width_cm: 0,
    height_cm: 0,
    length_cm: 0,
    width_in: '0',
    height_in: '0',
    length_in: '0',
    weight_kg: 0,
    weight_lbs: 0,
    shipping_width_cm: 0,
    shipping_height_cm: 0,
    shipping_length_cm: 0,
    shipping_width_in: '0',
    shipping_height_in: '0',
    shipping_length_in: '0',
    shipping_weight_kg: 0,
    shipping_weight_lbs: 0,
    fragility: 'L',
  },
}

const gallery = {
  ...metadataDefault,
  name: null,
  visibility: {
    world: 0,
    national: 0,
    media: 0,
    instagram: 0,
    nb_instagram_followers: 0,
    tap: 0,
  },
  media_set: [],
  admin: 1,
  address: {},
}

const cause = {
  ...metadataDefault,
  name: null,
  description: null,
  website: null,
}

const project = {
  ...metadataDefault,
  name: null,
  description: null,
  website: null,
  active: false,
  cause_set: [],
  country_set: [],
  address_set: [
    {}
  ],
}

const event = {
  ...metadataDefault,
  address: {},
  media_set: [],
  gallery_set: [],
  institution_set: []
}

const institution = {
  ...metadataDefault,
  address: {},
  media_set: [],
  event_set: []
}

const tag = {
  ...metadataDefault,
  name: null,
  description: null,
  website: null,
  category: null,
}

const organisation = {
  ...metadataDefault,
  name: null,
  address: {},
  country_set: [],
  project_set: [],
}

export default{
  artist,
  artwork,
  gallery,
  cause,
  project,
  event,
  institution,
  tag,
  organisation,
}
