<template>
  <div
    :class="[
      'base-input-container',
      {
        pristine: pristine,
        dirty: dirty,
        modified: !pristine,
        'has-focus': hasFocus,
        'has-error': validable && hasError,
        'is-valid': validable && isValid,
        'is-invalid': validable && isInvalid,
        'no-padding': !hasPadding
      },
      ...styles
    ]"
    @mouseenter="onMouseEnterField"
    @mouseleave="onMouseLeaveField"
  >
    <!-- FIELD LABEL -->
    <div
      v-if="label"
      class="field-label"
    >
      {{ label }}
      <template v-if="required">
        <span class="required">*</span>
      </template>
    </div>
    <div class="field-container">
      <!-- FIELD INPUT COMPONENT -->
      <div 
        :class="[
          'input-box',
          {'no-border': !hasBorder }
        ]"
        @click="setFocus"
      >
        <!-- VALIDATION HINT BAR -->
        <!-- <div class="validation-hint" /> -->
        <!-- HTML INPUT COMPONENT ELEMENT -->
        <div class="input-container">
          <template v-if="fieldComponent">
            <InputElement
              :is="fieldComponent"
              ref="inputElement"
              :disabled="disabled"
              :required="required"
              :styles="styles"
              :display-id="displayId"
              v-bind="$attrs"
              v-on="$listeners"
              @input="onInput"
              @keypress.enter="updateField"
              @autoclose="updateField"
              @focus="onFieldFocus"
              @blur="onFieldBlur"
              @invalid="onInvalid"
              @valid="onValid"
              @reset-validity="onResetValidity"
            />
          </template>
          <template v-else>
            No mapping
          </template>
        </div>
      </div>
      <!-- FIELD VALIDATION STATUS -->
      <div
        class="input-validation"
        :class="{'show-validation':validable && (isValid || hasError)}"
      >
        <div class="validation-icon icon-ok">
          <img 
            v-if="isValid"
            src="../../assets/img/svgicons/check-circle-green.svg"
          >
        </div>
        <div class="validation-icon">
          <img 
            v-if="hasError"
            src="../../assets/img/svgicons/x-circle-red.svg"
          >
        </div>
      </div>
    </div>
    <!-- FIELD HELP TEXT -->
    <div 
      v-if="helpText && !isInvalid && !disabled"
      :class="['help-text', {'show-help-text':showHelpText}]"
    >
      <!-- eslint-disable vue/no-v-html -->
      <p v-html="helpText" />
      <!--eslint-enable-->
    </div>
    <!-- FIELD ERRORS -->
    <div class="field-errors">
      <!-- <template v-if="Array.isArray(fieldErrors)"> -->
      <template v-for="(error, errorIdx) in fieldErrors">
        <div 
          :key="['ferr', label, errorIdx].join('-')"
          class="error"
        >
          {{ error }}
        </div>
      </template>
      <!-- </template> -->
      <!-- <template v-else>
        <p>{{ fieldErrors }}</p>
      </template> -->
      <div 
        v-if="validationError" 
        class="error"
      >
        {{ validationError }}
      </div>
    </div>
    <!-- PASSWORD STRENGTH BARS -->
    <template v-if="typeof strength == 'number'">
      <BaseStrengthBar
        :strength="strength" 
        @invalid="onInvalid"
        @valid="onValid"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    styles: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    /**
     * Label can be displayed to show field title.
     */
    label: {
      type: String,
      required: false,
      default: null
    },
    /**
     * fieldType permits field mapping by simple 'type' name.
     * Please look at fieldComponent to learn more.
     */
    fieldType: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Field can be visually and functionnaly disabled.
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Required is transmitted as prop to children.
     * Is used for local validation purposes.
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * All inputs are validable by default.
     * Some usages needs fields to not display validation status.
     */
    validable: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Help text is a short string meant to be displayed
     * in a 'tooltip'.
     */
    helpText: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Placeholder element transmitted to children.
     */
    // placeholder: {
    //   type: [String, Number, Object, Date],
    //   required: false,
    //   default: ()=>{ return null }
    // },
    errors: {
      type: [Object, Array, String],
      required: false,
      default: () => {
        return null
      }
    },
    /** 
     * used in password strength / strength bars
     */
    strength: {
      type: [Number, String],
      required: false,
      default: null
    },
    /** 
     * used in drop-down lists (BaseSelect and BaseMultiSelect)
     * See these two files for extra documentation
     * Note: could be useful for other inputs?
     */
    displayId: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function() {
    return {
      dirty: false,
      pristine: true,  // need init #TODO
      // showHelpText: false,
      mouseOverField: false,
      hasFocus: false,
      isValid: false,
      isInvalid: false,
      validationError: null,
    };
  },
  computed: {
    /**
     * BaseInputContainer is a wrapper for actual input components.
     * V-model passed to baseinputcontainer ensures bidirectionnal
     * communication between input container (here) and input component (child)
     */
    fieldComponent: function() {
      const fieldMap = {
        // default types
        string: 'BaseTextInput',
        longstring: 'BaseTextAreaInput',
        integer: 'BaseNumberInput',
        select: 'BaseSelect',
        multiselect: 'BaseMultiSelect',
        checkbox: 'BaseCheckbox',
        toggle: 'BaseToggle',
        password: 'BasePasswordInput',
        email: 'BaseEmailInput',
        float: 'BaseFloatInput',
        rte: 'BaseRichTextEditorInput',
        url: 'BaseURLInput',
        image: 'BaseUploadImage',
        date: 'BaseDateInput',
        radio: 'BaseRadio',
        regularfile: 'BaseFileUpload',
        // custom templates
        /* .... */
      };
      if (Object.keys(fieldMap).includes(this.fieldType)) {
        return fieldMap[this.fieldType];
      } else {
        return null;
      }
    },
    /**
     * Selectors handle their own focus event, sizing etc...
     * This returns true if this input component has such needs.
     */
    isSelector: function (){
      const selectorFieldTypes = ['select', 'multiselect', 'image', 'regularfile', 'checkbox', 'toggle', 'radio']
      if (selectorFieldTypes.includes(this.fieldType)){
        return true
      }
      return false
    },
    /**
     * Errors should always be passed as Array.
     * (<Object> is default value type)
     */
    fieldErrors: function(){
      if (Array.isArray(this.errors)){ 
        return this.errors
      } else {
        return [this.errors]
      }
    },
    /**
     * Errors can come from multiple sources:
     * - the prop `errors` usually set by API call
     * - input components that contains <input> elements can send their own local validation state
     * - custom events signalisation for all components : `emit('valid')` or `emit('invalid')`
     */
    hasError: function(){
      const errorState = this.errors && Object.keys(this.errors).length > 0
      return errorState || this.isInvalid
    },
    /**
     * Computes if the container should apply padding automagically
     * or if it lets the child handle it.
     */
    hasPadding: function (){
      // return !this.isSelector
      const excludePaddingFor = ['multiselect', 'rte', 'image', 'regularfile', 'longstring', 'toggle', 'radio']
      return !excludePaddingFor.includes(this.fieldType)
      // return this.fieldType != 'multiselect' && this.fieldType != 'rte'
    },
    hasBorder: function (){
      const withoutBorder= ['toggle', 'checkbox', 'rte', 'image', 'regularfile', 'radio']
      if (withoutBorder.includes(this.fieldType)) return false
      return true
    },
    showHelpText: function(){
      if (this.isSelector){
        return this.mouseOverField && !this.hasFocus
      } else {
        return this.hasFocus
      }
    },

  },
  mounted: function() {},
  methods: {
    initField: function(){
      // this should guess if value is set or get
      // and set pristine accordingly
      this.dirty = false
      this.pristine = true
    },
    // EVENT METHODS
    onInput: function(value) {
      // console.debug(`BaseInputContainer ${this.label} got`, value);
      this.pristine = false;
      this.dirty = true;
    },
    updateField: function(event) {
      if (event && event.target) this.$emit('update', event.target.value);
      else this.$emit('update', event);
      this.initField()
    },
    cancelField: function() {
      this.$emit('cancel');
      this.initField();
    },
    // STYLE METHODS
    onMouseEnterField: function (){
      // console.debug('mouseENTER', this.mouseOverField)
      this.mouseOverField = true
    },
    onMouseLeaveField: function (){
      // console.debug('mouseLEAVE', this.mouseOverField)
      this.mouseOverField = false
    },
    onFieldFocus: function (){
      // console.debug('FOCUS', this.label, this.mouseOverField)
      this.hasFocus = true
    },
    onFieldBlur: function (){
      // console.debug('BLUR', this.label, this.mouseOverField)
      this.mouseOverField = false
      this.hasFocus = false
    },
    onInvalid: function (validationMessage){
      this.isInvalid = true
      this.isValid = false
      this.validationError = validationMessage
    },
    onValid: function (){
      this.isValid = true
      this.isInvalid = false
      this.validationError = null
    },
    onResetValidity: function (){
      this.isValid = false
      this.isInvalid = false
    },
    /**
     * Sets focus to the <input> element inside if available.
     */
    setFocus: function (){
      /* no input element present in some baseinputs so let's stop here */
      if (!this.$refs.inputElement) return null
      const inputEl = this.$refs.inputElement.$el.querySelector('input')
      /** excluding selectors that handles focus on his own. */
      if (inputEl && !this.hasFocus && !this.isSelector){
        inputEl.focus()
      }
    }
  },
};
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-input-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  /* max-width: 100%; */
  /* width: 100%; */
  /* margin: 0px; */
  /* min-height: 75px; */
  /* margin-bottom: 30px; */
  /* max-width: 618px; */
  /* width: 100%; */
}
.field-label {
  height: 0.9rem;
  line-height: 0.9rem;
  vertical-align: middle;
  font-family: 'Rubik Medium';
  text-transform: uppercase;
  font-size: var(--h7);
  letter-spacing: var(--h7-ls);
  margin-bottom: 4px;
}
.pristine {
  
}
/* TODO: design field's dirty state */
/* .dirty {
  border: 1px solid blue;
} */
/* .modified{
  background-color: #bbb;
} */
.field-container{
  display: flex;
  /* justify-content: space-between; */
  box-sizing: border-box;
  height: var(--field-default-height);
  /* max-height: var(--field-default-height); */
  width: 100%;
}
.input-box {
  flex-grow: 1;
  display: flex;
  /* border: 1px solid #707070; */
  border: 1px solid var(--ruler-color);
  padding: var(--field-default-padding);
}
.no-padding{
  flex: auto;
}
.no-padding .input-box{
  padding: 0px;
}
.no-padding .field-container{
  height: auto;
  padding: 0px;
}
.has-focus .input-box{
  /* border: 3px solid #707070; */
  border: 2px solid var(--primary-color);
}
.is-invalid .input-box, 
.has-error .input-box{
  border-left: 4px solid var(--error-color) ;
}
.has-focus.is-invalid .input-box, 
.has-focus.has-error .input-box{
  border: 3px solid var(--ruler-color);
  border-left: 4px solid var(--error-color) ;
}
.is-valid .input-box{
  border: 1px solid var(--valid-color);
}
.input-box.no-border{
  outline: none;
  border: none;
}

.input-container{
  display: flex;
  align-items: center;
  width: 100%;
}
.input-validation{
  display: none;
}
.input-validation.show-validation{
  display: flex;
  align-items: center;
  /* margin-left: 0px; */
  margin-left: 14px;
}
.validation-icon{
  height: 1rem;
  user-select: none;
}
.validation-icon img{
  height: 100%;
}

.field-label .required{
  font-size: 120%;
}
.help-text{
  position: absolute;
  top: 100%;
  max-height: 100px;
  opacity: 0.0;
  box-sizing: border-box;
  background-color: #7B7B7B;
  box-shadow: 0px 0px 10px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1rem;
  transition: 0.3s linear;
  z-index: -1;
}
.help-text p{
  line-height: 0.9rem;
  vertical-align: middle;
  font-family: 'Rubik Medium';
  font-size: 0.75rem;
  letter-spacing: 0.09rem;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
}
.show-help-text{
  opacity: 1.0;
  z-index: 5; /* arbitrarily high */
}
.field-errors{
  top: 100px;
  font-family: 'Rubik Medium';
  font-size: 0.75rem;
  letter-spacing: 0.075rem;
  color: #000000;
  padding-top: 5px;
}



/* ALL TEXTINPUTS */
.base-textinput-container{
  /* flex: 1; */
  width: 100%;
}
/* theme: voluntas */
.voluntas .input-box{
  border: none;
  border-bottom: 1px solid #dbdbdb;
}
.voluntas.has-focus .input-box{
  border: 1px solid #dbdbdb;
}


</style>
<style>
.base-input-container .input-container input{
  border: none;
  outline: none;
  width: 100%;
  height: 1.2rem;
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
}

</style>
