export const baseinputs = {
  data: function () {
    return {}
  },
  props: {
    /**
     * General styling
     * Available styles are defined in components.
     */
    styles: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    /**
     * Field can be disabled.
     * TODO: really deactivate input interaction
     */
    disabled:{
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * fieldType permits field mapping by simple 'type' name.
     * Please look at fieldComponent to learn more.
     */
    fieldType: {
      type: String,
      required: false,
      default: () => {
        return '';
      },
    },
    /**
     * Required is transmitted as prop to children.
     * Is used for local validation purposes.
     */
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: [String, Number],
      required: false,
      default: undefined
    },
     /**
     * Placeholder element
     */
    placeholder: true,
  },
  computed: {
    inputListeners: function(){
      let vm = this
      return {
        ...this.$listeners,
        input: function(event){
          vm.$emit('input', event.target.value)
        }
      }
    },
    textInputListeners: function(){
      let vm = this
      return {
        ...this.$listeners,
        input: function(event){
          vm.checkValidity(event.target.value)
          if (!event.target.value){
            vm.$emit('reset-validity')
          }
        },
      }
    },
  },
  methods: {
    checkValidity: function(valueToCheck){
      if (this.$refs.inputElement.validity.valid){
        this.$emit('valid')
      } else {
        this.$emit('invalid', this.$refs.inputElement.validationMessage)
      }
      this.$emit('input', valueToCheck)
    }
  },
};
