import { mapGetters, mapState } from 'vuex'

export const modelFilters = {
  props: {
    filterValues: {
      type: Object,
      required: false,
      default: ()=>{ return {} }
    }
  },
  data: function(){
    return {
      modelName: '',
      // filterValues: {},
      queryParams: {}
    }
  },
  computed: {
    availableFilterFields: function(){
      return this.$store.state[this.modelName].availableFilterFields
    },
    ...mapState('choices', [
      'AGE_FILTER_CHOICES',
      'AVAILABILITY_CHOICES',
      'CAUSE_CHOICES',
      'CERTIFICATE_CHOICES',
      'COUNTRY_CHOICES',
      'CURRENCY_CHOICES',
      'CV_TYPE_CHOICES',
      'DATE_FILTER_CHOICES',
      'DELIVERY_CHOICES',
      'EDITION_CHOICES',
      'FORMAT_CHOICES',
      'FRAGILITY_CHOICES',
      'GENDER_CHOICES',
      'GENRE_CHOICES',
      'MEDIUM_CHOICES',
      'PRICE_FILTER_CHOICES',
      'SHIPMENT_MODE_CHOICES',
      'SIZE_FILTER_CHOICES',
      'STATUS_CHOICES',
      'TAG_CATEGORY_CHOICES',
      'TAX_RECEIPT_CHOICES',
      'TRANSACTION_STATUS_CHOICES',
    ]),
    /* doesn't exist */
    // ...mapState('artist', {
    //   profileChoices: state => state.profileChoices
    // }),
    ...mapGetters('gallery', [
      'cityChoices',
      'regionChoices',
    ]),
    ...mapGetters('event', [
    //   'dateChoices',
      'typeChoices',
    ]),
    ...mapGetters('fieldpicture', [
      'contentTypeChoices',
    ]),
  },
  methods: {

  },
  mounted: function(){
  }
}
