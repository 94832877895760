<template>
  <div class="conversation-panel">
    <header class="conversation-head">
      <h1
        v-if="panelTitle"
        class="panel-title title-level-6"
      >
        Subject: {{ panelTitle }}
      </h1>
    </header>
    <!-- UPPER SIDE OF THE CONVERSATION PANEL -->
    <div
      id="messages-list-container"
      ref="messagesListEl"
    >
      <div class="messages-list">
        <template v-for="(conversation, dIdx) in conversations">
          <!-- MESSAGES COMMON DATE DAY -->
          <div
            :key="'d' + dIdx"
            class="messages-common-day"
          >
            <template v-if="conversation.date.getDate() == todayDay">
              Today
            </template>
            <template v-else>
              {{ conversation.date | formatDateTime('SHORT_DATE') }}
            </template>
          </div>
          <hr
            :key="'d' + 'h' + dIdx"
            class="horizontal-bar"
          >
          <!-- MESSAGES DURING THIS DAY -->
          <template v-for="(message, mIdx) in conversation.comments.results">
            <BaseComment
              :key="'d' + dIdx + 'm' + mIdx"
              :message="message"
              :current-username="currentUsername"
            />
          </template>
        </template>
        <!-- EMPTY CONVERSATION PLACEHOLDER -->
        <div
          v-if="conversations.length == 0"
          class="conversation-empty text-cdt"
        >
          No messages yet
        </div>
      </div>
    </div>
    <!-- LOWER SIDE OF THE CONVERSATION PANEL -->
    <div id="message-form-container">
      <!-- TEXT INPUT FOR NEW MESSAGE -->
      <div class="message-form">
        <BaseInputContainer
          id="message-input"
          v-model="messageToSend"
          field-type="longstring"
          placeholder="Type your message..."
          :validable="false"
        />
      </div>
      <!-- SEND BUTTON -->
    </div>
    <div class="message-controls">
      <BaseInputContainer
        v-if="item.metadata"
        v-model="item.metadata.conversation_active"
        class="conversation-status"
        field-type="toggle"
        :options="[
          {label: 'Closed', value: false},
          {label: 'Open', value: true}
        ]"
        :validable="false"
        @input="changeConversationStatus"
      />
      <BaseButton
        label="Send"
        class="send-button"
        :disabled="messageToSend.length == 0"
        :styles="['CTA-nav']"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseConversationPanel',
  props: {
    /**
     * A grouped messages list.
     * Each element of the list is one day of 
     * conversation.
     */
    comments: {
      type: Array,
      required: true,
      default: () => {
        return null
      },
    },
    /**
     * currentUsername is used to style
     * message display depending on user
     */
    currentUsername: {
      type: String,
      required: true,
      default: null,
    },
    /**
     * Heritates item and modelName to compute
     * a nice title for the conversation
     */
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    modelName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data: function () {
    return {
      messageToSend: '',
      conversationIsVisible: undefined,
      gotMessageDraft: false,
    }
  },
  computed: {
    /**
     * What's the day today ?
     */
    todayDay: function () {
      const d = new Date()
      return d.getDate()
    },
    /**
     * computes a title for the conversation panel
     */
    panelTitle: function () {
      const itemName = this.item.title || this.item.name
      const responsible = this.item.artist || this.item.organisation
      return `${responsible ? responsible.name + ' - ' : ''}${
        itemName ? itemName : ''
      }`
    },
    /**
     * Python date needs conversion to Date js type
     */
    conversations: function () {
      return this.comments.map((conv) => {
        return {
          ...conv,
          date: new Date(conv.date),
        }
      })
    },
  },
  watch: {
    /**
     * indicates draft status
     */
    messageToSend: function (nv, ov) {
      if (nv != ov && nv.length > 0) {
        this.gotMessageDraft = true
      } else {
        this.gotMessageDraft = false
      }
      this.$emit('draft-message', this.gotMessageDraft)
    },
    /**
     * autoscrolls on new messages
     */
    comments: function () {
      this.$nextTick(()=>{
        this.scrollToEnd()
      })
    }
  },
  mounted: function () {
    this.initVisibilityDetector()
  },
  methods: {
    /**
     * sends a message as a custom vue event
     * should be handled by parent view (DetailsView)
     */
    sendMessage: async function () {
      this.$emit('push-message', { message: this.messageToSend })
      this.messageToSend = ''
    },
    /**
     * scroll to the start of the last message
     */
    scrollToEnd: function () {
      this.$refs.messagesListEl.scrollTop =
        this.$refs.messagesListEl.firstElementChild.lastElementChild.offsetTop
    },
    /**
     * Uses an IntersectionObserver to detect
     * if conversation just became visible.
     * If so, messages-list scrolls to most recent message.
     */
    initVisibilityDetector: function () {
      const visibleEl = this.$refs.messagesListEl
      const obsOptions = { threshold: 0.0 }
      const observer = new IntersectionObserver((e, obs) => {
        this.conversationIsVisible = e[0].isIntersecting
        if (this.conversationIsVisible) this.$nextTick(() => this.scrollToEnd())
        // console.debug('CONVPANEL: visibility changed to', this.conversationIsVisible)
      }, obsOptions)
      observer.observe(visibleEl)
    },
    changeConversationStatus: function() {
      this.$emit('conversation-status-change', { active : this.item.metadata.conversation_active })
    }
  },
}
</script>

<style scoped>
/* CONVERSATION PANEL CONTAINER */
.conversation-panel {
  display: flex;
  flex-direction: column;
  --conversation-height: calc(100vh - 40px - var(--topbar-height));
  --message-input-height: 150px;
  height: var(--conversation-height);
  overflow-y: hidden;
  border: var(--default-ruler);
  padding: 10px;
  background-color: var(--grey-light);
}
.conversation-head{
  display:flex;
  justify-content: space-between;
  align-items:flex-start;
}
.panel-title {
  margin-bottom: 5px;
}
/* UPPER SIDE OF THE CONVERSATION PANEL */
#messages-list-container {
  height: calc(var(--conversation-height) - var(--message-input-height));
  overflow-y: scroll; /* this is the scrollable messages container */
}
.messages-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.messages-common-day {
  display: flex;
  align-self: center;
  /* border: 1px solid var(--grey-medium); */
  background-color: var(--grey-medium);
  color: var(--grey-dark);
  border-radius: 16px;
  padding: 8px;
  margin-top: 20px;
  z-index: 1;
  font-family: 'Rubik Regular';
}
.horizontal-bar {
  border: none;
  border-top: 1px solid var(--grey-medium);
  width: 100%;
  transform: translate(0%, -80%);
  height: 20px;
  margin-bottom: -20px;
}
/* LOWER SIDE OF THE CONVERSATION PANEL */
#message-form-container {
  height: 100px;
  margin-top: 10px;
  height: var(--message-input-height);
  position: relative;
}
.message-form {
  display: flex;
  flex-direction: column;
}

.conversation-status.base-input-container{
  flex:initial;
}
#message-input {
  background-color: var(--white);
}
#message-input .base-textareainput-container {
  min-height: none;
}
#message-input >>> .input-box {
  border: none;
}

.message-controls{
  display: flex;
  justify-content: space-between;
}

/* SCROLLBAR */
#messages-list-container::-webkit-scrollbar {
  width: 8px;
}
#messages-list-container::-webkit-scrollbar-track {
  background-color: var(--grey-medium);
}
#messages-list-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}
#messages-list-container {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) var(--grey-medium);
}
</style>
