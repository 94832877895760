<template>
  <div
    v-if="item.id"
    class="form"
  >
    <header class="form-header">
      <div class="item-title-container">
        <h2>Transaction</h2>
        <h1>{{ item.order.artwork.title }}, {{ item.order.artwork.year }}</h1>
        <!-- ADMIN-TAGS -->
        <slot name="admin-tags" />   
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---------- PURCHASE DETAILED INFORMATIONS ---------- -->
    <section class="tab-view-container">
      <h2>Purchase</h2>
      <!-- ---------- BOUGHT ITEM ----------  -->
      <section class="vertical-block purchase-artwork-details">
        <div class="picture-container">
          <router-link 
            :to="{
              name:'artwork-view', params: {id: item.order.artwork.id}
            }"
          >
            <img
              class="artwork-image"
              :src="artworkPicture"
            >
          </router-link>
        </div>
        <div class="artwork-details">
          <p class="artwork-author">
            {{ artwork.artist.name }}
          </p>
          <p class="artwork-title">
            {{ artwork.title }},
            <span class="artwork-year">
              {{ artwork.year }}
            </span>
          </p>
          <p>{{ artwork.gallery.name }}</p>
        </div>
      </section>
      <!-- ---------- FINANCIAL DETAILS ----------  -->
      <section class="vertical-block">
        <h3 class="title-level-7">
          Price breakdown
        </h3>
        <!-- ---------- PRICE BREAKDOWN ----------  -->
        <table class="tabular-data">
          <!-- TODO: Warning : currencies / prices differ -->
          <tr>
            <td class="field-cell">
              Artwork price
            </td>
            <td>
              {{ item.order.price | formatPrice(item.order.currency.code) }}
            </td>
          </tr>
          <tr>
            <!-- TODO: grab donation data from the transaction details instead of computed values -->
            <td class="field-cell">
              Donation amount
            </td>
            <td>
              {{ donationAmount | formatPrice(item.order.currency.code) }}
            </td>
          </tr>
          <tr>
            <td class="field-cell">
              Shipment price
            </td>
            <td>
              {{ item.shipment_price | formatPrice(item.order.currency.code) }}
            </td>
          </tr>
          <!-- <tr>
            <td>Currency tax</td>
            <td class="field-cell">{{ item.shipment_price | formatPrice(item.order.currency.code) }}</td>
          </tr> -->
          <tr>
            <td class="field-cell">
              Total Price
            </td>
            <td>
              {{ item.total_price | formatPrice(item.order.currency.code) }}
            </td>
          </tr>
        </table>
        <!-- ---------- TAXES BREAKDOWN ----------  -->
        <div>
          <h3 class="title-level-7">
            Tax and duties
          </h3>
          <table class="tabular-data">
            <tr>
              <td class="field-cell">
                Breakdown
              </td>
              <template
                v-if="item.shipping && item.shipping.avatax_details && item.shipping.avatax_details.summary.length > 0"
              >
                <td class="list-container-cell">
                  <ul>
                    <li
                      v-for="(tax , taxIdx) in taxDetails"
                      :key="`tads-${taxIdx}`"
                    >
                      {{ tax.jurisName }} {{ tax.taxAuthorityType }} ({{ tax.rate * 100 }}%) : {{ tax.taxCalculated | formatPrice(item.shipping.tax_currency.code) }}
                    </li>
                  </ul>
                </td>
              </template>
              <template
                v-else
              >
                <td>
                  No tax details available.
                </td>
              </template>
            </tr>            
            <tr v-if="item.shipping && item.shipping.total_tax">
              <td class="field-cell">
                Total
              </td>
              <td> 
                {{ item.shipping.total_tax | formatPrice(item.order.currency.code) }}
              </td>
            </tr>
          </table>
        </div>
        <!-- ---------- TRANSACTION DETAILS ----------  -->
        <div>
          <h3 class="title-level-7">
            Transaction details
          </h3>
          <table class="tabular-data">
            <tr>
              <td class="field-cell">
                Date
              </td>
              <td>
                {{ item.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
              </td>
            </tr>
            <tr>
              <td class="field-cell">
                Status
              </td>
              <td>
                {{ item.status }}
              </td>
            </tr>
            <tr>
              <td class="field-cell">
                Unique ID
              </td>
              <td>
                {{ item.id }}
              </td>
            </tr>
          </table>
        </div>
        <!-- ---------- PAYMENT DETAILS ----------  -->
        <div>
          <h3 class="title-level-7">
            Payment details
          </h3>
          <table
            v-if="item.payment && item.payment.stripe_id"
            class="tabular-data"
          >
            <tr>
              <td class="field-cell">
                Type
              </td>
              <td>
                <template v-if="stripePaymentDetailsURL">
                  <a
                    :href="stripePaymentDetailsURL"
                    target="_blank"
                  >
                    <BasePartnerLogo
                      partner-name="stripe"
                      class="inline"
                    />
                  </a>
                </template>
                <template v-else>
                  No payment
                </template>
              </td>
            </tr>
            <tr>
              <td class="field-cell">
                Date
              </td>
              <td>
                {{ item.payment.date | formatDateTime('SHORT_NUMERIC_DATETIME') }}
              </td>
            </tr>
            <tr>
              <td class="field-cell">
                Stripe payment ID
              </td>
              <td>
                {{ item.payment.stripe_id }}
              </td>
            </tr>
          </table>
          <div v-else>
            <p>No payment details</p>
          </div>
        </div>
      </section>
    </section>
    <!-- ---------- DELIVERY / SHIPMENT DETAILS ----------  -->
    <section
      v-if="item.forth"
      class="tab-view-container"
    >
      <!-- --- DELIVERY DETAILS --- -->
      <section class="vertical-block">
        <h2 class="title-level-7">
          Delivery
        </h2>
        <ForthForm
          :item="item.forth"
          :errors="errors.forth"
          editable
          v-on="$listeners"
          @dirtysubform="onDirtySubform"
        >
          <template slot="subform-controls">
            <slot name="controls" />
          </template>
        </ForthForm>
      </section>
      <!-- --- SHIPMENT ORIGIN ADDRESS --- -->
      <section class="vertical-block addresses-section">
        <div class="address-block">
          <h3 class="title-level-7">
            Origin
          </h3>
          <BaseAddressDisplay
            v-if="item.forth && item.forth.origin"
            :address="item.forth.origin"
          />
        </div>
      </section>
    </section>
    <!-- ---------- BUYER DETAILS ----------  -->
    <section class="tab-view-container">
      <h2>Buyer</h2>
      <!-- --- BUYER identification--- -->
      <section class="vertical-block">
        <table class="tabular-data">
          <tr>
            <td class="field-cell">
              Firstname
            </td>
            <td>
              {{ buyer.first_name }}
            </td>
          </tr>
          <tr>
            <td class="field-cell">
              Lastname
            </td>
            <td>
              {{ buyer.last_name }}
            </td>
          </tr>
          <tr>
            <td class="field-cell">
              Username
            </td>
            <td>
              {{ buyer.username }}
            </td>
          </tr>
          <tr v-if="buyer.email">
            <td class="field-cell">
              email
            </td>
            <td>
              {{ buyer.email }}
            </td>
          </tr>          
        </table>
      </section>
      <!-- --- BUYER addresses --- -->
      <section class="vertical-block addresses-section">
        <div class="address-block">
          <h3 class="title-level-7">
            Billing address
          </h3>
          <BaseAddressDisplay
            v-if="item.billing"
            :address="item.billing"
          />
          <p
            v-else
            class="empty-info"
          >
            No billing address known
          </p>
        </div>
        <div class="address-block">
          <h3 class="title-level-7">
            Shipping address
          </h3>
          <BaseAddressDisplay
            v-if="item.shipping"
            :address="item.shipping"
          />
          <p
            v-else
            class="empty-info"
          >
            No shipping address known
          </p>
        </div>
      </section>
    </section>
    <!-- ---------- PLEDGE DETAILS ----------  -->
    <section 
      v-if="item.donation && item.donation.cause_set"
      class="tab-view-container"
    >
      <h2>Pledge</h2>
      <table class="tabular-data">
        <tr>
          <td class="field-cell">
            Amount
          </td>
          <td>
            {{ donationAmount | formatPrice(item.order.currency.code) }}
          </td>
        </tr>
        <tr>
          <td class="field-cell">
            UN goals
          </td>
          <!-- DISPLAY DONATION CAUSE IF ANY -->
          <td
            v-if="item.donation"
            class="boxed-causes"
          >
            <template v-for="(cause, causeIdx) in item.donation.cause_set">
              <BaseCauseSquare
                :key="'bcs-'+causeIdx+'-'+cause.id"
                :cause-index="cause.id"
                :hoverable="false"
              />
            </template>
          </td>         
          <!-- NO DONATION -->
          <td v-else>
            No donation selected.
          </td>       
        </tr>
      </table>
      <!-- ---------- DONATE TO details ----------  -->
      <template v-if="item.donation">
        <BaseProjectBox
          class="review-project"
          :project="item.donation.project"
          box-title=""
        />
      </template>
    </section>
    <!-- CO2 COMPENSATION PROJECT - only for ARTA shipments -->
    <section
      v-if="item.forth && item.forth.mode == 'A'"
      class="tab-view-container"
    >
      <h2 class="title-level-7">
        CO<sub>2</sub> offset
      </h2>
      <table class="tabular-data">
        <tr>
          <td class="field-cell">
            Estimated emissions
          </td>
          <td>
            {{ item.forth.co2_kg }} kg
          </td>
        </tr>
        <tr>
          <td class="field-cell">
            Due compensation
          </td>
          <td>
            {{ item.forth.co2_eur | formatPrice("EUR") }}
          </td>
        </tr>
      </table>
      <BaseProjectBox
        v-if="item.forth.co2_project"
        :project="item.forth.co2_project"
        box-title=""
      />
    </section>
  </div>
</template>

<script>
/* Mixin */
import { modelForms } from '@/mixins/modelForms.js'
import ForthForm from '@/components/transaction/ForthForm'

const DONATION_COEFFICIENT = 0.1
const STRIPE_ROOT_URL = process.env.VUE_APP_STRIPE_ROOT_URL

export default {
  name: 'TransactionForm',
  components: {
    ForthForm,
  },
  mixins: [modelForms],
  data: function () {
    return { }
  },
  computed: {
    artwork: function () {
      if (this.item && this.item.order.artwork)
        return this.item.order.artwork
      return null
    },
    artworkPicture: function () {
      return this.artwork.media_set[0].picture.web || this.artwork.order.picture
    },
    buyer: function () {
      if (this.item && this.item.order.buyer)
        return this.item.order.buyer
      return null
    },
    deliveryMethod: function (){
      if (!this.item.shipmentquote) return 'No delivery method specified'
      const choice = this.$store.state.choices.DELIVERY_CHOICES
        .find((c)=> c.value == this.item.shipmentquote.mode)
      if (choice) return choice.display_name
      return 'unknown delivery method'
    },
    // TODO: replace by transaction data
    donationAmount: function() {
      if (!this.item.order.price) return 0
      return this.item.order.price * DONATION_COEFFICIENT
    },
    stripePaymentDetailsURL: function () {
      if (this.item.payment != null) {
        const acct = this.item.payment.connect_id
        const pi = this.item.payment.stripe_id
        return `${STRIPE_ROOT_URL}${acct}/payments/${pi}`
      } else {
        return null
      }
    },
    taxDetails: function () {
      let taxDetails = [...this.item.shipping.avatax_details.summary]
      /* TODO: comment lines below and replace by code without side-effects */
      taxDetails
        .map((line) => {
          line.jurisName = line.jurisName
            .trim()
            .toLowerCase()
            .replace(/\w\S*/g, (w) =>
              w.replace(/^\w/, (c) => c.toUpperCase())
            )
          
          const choice = this.$store.state.choices.TAX_AUTHORITY_TYPE_CHOICES
          .find((c) => c.value == line.taxAuthorityType)
          if (choice) line.taxAuthorityType = choice.display_name
        })
      return taxDetails
    },
  },
  methods: {},
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
.tab-view-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: var(--field-default-h-padding);
  border: var(--default-ruler);
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  letter-spacing: var(--paragraph-ls);
  line-height: var(--paragraph-lh);
  margin-bottom: var(--field-v-margin);
}
.tab-view-container .vertical-block {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-wrap: wrap;
}
h3.title-level-7 {
  font-size: var(--h7);
  line-height: var(--h7-lh);
}
.tab-view-container .purchase-artwork-details{
  align-items: center;
  justify-content: stretch;
}
.tab-view-container section.addresses-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.tab-view-container section.addresses-section .address-block {
  margin-right: var(--field-v-margin);
}
table.tabular-data{
  margin-bottom: var(--field-h-margin);
  border-spacing: 10px 5px;
}
.field-cell{
  text-transform: lowercase;
  font-variant: small-caps;
  font-family: 'Rubik SemiBold';
}
.list-container-cell{
  padding-left: 10px;
}
.picture-container {
  width: min(200px, 100vw);
  display: flex;
  justify-content: center;
  align-items: center;
}
.picture-container img {
  object-fit: contain;
  width: 100%;
}
.artwork-author {
  font-family: 'Rubik SemiBold';
  text-transform: uppercase;
}
.artwork-title {
  font-family: 'Rubik SemiBold Italic';
}
.artwork-year{
  font-family: 'Rubik SemiBold';
}
.boxed-causes {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * 20px);
  margin-top: 20px;
}
.boxed-causes .base-cause-square{
  --margin: 20px;
  margin-right: var(--margin);
  margin-bottom: var(--margin);
  /* --default-width: calc(100% / 6 - var(--margin)); */
}
/* .small-theme .boxed-causes .base-cause-square{
  --default-width: calc(100% / 3 - var(--margin));
} */

/* SMALL */
/* .small-theme .tab-view-container .vertical-block{
  width: initial;
} */
</style>
