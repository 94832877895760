import Vue from 'vue'
import Vuex from 'vuex'

/* STORE MODULES */
import address  from './modules/address'
import artist   from './modules/artist'
import artwork  from './modules/artwork'
import country  from './modules/country'
import currency from './modules/currency'
import event    from './modules/event'
import gallery  from './modules/gallery'
import transaction    from './modules/transaction'

/* special store modules */
import choices from './modules/choices'
import user from './modules/user'
import ui from './modules/ui'

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    address,
    artist,
    artwork,
    country,
    currency,
    event,
    gallery,
    transaction,
    
    choices,
    user,
    ui,
  },
  strict: debug
})
