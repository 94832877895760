<template>
  <section>
    <header class="header">
      <div class="img-header">
        <img
          title="Error"
          src="@/base/assets/img/svgicons/alert-triangle.svg"
        >
        <BasePartnerLogo
          partner-name="stripe"
          class="inline"
        />
      </div>
      <div class="warning-header">
        <p>
          In order to receive payment from The Art Pledge platform, you are
          kindly asked to create a Stripe connect account. To do so, please
          <a
            :href="$store.state.user.stripeConnectLink.url"
            target="_blank"
          >
            click here
          </a>
        </p>
        <p>
          The link expires at
          {{ expirationDate | formatDateTime('SHORT_DATETIME') }}
          please log out and log in again generate a new one.
        </p>
      </div>
    </header>
  </section>
</template>

<script>
export default {
  name: 'StripeConnectLink',
  props: {},
  computed: {
    expirationDate: function () {
      const ed = this.$store.state.user.stripeConnectLink.expires_at * 1000
      return new Date(ed)
    },
  },
}
</script>

<style scoped>
.header {
  border: var(--default-admin-border);
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.warning-header {
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  text-align: center;
}
.img-header {
  display: flex;
  flex-direction: column;
  justify-self: auto;
}
.header a {
  color: var(--primary-color);
}
</style>
