import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

if (process.env.NODE_ENV == 'production') {
  Vue.config.productionTip = false
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
  // console.log = () => {}
  // console.debug = () => {}
  /* 
  this will be nice to log users errors
  Vue.config.errorHandler = (err, vm, info)=>{
     console.log(err)
  } 
  */
} else {
  console.log('🎉 Welcome fellow developer !')
}

/* API services initialization */
import APIs from '@/services'
APIs.init()

/* workbox */
import wb from "./registerServiceWorker";
Vue.prototype.$workbox = wb;

/* import base components */
import Base from '@/base/main'
Base.initBaseComponents()


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
