<template>
  <div 
    :class="[
      'base-textinput-container', 
      { disabled: disabled },
      ...styles
    ]"
  >
    <input
      type="text"
      :placeholder="placeholder"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      :maxlength="maxLength"
      :autocomplete="autocomplete"
      v-on="inputListeners"
      @input="checkValidity"
    >
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'

export default {
  name: 'BaseTextInput',
  mixins: [baseinputs],
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Type something ...',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * facultative and not enabled by default
     * allows browser and forms manager to make suggestions
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
     */
    autocomplete: {
      type: String,
      required: false,
      default: null, // 'on', 'username', 'email', etc... see mdn doc.
    },
  },
  data: function () {
    return {}
  },
  computed: {},
  mounted: function () {
    if (this.required) {
      this.$el.querySelector('input').required = true
    }
  },
  methods: {
    checkValidity: function () {
      const inputEl = this.$el.querySelector('input')
      const msg = inputEl.validationMessage
      if (!inputEl.validity.valid) {
        this.$emit('invalid', msg)
      } else {
        this.$emit('valid')
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>

</style>