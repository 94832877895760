<template>
  <div
    class="base-checkbox"
    :class="[
      ...styles, 
      { checked: value }, 
      { disabled: disabled }
    ]"
    @click="toggleValue"
  >
    <div class="checkbox-and-label">
      <div class="checkbox-container">
        <template v-if="value === true">
          <img
            class="check-icon"
            src="../../assets/img/svgicons/check-white.svg"
          >
        </template>
      </div>
      <template v-if="label">
        <div class="label">
          {{ label }}
        </div>
      </template>
    </div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="horizontalLabel"
      class="label"
      v-html="horizontalLabel"
    />
    <!--eslint-enable-->
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'

export default {
  name: 'BaseCheckbox',
  mixins: [baseinputs],
  props: {
    value: {
      type: Boolean,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    horizontalLabel: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {}
  },
  computed: {},
  mounted: function () {},
  methods: {
    toggleValue: function () {
      if (!this.disabled) {
        this.$emit('click')
        this.$emit('input', !this.value)
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.checkbox-container {
  box-sizing: border-box;
  height: 1rem;
  width: 1rem;
  border: 1px solid #707070;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  pointer-events: none;
}
.checkbox-and-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  cursor: pointer;
}
.base-checkbox:hover {
  background-color: #f1f1f1;
}
.check-icon {
  width: 1rem;
}
/* checked state */
.checked .checkbox-container {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}
/* disabled state */

.disabled.base-checkbox:hover {
  background-color: initial;
  cursor: not-allowed;
}
.disabled .checkbox-container {
  background-color: #f1f1f1;
}
.checked.disabled .checkbox-container {
  background-color: #707070b3;
}
.label {
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  line-height: var(--paragraph-lh);
}
/* v-html requires >>> */
.label >>> a {
  text-decoration: underline;
}
/* checked disabled */
.checked.disabled .checkbox-container {
  border: 1px solid var(--grey-dark);
}
</style>
