<template>
  <div :class="['base-textinput-container', ...styles]">
    <input
      ref="inputElement"
      type="email"
      :placeholder="placeholder"
      :maxlength="maxLength"
      pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      v-bind="$attrs"
      :value="value"
      v-on="textInputListeners"
    >
  </div>
</template>

<script>
import {baseinputs} from '../../mixins/baseinputs'

export default {
  name: 'BaseEmailInput',
  mixins: [baseinputs],
  props: {
    /**
     * Text input value for email address.
     * Will be validated in the computed `input` inputListeners.
     */
    value: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * If no email address provided, `<input>` element will
     * show default placeholder.
     */
    placeholder: {
      type: String,
      required: false,
      default: 'Enter a valid email address...'
    },
  },
  mounted: function(){
    // Check validity at init
    // if (this.value || this.required){
    //   this.checkValidity(this.value)
    // }
  }
};
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>

</style>
