export const animatedBevelMixin = {
  props: {
    disableAnimation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      elementIsMounted: false,
      elementObserver: {},
    }
  },
  computed: {},
  mounted: function () {
    if (this.disableAnimation) {
      this.disableBevelAnimation()
    } else {
      this.initBevelAnimation()
    }
  },
  methods: {
    /**
     * Disables animation
     */
    disableBevelAnimation: function () {
      this.elementIsMounted = true
      this.$el.style.setProperty('--appear-delay', `0ms`)
      this.$el.style.setProperty('--appear-timing-basis', `0ms`)
      return null
    },
    /**
     * Randomize animation parameters and initializes intersectionObserver
     */
    initBevelAnimation: function () {
      // const thisElIdx = [...document.querySelectorAll('.base-artwork')].indexOf(this.$el)
      /* initializing intersection observer */
      this.initObserver()
      /* random timings to decouple motions and avoid guillotine effect */
      let appearDelay = 100 * Math.random()
      let animationTimingBasis = 400 * (Math.random() + 0.5)
      /* small mobile screen does not need much randomization
        reducing animation's delays make scrolling less penible . */
      if (this.$store.state.ui.isMobile) {
        appearDelay = 0
        animationTimingBasis = 400
      }
      /* theses values will supersede CSS defined variables */
      this.$el.style.setProperty('--appear-delay', `${appearDelay}ms`)
      this.$el.style.setProperty(
        '--appear-timing-basis',
        `${animationTimingBasis}ms`
      )
    },
    /**
     *  this intersectionObserver activates the bevel animation
     * when item appears inside viewport
     */
    initObserver: function () {
      this.elementObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          /* this works on appear and disappear which seems a bit too much */
          // this.elementIsMounted = entry.isIntersecting
          /* this one makes the content appear only once */
          if (entry.isIntersecting) this.elementIsMounted = true
        })
      })
      this.elementObserver.observe(this.$el)
    },
  },
}
