<template>
  <div
    :class="['base-scroll-to-top']"
    @click="goToTop"
  >
    <img
      src="../assets/img/svgicons/arrow-up-white-thin.svg"
    >
  </div>
</template>

<script>
export default {
  name: 'BaseScrollToTop',
  props: {
  },
  data: function () {
    return {}
  },
  computed: {},
  methods: {
    goToTop: function () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    },
  },
}
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.base-scroll-to-top {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
}
.base-scroll-to-top:hover {
  background-color: var(--secondary-color);
}
.base-scroll-to-top img{
  height: 24px;
  width: 24px;
}
</style>
