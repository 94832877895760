import { mapState } from 'vuex'

export const modelRowUtil = {
  props: {
    object: {
      type: Object,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState({
      availableFields(state) {
        if (this.model) return state[this.model].availableFields
        return {}
      },
    }),
    ...mapState('ui', {
      smallScreen: state => state.isSmall,
    }),
    status: function() {
      const choice = this.$store.state.choices.STATUS_CHOICES.find(
        (c) => this.object.metadata.status == c.value
      )
      if (choice) return choice.display_name
      return null
    },
    tagCategory: function() {
      const choice = this.$store.state.choices.TAG_CATEGORY_CHOICES.find(
        (c) => c.value == this.object.category
      )
      if (choice) return choice.display_name
      return null
    },
    published() {
      if (this.object.metadata.published) {
        return 'Published'
      } else {
        return 'Not published'
      }
    },
  },
}
