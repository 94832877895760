<!-- 
        ____ ___ ____ ___ _ _ ___
        |___  |  |--|  |  _X_  | 
  note: default is at CTA-txt-2 cause it seems more common.
-->
<template>
  <span
    :class="[
      'base-cta-txt',
      ctaType,
      { disabled: disabled },
      { active: active },
    ]"
    :title="label"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    {{ label | truncate(30) }}
    <BaseArrow
      v-if="ctaType == 'CTA-txt-2'"
      ref="baseArrow"
      size="small"
      class="arrow"
    />
  </span>
</template>

<script>
export default {
  name: 'BaseCTAtxt',
  props: {
    /**
     * Label is a short string that should be displayed
     * on button face.
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Disabled button won't allow any user interaction.
     * No event will be emitted.
     * (from CSS, look at property `pointer-events: none;`)
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    ctaType: {
      type: String,
      required: false,
      default: 'CTA-txt-2',
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {}
  },
  methods: {
    onClick: function () {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
    onMouseEnter: function () {
      if (this.ctaType == 'CTA-txt-2') this.$refs.baseArrow.onMouseEnter()
    },
    onMouseLeave: function () {
      if (this.ctaType == 'CTA-txt-2') this.$refs.baseArrow.onMouseLeave()
    },
  },
}
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.base-cta-txt {
  text-decoration: underline;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  transition: var(--default-transition);
}
.CTA-txt-1 {
  font-family: 'Filson Pro Regular';
  font-size: var(--h2);
  line-height: var(--h2-lh);
  letter-spacing: var(--h2-ls);
}
.CTA-txt-2 {
  font-family: 'Filson Pro Bold';
  font-size: var(--paragraph);
  line-height: var(--paragraph-lh);
  /* letter-spacing: var(--ls-cdt); */
}
.base-cta-txt:hover {
  color: var(--primary-color);
}
.base-cta-txt .base-arrow{
  margin-left: 5px;
}
.base-cta-txt:hover .base-arrow {
  --stroke-color: var(--primary-color);
}
</style>
