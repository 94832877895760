<template>
  <div :class="['form', { 'dirty-form': dirty }]">
    <header class="form-header">
      <div class="item-title-container">
        <template v-if="itemRaw.id">
          <h2>Artist</h2>
          <h1>{{ itemRaw.name }}</h1>
          <!-- ADMIN-TAGS -->
          <slot name="admin-tags" />
        </template>
      </div>
      <slot name="meta-details-container" />
    </header>
    <!-- ---- METADATA FORM ----  -->
    <slot name="metadata" />
    <section>
      <h2>Artist's informations</h2>
      <BaseInputContainer
        v-model="item.name"
        :errors="errors.name"
        field-type="string"
        label="Artist's name"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.gender"
        :errors="errors.gender"
        field-type="select"
        label="Gender"
        :options="GENDER_CHOICES"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.birthyear"
        :errors="errors.birthyear"
        field-type="integer"
        label="Artist's birthyear"
        placeholder=""
        disabled
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.city_of_birth"
        :errors="errors.city_of_birth"
        field-type="string"
        label="City of birth"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.country_of_birth"
        :errors="errors.country_of_birth"
        field-type="select"
        :options="COUNTRY_CHOICES"
        label="Country of birth"
        reverse-field="code"
        disabled
      />
      <!-- :reverse-field="$store.state.choices.specialChoices['COUNTRY_CHOICES'].reverseField"
      :display-field="$store.state.choices.specialChoices['COUNTRY_CHOICES'].displayField" -->
    </section>
    <section>
      <BaseInputContainer
        v-model="item.city_of_residence"
        :errors="errors.city_of_residence"
        field-type="string"
        label="City of residence"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.country_of_residence"
        :errors="errors.country_of_residence"
        field-type="select"
        :options="COUNTRY_CHOICES"
        label="Country of residence"
        reverse-field="code"
        placeholder=""
        disabled
      />
      <!-- :reverse-field="$store.state.choices.specialChoices['COUNTRY_CHOICES'].reverseField"
      :display-field="$store.state.choices.specialChoices['COUNTRY_CHOICES'].displayField" -->
      <!-- <BaseInputContainer
        v-model="item.gallery_set"
        :errors="errors.gallery_set"
        field-type="multiselect"
        placeholder="Select a gallery..."
        label="Represented by"
        reverse-field="id"
        :required="availableFields.gallery_set.required"
        :data-source="$TapGallery.gallery"
        @update="onFieldUpdate"
        @input="onFieldInput"
      /> -->
      <!-- :options="galleryChoices" -->
      <!-- <slot name="controls" /> -->
    </section>
    <section>
      <BaseInputContainer
        v-model="item.portrait"
        field-type="image"
        label="Portrait"
        help-text="Portrait for this artist."
        ratio-type="portrait"
        :disabled-resize="false"
        :errors="errors.portrait"
        disabled
      />
    </section>
    <section>
      <BaseInputContainer
        v-model="item.copyright"
        field-type="string"
        label="Copyright"
        help-text="Copyright/credits for portrait."
        :errors="errors.copyright"
        disabled
      />
      <!-- <slot name="controls" /> -->
    </section>
    <section>
      <h2>Public informations</h2>
      <BaseInputContainer
        v-model="item.bio"
        field-type="rte"
        label="Artist bio"
        :errors="errors.bio"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.tag_set"
        :errors="errors.tag_set"
        field-type="multiselect"
        placeholder=""
        label="Tags"
        reverse-field="id"
        :data-source="$TapGallery.tag"
        disabled
      />
      <BaseInputContainer
        v-model="item.website"
        field-type="url"
        label="Artist website"
        :errors="errors.website"
        placeholder=""
        disabled
      />
      <BaseInputContainer
        v-model="item.wikipedia"
        field-type="url"
        label="Wikipedia page"
        :errors="errors.wikipedia"
        placeholder=""
        disabled
      />
      <SocialNetworkForm
        :item="item.social_network"
        :errors="errors.social_network"
        disabled
      />
      <BaseInputContainer
        v-model="item.related_artist_set"
        :errors="errors.related_artist_set"
        label="Related artists"
        help-text="Artists related to this artist"
        field-type="multiselect"
        reverse-field="id"
        :data-source="$TapGallery.artist"
        placeholder=""
        disabled
      />
      <!-- <slot name="controls" /> -->
    </section>
    <section>
      <!-- <h2>Pledge</h2>
      <BaseInputContainer
        v-model="item.cause_set"
        :errors="errors.cause_set"
        field-type="multiselect"
        placeholder="Select a cause..."
        label="Causes"
        reverse-field="id"
        :options="CAUSE_CHOICES"
        :required="availableFields.cause_set.required"
        @update="onFieldUpdate"
        @input="onFieldInput"
      /> -->
      <BaseInputContainer
        v-model="item.project_set"
        :errors="errors.project_set"
        field-type="multiselect"
        placeholder=""
        label="Projects involving artist"
        reverse-field="id"
        :data-source="$TapGallery.project"
        disabled
      />
      <BaseInputContainer
        v-model="item.pledge_text"
        :errors="errors.pledge_text"
        field-type="longstring"
        placeholder=""
        label="Pledge Text"
        :max-length="availableFields.pledge_text.max_length"
        disabled
      />
      <BaseInputContainer
        v-model="item.pledge_video"
        field-type="url"
        label="Pledge video"
        :errors="errors.pledge_video"
        placeholder=""
        disabled
      />
      <!-- <slot name="controls" /> -->
    </section>
    <!-- <section>
      <h2>CV</h2>
      <SetEditor
        :item="item.curriculumentry_set"
        :errors="errors.curriculumentry_set"
        label="Curriculum"
        set-model="curriculumentry_set"
        @input="onFieldInput"
        @update="onFieldUpdate"
        v-on="$listeners"
        @dirtysubform="onDirtySubform"
      >
        <template slot="controls">
          <slot name="controls" />
        </template>
      </SetEditor>
    </section> -->
  </div>
</template>

<script>
// Mixin
import { modelForms } from '@/mixins/modelForms.js'
// Components
// import SetEditor from '@/components/SetEditor'
// import VisibilityForm from '@/components/VisibilityForm'
import SocialNetworkForm from '@/components/SocialNetworkForm'

export default {
  name: 'ArtistForm',
  components: {
    // SetEditor,
    // VisibilityForm,
    SocialNetworkForm,
  },
  mixins: [modelForms],
  computed: {},
  methods: {},
}
</script>

<style src="../../assets/css/forms.css" scoped></style>
<style scoped>
</style>
