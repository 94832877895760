import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['event', 'events'],
  modelName: 'event',
}

const mutations = {
  ...baseStore.mutations
}

const actions = {
  ...baseStore.actions
}

const getters = {
  ...baseStore.getters,
  typeChoices: function (state){
    if (!state.fieldsReady) return []
    return state.availableFields.type.choices
  },
  // dateChoices: function (state){
  //   if (!state.fieldsReady) return []
  //   return state.availableFilterFields.date.choices
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
