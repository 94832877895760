const defaultOptions = {
  timeout: 1000,
  headers: {
    common: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
}

export default defaultOptions
