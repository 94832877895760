<template>
  <div
    :class="[
      'base-follow-button', 
      { active: isFollowed }
    ]"
    @click="onClick"
  >
    <svg
      id="heart-icon"
      viewBox="0 0 24 24"
    >
      <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BaseFollowButton',
  props: {
    isFollowed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      mouseOver: false,
    }
  },
  mounted: function () {},
  methods: {
    onClick: function () {
      if (!this.disabled) {
        this.$emit('click')
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-follow-button {
  --default-width: 55px;
  height: var(--default-width);
  width: var(--default-width);
  border: var(--default-ruler);
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
}
.base-follow-button.active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white);
}
.base-follow-button:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}
.base-follow-button svg{
  width: calc(var(--default-width) / 2);
}
#heart-icon{
  fill: none;
  stroke: var(--primary-color);
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: var(--default-transition);
}
.base-follow-button:hover #heart-icon{
  stroke: #fff;
}
.base-follow-button.active #heart-icon{
  fill: #fff;
}
</style>
