<!-- 
  <BasePartnerLogo partnerName="name">
-->
<template>
  <div
    class="partner"
    @click="onClick"
  >
    <img
      v-if="partner == 'arta'"
      src="../../assets/img/partners/arta-small.png"
    >
    <img
      v-else-if="partner == 'global giving'"
      src="../../assets/img/partners/globalgiving.png"
    >
    <img
      v-else-if="partner == 'transnational giving europe'"
      src="../../assets/img/partners/tge.png"
    >
    <img
      v-else-if="partner == 'avalara'"
      src="../../assets/img/partners/avalara.png"
    >
    <img
      v-else-if="partner == 'arcarta'"
      src="../../assets/img/partners/arcarta.svg"
    >
    <img
      v-else-if="partner == 'stripe'"
      src="../../assets/img/partners/stripe.png"
    >
    <img
      v-else-if="partner == 'factiva'"
      src="../../assets/img/partners/factiva.jpg"
    >
    <img
      v-else-if="partner == 'artfacts'"
      src="../../assets/img/partners/artfacts.png"
    >
    <img
      v-else-if="partner == 'the carbon accounting company' || partner == 'tcac'"
      class="bg"
      src="../../assets/img/partners/tcac.png"
    >
    <img
      v-else-if="partner == 'klarna'"
      class="bg"
      src="../../assets/img/partners/klarna.png"
    >
    <!-- OR moins htmlesque -->
    <!-- <img
      :src="imgDetails.src"
      :class="imgDetails.classes"
    > -->
    <div v-else>
      {{ partner }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePartnerLogo',
  props: {
    partnerName: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    partner: function () {
      return this.partnerName.toLowerCase()
    }
    /* autre version moins htmlesque */
    // imgDetails: function () {
    //   const partnerName = this.partnerName.toLowerCase()
    //   const imgSrcMap = [
    //     {
    //       partner: 'global giving',
    //       src: '../../assets/img/partners/globalgiving.png',
    //     },
    //     {
    //       partner: 'transnational giving europe',
    //       src: '../../assets/img/partners/tge.png',
    //     },
    //     {
    //       partner: 'the carbon accounting company',
    //       src: '../../assets/img/partners/tcac.png',
    //       classes: ['bg'],
    //     },
    //   ]
    //   return imgSrcMap.find((img) => img.partner == partnerName) || null
    // },
  },
  methods: {
    onClick: function () {
      this.$emit('click')
    },
  },
}
</script>

<style scoped>
.partner img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}
/* utilisé depuis l'extérieur par PurchaseRow; pas bon */
/* TODO: fix */
.inline {
  display: inline;
}
.inline img {
  height: 1em;
}
</style>
