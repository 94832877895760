<template>
  <article :class="['base-facette-result', ...styles]">
    <div class="image">
      <template v-if="imageSrc">
        <img :src="imageSrc">
      </template>
      <template v-else>
        <div class="nopic" />
      </template>
    </div>
    <div class="details">
      <header>
        <h1>{{ title }}</h1>
      </header>
      <main>
        <p>{{ shortText | truncate(200, '...') }}</p>
      </main>
      <footer>
        <a :href="link">See more</a>
      </footer>
    </div>
  </article>
</template>

<script>
export default {
  name: 'BaseFacetteResult',
  props: {
    styles: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    imageSrc: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    shortText: {
      type: String,
      required: false,
      default: ''
    },
    link: {
      type: String,
      required: false,
      default: "/#"
    },
    maxLength : {
      type: Number,
      required: false,
      default: 200
    }
  },
  data: function () {
    return {};
  },
  mounted: function(){},
  methods: {},
};
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.base-facette-result{
  width: 500px;
  height: 300px;
  border: 1px solid #000;
  display: flex;
}
.image{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.details{
  width: 50%;
}
</style>
