import Vue from 'vue'
import baseStore from './baseStore'

const state = {
  ...baseStore.state,
  modelDisplayNames: ['gallery', 'galleries'],
  modelName: 'gallery',
  myGallery: undefined,
}

const mutations = {
  ...baseStore.mutations,
  setMyGallery: function(state, value) {
    state.myGallery = value
  },
}

const actions = {
  ...baseStore.actions,
  initStore: async function({ commit, dispatch }, tapApi) {
    commit('setTapApi', tapApi)
    // await dispatch('setMyGallery')
    await state.endpoint.search()
      .then((response)=>{
        commit('setMyGallery', response.data.results[0])
      })
      // .then(()=>{
    return dispatch('populateAvailableFields')
      // })
  },
  // setMyGallery: async function({commit}){
  //   return 
  // },
}

const getters = {
  ...baseStore.getters,
  /* NO CHOICES PROVIDED BY API 14/09/21 */
  cityChoices: function (state){
    if (!state.fieldsReady) return []
    return state.availableFilterFields.city.choices
  },
  regionChoices: function (state){
    if (!state.fieldsReady) return []
    return state.availableFilterFields.region.choices
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
