<template>
  <section 
    :class="[
      'set-editor',
      {'fold': fold},
      {'no-fold': !fold}
    ]"
  >
    <header class="set-header">
      <h2>{{ label }}</h2>
      <div class="toolbar">
        <a
          v-if="setDetails.canAdd"
          class="set-add"
          @click="addSetElement"
        >
          + Add {{ setDetails.name }}
        </a>
        <div 
          class="fold-button"
          :title="fold?'Click to expand':'Click to fold this section'"
          @click="fold = !fold"
        >
          <img
            v-if="fold"
            src="../base/assets/img/svgicons/maximize-2.svg"
          >
          <img
            v-else
            src="../base/assets/img/svgicons/minimize-2.svg"
          >
        </div>
      </div>
    </header>
    <template v-for="(el, elIdx) in item">
      <div
        :key="'setel' + elIdx"
        :class="['set-element foldable-content', { highlight: mouseOver === elIdx }]"
      >
        <h3 :key="'h3' + elIdx">
          {{ setDetails.name }} #{{ elIdx + 1 }}
          <template v-if="el.name">
            - {{ el.name }}
          </template>
        </h3>
        <Form
          :is="setDetails.form"
          :key="'address' + elIdx"
          :item="el"
          :errors="errors && errors[elIdx]"
          :disabled="disabled"
          v-on="$listeners"
        />
        <a
          v-if="setDetails.canDelete"
          :key="'setd' + elIdx"
          class="set-delete-element"
          @mouseenter="mouseOver = elIdx"
          @mouseleave="mouseOver = false"
          @click="removeSetElement(elIdx)"
        >
          Delete {{ setDetails.name }}
        </a>
      </div>
    </template>
    <slot name="controls" />
  </section>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'

import AddressForm from '@/components/AddressForm'
// import CO2CompensationForm from '@/components/CO2CompensationForm'
import CurriculumEntryForm from '@/components/artist/CurriculumEntryForm'
import MediaForm from '@/components/MediaForm'
// import PayoutAccountsForm from '@/components/gallery/PayoutAccountsForm'
import SpaceForm from '@/components/gallery/SpaceForm'
import TaxReceiptForm from '@/components/gallery/TaxReceiptForm'

export default {
  name: 'SetEditor',
  mixin: [modelForms],
  components: {
    AddressForm,
    // CO2CompensationForm,
    CurriculumEntryForm,
    MediaForm,
    // PayoutAccountsForm,
    SpaceForm,
  },
  props: {
    item: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
    setModel: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    errors: {
      type: [Array, Object],
      required: false,
      default: () => {
        return null
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    canAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    canDelete: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      mouseOver: false,
      fold: true,
    }
  },
  computed: {
    setDetails: function () {
      const setMapping = {
        address_set: {
          name: 'address',
          pluralName: 'addresses',
          canAdd: true,
          canDelete: true,
          form: AddressForm,
        },
        space_set: {
          name: 'space',
          pluralName: 'spaces',
          canAdd: this.canAdd,
          canDelete: this.canDelete,
          form: SpaceForm,
        },
        media_set: {
          name: 'media',
          pluralName: 'medias',
          canAdd: this.canAdd,
          canDelete: this.canDelete,
          form: MediaForm,
        },
        curriculumentry_set: {
          name: 'curriculum entry',
          pluralName: 'curriculum entries',
          canAdd: this.canAdd,
          canDelete: this.canDelete,
          form: CurriculumEntryForm,
        },
        gallery_to_artist: {
          name: 'Tax Receipt',
          canAdd: false,
          canDelete: false,
          form: TaxReceiptForm,
        },
        // co2_compensation_set: {
        //   name: 'CO₂ Compensation project',
        //   canAdd: false,
        //   canDelete: false,
        //   form: CO2CompensationForm,
        // },
        // bank_account_set: {
        //   name: 'Bank Account',
        //   canAdd: true,
        //   canDelete: true,
        //   form: PayoutAccountsForm,
        // },
      }
      return setMapping[this.setModel]
    },
  },
  mounted: function () {
    // console.log(this.$attrs)
  },
  methods: {
    addSetElement: function () {
      this.fold = false
      this.item.push({})
      this.$emit('dirtysubform')
      // item.space_set.push({})
    },
    removeSetElement: function (elIdx) {
      this.item.splice(elIdx, 1)
      this.$emit('dirtysubform')
    },
  },
}
</script>

<style src="../assets/css/forms.css" scoped></style>
<style scooped> 
@keyframes fold{
  0%{ height: auto; }
  1%{ height: 800px; }
  100%{ height: 0px; }
}
@keyframes unfold{
  0%{ height: 0px; }
  99%{ height: 800px; }
  100%{ height: auto; }
}
.fold-button{
  /* margin: 0 10px; */
  cursor: pointer;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fold-button img{
  width: 20px;
}
.fold-button:hover{
  filter: invert(1);
  box-shadow: 0px 0px 5px #707070;
}
.foldable-content{
  overflow: hidden;
}
.fold .foldable-content{
  margin: 0px;
  animation: .1s fold ease-in-out forwards;
}
.no-fold .foldable-content{
  animation: .1s unfold ease-in-out forwards;
  overflow: visible;
}
.set-header .toolbar {
  display: flex;
  width: 30%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}
/* handles foldable header bottom margin */
.form section .set-editor .set-header h2{
  margin-bottom: 0px;
}
.form section .set-editor.no-fold .set-header{
  margin-bottom: var(--margin-unit);
}

</style>
