<!--
Simple progress bar
props:
- ratio: a number between 0 and 100 (percentage)

TODO:
- add at least one story in storybook (mandatory)
- add an extra 'styles' prop to change background/foreground color/height (optional)
- add an extra 'explicit' prop to display the number inside the bar (optional)
 -->
<template>
  <div class="progressbar-background">
    <div
      class="progressbar-bar"
      :style="barStyle"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseProgressBar',
  props: {
    ratio: {
      type: Number,
      required: true,
      default: 0,
    }
  },
  computed: {
    barStyle: function () {
      return {
	width: `${this.ratio}%`
      }
    }
  }
}
</script>

<style scoped>
.progressbar-background {
  width: 100%;
  height: 10px;
  background-color: var(--flat-color);
}
.progressbar-bar {
  height: 100%;
  background-color: var(--primary-color);
}
</style>
