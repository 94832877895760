<template>
  <div class="filters">
    <!-- ----------- FILTER FIELDS ---------- -->
    <form
      :is="filtersComponent"
      ref="filtersComponent"
      :filter-values="filterValues"
      @input="onFilterInput"
    />
    <!-- ----------- FILTER CONTROLS ---------- -->
    <div
      v-if="displayableFilters.length > 0"
      class="filter-controls"
    >
      <!-- Applied filters -->
      <div class="applied-filters">
        <div
          v-for="(filt, fIdx) in displayableFilters"
          :key="'af'+fIdx"
          class="applied-filter-label-container"
        >
          <img
            class="remove-cross"
            src="../base/assets/img/svgicons/x.svg"
            @click="removeFilter(filt)"
          >
          <div class="filter-display">
            <span class="filter-key">
              {{ filt.key.replaceAll("_", " ") }} :
            </span>
            <span class="filter-value">
              <template v-if="filt.display.getMonth">
                {{ filt.display | formatDateTime('SHORT_DATE') }}
              </template>
              <template v-else>
                {{ filt.display }}
              </template>
            </span>
          </div>
        </div>
      </div>
      <!-- filters management: clear or apply filters-->
      <div class="filters-management">
        <div
          class="remove-all"
          @click="resetSearch"
        >
          <img
            class="remove-cross"
            src="../base/assets/img/svgicons/x.svg"
          >
          Clear All Filters
        </div>
        <div class="right-buttons">
          <BaseButton
            label="Reset search"
            :disabled="isSearching"
            @click="resetSearch"
          />
          <BaseButton
            :styles="['CTA']"
            :disabled="isSearching"
            label="Search"
            @click="onFilterInput"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ArtistFilters from '@/components/artist/ArtistFilters';
import ArtworkFilters from '@/components/artwork/ArtworkFilters';
import TransactionFilters from '@/components/transaction/TransactionFilters'

/* debouncing search by waiting for input completion 
before emitting search request. This delay in milliseconds */
const DEBOUNCE_DELAY = 1000 

export default {
  name: 'FiltersModule',
  components: {
    ArtistFilters,
    ArtworkFilters,
    TransactionFilters,
  },
  props:{
    modelName: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function (){
    return {
      filterValues: {},
      debounceTimeout: {},
    }
  },
  computed:{
    filtersComponent: function() {
      const filtersMap = {
        // default types
        artist:       'ArtistFilters',
        artwork:      'ArtworkFilters',
        transaction: 'TransactionFilters',
      };
      if (Object.keys(filtersMap).includes(this.modelName)) {
        return filtersMap[this.modelName];
      } else {
        console.debug('⚠ - No FILTER component for this model.', this.modelName)
        return null;
      }
    },
    appliedFilters: function (){
      return this.$store.state[this.modelName].appliedFilters
    },
    isSearching: function(){
      return !this.$store.state[this.modelName].resultsReady
    },
    availableFilterFields: function(){
      return this.$store.state[this.modelName].availableFilterFields
    },
    /**
     * Computes human-readable display values 
     * for applied search query filters.
     * Relies on store/choices/getChoiceDisplay for
     * fieldname-value mappings.
     */
    displayableFilters: function () {
      /**
       * Adds the computed human-readable filter display value
       * to the displayable filters array.
       */
      function pushValue(df, fk, fv, getters) {
        let tryChoiceDisplay = getters['choices/getChoiceDisplay']([fk, fv])
        if (tryChoiceDisplay) {
          tryChoiceDisplay = tryChoiceDisplay.display_name
        }
        df.push({
          key: fk, 
          value: fv, 
          display: tryChoiceDisplay || fv 
        })
      }
      /* maps filter's values to human-readable display values */
      const af = this.appliedFilters
      const afKeys = Object.keys(this.appliedFilters)
      const df = []
      afKeys.forEach((fk) => {
        let display = af[fk]
        if (Array.isArray(display)) {
          display.forEach((fv) => {
            pushValue(df, fk, fv, this.$store.getters)
          })
        } else {
          pushValue(df, fk, display, this.$store.getters)
        }
      })
      return df
    },
  },
  watch: {
    modelName: function(){
      // this.filterValues = {}
      this.filterValues = {...this.appliedFilters}
    },
    appliedFilters: function () {
      this.filterValues = {...this.appliedFilters}
    },

  },
  methods: {
    /**
     * Updating search on each filter field interaction
     */
    onFilterInput: function () {
      if (!this.isSearching){
        this.updateFilters()
        this.goSearch()
      } else {
        if (this.debounceTimeout) clearInterval(this.debounceTimeout)
        this.debounceTimeout = setTimeout(this.onFilterInput, DEBOUNCE_DELAY)
      }
    },
    /**
     * Do the actual customized search
     */
    goSearch: function (){
      this.$store.dispatch(this.modelName+'/search')
    },
    /**
     * Parses filterValues and availableFilterFields
     * and decides the kind of filter query it should create.
     */
    updateFilters: function (){
      Object.keys(this.filterValues).forEach(fk=>{
        if (this.filterValues[fk] === null){
          this.$delete(this.filterValues, fk)
        }
      })
      this.$store.commit(this.modelName + '/updateAppliedFilters', this.filterValues)
    },
    removeFilter: function(filt){
      let nfs = {}
      Object.keys(this.filterValues).forEach(fk=>{
        let fv = this.filterValues[fk]
        if (filt.key != fk){
          nfs = {...nfs, [fk]: fv}
        } else {
          let nfv = null 
          if (Array.isArray(fv)){
            if (typeof fv[0] == 'string') {
              nfv = fv.filter(fval=>fval != filt.value) || null
            } else {
              nfv = fv.filter(fval=>Object.values(fval)[0] != Object.values(filt.value)[0]) || null
            }
            if (nfv) nfs = {...nfs, [fk]: [...nfv]}
          } else {
            console.log('else', filt, fk, fv)
            if (fv != filt.value && fv != null){
              nfs = {...nfs, [fk]: fv}
            }
          }
        }
      })
      this.filterValues = {...nfs}
      this.updateFilters()
      this.goSearch()
    },
    resetSearch: function(){
      // this.filterValues = {}
      this.$store.commit(this.modelName + '/resetFilters')
      this.goSearch()
    }
  }
}
</script>

<style scoped>
.filters{
  border: var(--default-admin-border);
  padding: 15px;
  /* padding-right: 0px; */
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  --margin: 10px;
  margin-right: calc(-1 * var(--margin));
}
.applied-filter-label-container{
  box-sizing: border-box;
  height: var(--option-tag-height);
  background-color: var(--grey-medium);
  display: flex;
  align-items: center;
  margin-right: var(--margin);
  margin-top: var(--margin);
  padding: 5px 15px 5px 10px;
  /* max-width: 150px; */
  cursor: default;
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  /* text-transform: capitalize; */
  /* max-width: 100%; */
}
.remove-cross{
  height: 14px;
  margin-right: 6px;
  cursor: alias;
}

.applied-filters{
  /* max-width: 100%; */
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.filters-management{
  display: flex;
  justify-content: space-between;
  border-top: var(--default-admin-border);
  padding-top: 20px;
}
.filter-controls{
  /* padding-right: 40px; */
}
.filter-key{
  font-family: 'Rubik Medium';
}
.right-buttons{
  /* width: 262px; */
  display: flex;
  justify-content: space-between;
}
.remove-all{
  display: flex;
  align-items: center;
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  text-decoration: underline;
  white-space: nowrap;
  cursor: pointer;
}
.remove-all:hover{
  background-color: #dbdbdb;
}

</style>
