export const defaultRequestInterceptor = {
  configInterceptor: function (config) {
    return config
  },
  errorInterceptor: function (error) {
    console.error('API request error', error)
  }
}

export const defaultResponseInterceptor = {
  responseInterceptor: function (response) {
    return response
  },
  errorInterceptor: function (error) {
    console.error('API response error', error)
    return Promise.reject(error)
  }
}
