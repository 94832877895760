<!-- ___ _  ___   _____ ___ ___   _   _      ___ _____ _ _____ ___ 
    | _ \ || \ \ / / __|_ _/ __| /_\ | |    / __|_   _/_\_   _| __|
    |  _/ __ |\ V /\__ \| | (__ / _ \| |__  \__ \ | |/ _ \| | | _| 
    |_| |_||_| |_| |___/___\___/_/ \_\____| |___/ |_/_/ \_\_| |___|
                                                                    -->
<template>
  <section v-if="item">
    <section>
      <BaseInputContainer
        v-model="item.fragility"
        :errors="localErrors.fragility"
        :required="fields.fragility.required"
        :options="FRAGILITY_CHOICES"
        field-type="select"
        label="Fragility"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.packing"
        field-type="select"
        :errors="localErrors.packing"
        :required="fields.packing.required"
        :options="DELIVERY_PACKING_CHOICES"
        label="Packing"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
      <BaseInputContainer
        v-model="item.object_type"
        field-type="select"
        :errors="localErrors.object_type"
        :required="fields.object_type.required"
        :options="DELIVERY_OBJECT_TYPE_CHOICES"
        label="Object type"
        @update="onFieldUpdate"
        @input="onFieldInput"
      />
    </section>
    <section>
      <h3>Artwork dimensions and weight</h3>
      <h4>SI units</h4>
      <BaseInputContainer
        ref="height_cm"
        v-model="item.height_cm"
        :errors="localErrors.height_cm"
        :required="fields.height_cm.required"
        field-type="float"
        label="Height (cm)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'height_cm')"
      />
      <BaseInputContainer
        ref="length_cm"
        v-model="item.length_cm"
        :errors="localErrors.length_cm"
        :required="fields.length_cm.required"
        field-type="float"
        label="length (cm)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'length_cm')"
      />
      <BaseInputContainer
        ref="width_cm"
        v-model="item.width_cm"
        :errors="localErrors.width_cm"
        :required="fields.width_cm.required"
        field-type="float"
        label="Width (cm)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'width_cm')"
      />
      <BaseInputContainer
        ref="weight_kg"
        v-model="item.weight_kg"
        :errors="localErrors.weight_kg"
        :required="fields.weight_kg.required"
        field-type="float"
        label="Weight (kg)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'weight_kg')"
      />
    </section>
    <section>
      <h4>Imperial units</h4>
      <BaseInputContainer
        ref="height_in"
        v-model="item.height_in"
        :errors="localErrors.height_in"
        :required="fields.height_in.required"
        field-type="string"
        label="Height (in)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'height_in')"
      />
      <BaseInputContainer
        ref="length_in"
        v-model="item.length_in"
        :errors="localErrors.length_in"
        :required="fields.length_in.required"
        field-type="string"
        label="length (in)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'length_in')"
      />
      <BaseInputContainer
        ref="width_in"
        v-model="item.width_in"
        :errors="localErrors.width_in"
        :required="fields.width_in.required"
        field-type="string"
        label="Width (in)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'width_in')"
      />
      <BaseInputContainer
        ref="weight_lbs"
        v-model="item.weight_lbs"
        :errors="localErrors.weight_lbs"
        :required="fields.weight_lbs.required"
        field-type="float"
        label="Weight (lbs)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'weight_lbs')"
      />
    </section>
    <!-- packed -->
    <section>
      <h3>Package dimensions and weight</h3>
      <h4>SI units</h4>
      <BaseInputContainer
        ref="height_cm"
        v-model="item.shipping_height_cm"
        :errors="localErrors.shipping_height_cm"
        :required="fields.shipping_height_cm.required"
        field-type="float"
        label="Height (cm)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_height_cm')"
      />
      <BaseInputContainer
        ref="length_cm"
        v-model="item.shipping_length_cm"
        :errors="localErrors.shipping_length_cm"
        :required="fields.shipping_length_cm.required"
        field-type="float"
        label="length (cm)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_length_cm')"
      />
      <BaseInputContainer
        ref="width_cm"
        v-model="item.shipping_width_cm"
        :errors="localErrors.shipping_width_cm"
        :required="fields.shipping_width_cm.required"
        field-type="float"
        label="Width (cm)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_width_cm')"
      />
      <BaseInputContainer
        ref="weight_kg"
        v-model="item.shipping_weight_kg"
        :errors="localErrors.shipping_weight_kg"
        :required="fields.shipping_weight_kg.required"
        field-type="float"
        label="Weight including artwork (kg)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_weight_kg')"
      />
    </section>
    <section>
      <h4>Imperial units</h4>
      <BaseInputContainer
        ref="height_in"
        v-model="item.shipping_height_in"
        :errors="localErrors.shipping_height_in"
        :required="fields.shipping_height_in.required"
        field-type="string"
        label="Height (in)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_height_in')"
      />
      <BaseInputContainer
        ref="length_in"
        v-model="item.shipping_length_in"
        :errors="localErrors.shipping_length_in"
        :required="fields.shipping_length_in.required"
        field-type="string"
        label="length (in)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_length_in')"
      />
      <BaseInputContainer
        ref="width_in"
        v-model="item.shipping_width_in"
        :errors="localErrors.shipping_width_in"
        :required="fields.shipping_width_in.required"
        field-type="string"
        label="Width (in)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_width_in')"
      />
      <BaseInputContainer
        ref="weight_lbs"
        v-model="item.shipping_weight_lbs"
        :errors="localErrors.shipping_weight_lbs"
        :required="fields.shipping_weight_lbs.required"
        field-type="float"
        label="Weight including artwork (lbs)"
        @update="onFieldUpdate"
        @input="onDimFieldInput($event, 'shipping_weight_lbs')"
      />
    </section>
  </section>
</template>

<script>
import { modelForms } from '@/mixins/modelForms.js'

export default {
  name: 'PhysicalStateForm',
  mixins: [modelForms],
  computed: {
    localErrors: function () {
      if (this.errors && this.errors.physical_state) {
        return this.errors.physical_state
      } else {
        return {}
      }
    },
    fields: function () {
      return this.$parent.availableFields.physical_state.children
    },
  },
  methods: {
    onDimFieldInput: function (value, fieldName, c) {
      // suppose conversion is from cm to inches
      let converter = this.$options.filters.cmToInch
      let target = fieldName.replace('_cm', '_in')
      // if conversion is from inches to cm
      if (fieldName.includes('_in')) {
        converter = this.$options.filters.inchToCm
        target = fieldName.replace('_in', '_cm')
      }
      // if conversion is from lbs to kg
      if (fieldName.includes('_lbs')) {
        converter = function (value) {
          return parseFloat(Number(value * 0.453592).toFixed(2))
        }
        target = fieldName.replace('_lbs', '_kg')
      }
      // if conversion is from kg to lbs
      if (fieldName.includes('_kg')) {
        converter = function (value) {
          return parseFloat(Number(value * 2.20462).toFixed(2))
        }
        target = fieldName.replace('_kg', '_lbs')
      }
      // convert and set
      this.$set(this.$parent.item.physical_state, target, converter(value))
      // update value
      this.onFieldInput()
    },
  },
}
</script>

<style src="@/assets/css/forms.css" scoped></style>
<style scoped>
</style>
