<template>
  <div class="redirection-view">
    <section class="process-section">
      <!-- STRIPE CONNECT ONBOARDING PROCESS -->
      <template v-if="accountProcess == 'stripe_connect_onboarding_complete'">
        <header class="reset">
          <h1 class="title-level-3">
            Stripe Connect
          </h1>
          <div
            v-if="ok=='1'"
            class="text-cdt"
          >
            <p>Thank you for following the onboarding process.</p>
            <p>Your account is now ready to receive payments.</p>
          </div>
          <div
            v-else
            class="text-cdt"
          >
            <p>There was an error during Stripe Connect onboarding.</p>
            <p>Your onboarding process is not complete.</p>
            <p>
              Please logout then login again to generate a new Stripe Connect link.
            </p>
            <BaseButton
              label="Logout"
              @click="onLogoutButtonClick"
            />
          </div>
        </header>
      </template>
      <!-- TAP PASSWORD RESET PROCESS -->
      <template v-if="['password_reset', 'account_activation'].includes(accountProcess)">
        <template v-if="actionCompleted">
          <!-- ---- account_activation final step ---- -->
          <template v-if="accountProcess == 'account_activation'">
            <header class="welcome">
              <h1 class="title-level-4">
                Welcome to&nbsp;
                <BaseLogo />
              </h1>
              <span class="text-cdt">
                Your account has been activated. You can connect
                <router-link :to="{ name: 'Login' }">here</router-link>.
              </span>
            </header>
          </template>
          <!-- ---- password_reset final step ---- -->
          <template v-if="accountProcess == 'password_reset'">
            <header class="welcome">
              <h1 class="title-level-4">
                Password changed
              </h1>
              <span class="text-cdt">
                Your password has been changed. You can connect
                <router-link :to="{ name: 'Login' }">here</router-link>.
              </span>
            </header>
          </template>
        </template>
        <!-- password reset form -->
        <template v-else>
          <header class="reset">
            <!-- ACCOUND activation  -->
            <template v-if="accountProcess == 'account_activation'">
              <h1 class="title-level-3">
                Create a password
              </h1>
              <BaseNonFieldErrors
                v-if="errors"
                :errors="errors"
              />
              <span class="text-cdt">
                Please enter your new password twice to activate your account.
              </span>
            </template>
            <!-- PASSWORD RESET -->
            <template v-if="accountProcess == 'password_reset'">
              <h1 class="title-level-3">
                Reset password
              </h1>
              <BaseNonFieldErrors
                v-if="errors"
                :errors="errors"
              />
              <span class="text-cdt">
                Please enter your new password twice:
              </span>
            </template>            
          </header>
          <form autocomplete="on">
            <BaseInputContainer
              v-model="new_password1"
              field-type="password"
              label="New password"
              required
              :strength="newPasswordStrength"
              :errors="errors ? errors.new_password1 : null"
              @input="checkPasswordStrength"
            />
            <BaseInputContainer
              v-model="new_password2"
              field-type="password"
              :errors="errors ? errors.new_password2 : null"
              required
              label="Confirm password"
            />
            <div id="buttonCategory">
              <BaseButton
                label="Create password"
                @click="onResetPasswordConfirmClick"
              />
            </div>
          </form>
        </template>
      </template>
      <!-- ERROR : PROCESS IS NOT RECOGNIZED -->
      <template v-if="!availableActions.includes(accountProcess)">
        <header>
          <h1 class="title-level-3">
            This action is not available.
          </h1>
          <span class="text-cdt">
            An error occurred.
          </span>
        </header>
      </template>
    </section>
  </div>
</template>

<script>
const AVAILABLE_ACTIONS = [
  'password_reset',                     // password reset
  'stripe_connect_onboarding_complete', // return view after stripe onboarding
  'account_activation',                 // first-time seller activation
]

export default {
  name: 'AccountRedirection',
  components: {},
  props: {
    accountProcess: {
      type: String,
      required: true,
      default: '',
    },
    tk1: {
      type: String,
      required: true,
      default: '',
    },
    tkd: {
      type: String,
      required: false,
      default: '',
    },
    tk2: {
      type: String,
      required: false,
      default: '',
    },    
    token: {
      type: String,
      required: false,
      default: '',
    },
    ok: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  data: function () {
    return {
      actionCompleted: false,
      availableActions: AVAILABLE_ACTIONS,
      errors: null,
      newPasswordStrength: -1,
      new_password1: '',
      new_password2: '',
    }
  },
  computed: {},
  mounted: function () {
    if (this.accountProcess == 'account_activation'){
      document.title = 'TheArtPledge | Activate your seller account'
    }
    if (this.accountProcess == 'password_reset') {
      document.title = 'TheArtPledge | Reset your password'
    }
    // if (this.accountProcess == 'stripe_connect') {
    //   // Send request to API/admin/account/seller/
    //   // to check if account is OK or get new link
    //   // If HTTP status is 208, account is really valid
    //   // Else, the response contains the account_link structure
    //   this.$TAP.system.stripeConnect().then((response) => {
    //     if (response.status == 208) {
    //       this.actionCompleted = true
    //     } else {
    //       this.accountLink = response.data
    //     }
    //   })
    // }
  },
  methods: {
    checkPasswordStrength: function (value){
      this.$TapGallery.auth
        .getPasswordStrength(value)
        .then((response)=>{
          this.newPasswordStrength = Number(response.data.score)
        })
    },
    onResetPasswordConfirmClick: function () {
      if (this.accountProcess == 'account_activation'){
        const credentials = {
          new_password1: this.new_password1,
          new_password2: this.new_password2,
          tk1: this.tk1,
          tkd: this.tkd,
        }
        this.$TapGallery.auth
          .validateAccount(credentials)
          .then((r) => {
            this.actionCompleted = true
          })
          .catch((err) => {
            this.errors = { ...err.response.data }
          })
      }
      if (this.accountProcess == 'password_reset'){
        const credentials = {
          new_password1: this.new_password1,
          new_password2: this.new_password2,
          tk1: this.tk1,
          tk2: this.tk2,
          token: this.token
        }
        this.$TapGallery.auth
          .confirmPasswordReset(credentials)
          .then((r) => {
            this.actionCompleted = true
          })
          .catch((err) => {
            this.errors = { ...err.response.data }
          })
      }
    },
    onLogoutButtonClick: function () {
      this.$store.dispatch('user/logout').then((r) => {
        this.$router.push({ path: '/login' })
      })
    },
  },
}
</script>
<style scoped>
.process-section {
  text-align: center;
  padding: 20vh 0;
}
.process-section h1 {
  text-align: center;
  margin-bottom: 30px;
}
.welcome h1 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.welcome a,
.reset a {
  text-decoration: underline;
}
.welcome >>> .base-logo {
  width: 200px;
}
.process-section form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: var(--field-h-margin) auto;
  max-width: 600px;
}
.errors {
  margin: var(--field-h-margin) auto;
}
.base-input-container,
.base-button-container {
  margin-bottom: var(--field-v-margin);
}
#buttonCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
