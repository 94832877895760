<template>
  <!-- MOBILE VIEW -->
  <div
    v-if="smallScreen"
    class="model-small-container"
  >
    <div class="small-thumb-container">
      <figure
        class="picture-container"
      > 
        <img
          v-if="object.media_set && object.media_set.length > 0"
          :src="object.media_set[0].picture.thumbnail"
          class="thumb"
        >
        <div
          v-else
          class="empty-thumb"
        />
      </figure>
    </div>
    <div class="small-info">
      <div class="small-artist-name">
        <router-link :to="artistLink">
          {{ object.artist.name }}
        </router-link>
        <p>
          <span class="title">{{ object.title }}</span>, {{ object.year }}
        </p>
      </div>
      <div>
        <p>{{ status }} &mdash; {{ published }}</p>
      </div>
    </div>
  </div>

  <!-- DESKTOP VIEW -->
  <tr v-else>
    <slot name="listSelectionControls" />
    <td class="thumb-cell">
      <div class="thumb-container">
        <img
          v-if="object.media_set && object.media_set.length > 0"
          :src="object.media_set[0].picture.thumbnail"
        >
      </div>
    </td>
    <td>
      <p>
        <span class="title">{{ object.title }}</span>, {{ object.year }}
      </p>
    </td>
    <td>
      <router-link :to="artistLink">
        {{ object.artist.name }}
      </router-link>
    </td>
    <td>
      <p>{{ status }}</p>
      <p>{{ published }}</p>
    </td>
    <slot name="defaultAvailableActions" />
  </tr>
</template>

<script>
import { modelRowUtil } from '@/mixins/modelRowUtil'
export default {
  name: 'ArtworkRow',
  mixins: [modelRowUtil],
  computed: {
    artistLink: function () {
      return { name: 'artist-view', params: {id: this.object.artist.id} }
    }
  }
}
</script>

<style src="@/assets/css/listrows.css" scoped></style>
