<template>
  <div
    :class="[
      'base-social-icon',
      {disabled: disabled},
      {active: active},
    ]"
    @click="onClick"
    @mouseenter="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <template v-if="href">
      <a
        :href="href"
        target="_blank"
      >
        <img
          class="icon"
          :src="icon"
        >
      </a>
    </template>
    <template v-else>
      <img
        class="icon"
        :src="icon"
      >
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseSocialIcon',
  props: {
    href: {
      type: String,
      required: false,
      default: null
    },
    network: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      mouseOver: false,
    }
  },
  computed: {
    socialNetwork: function (){
      if (this.network) return this.network
      const urlRegs = {
        twitter: /twitter|t.co/,
        facebook: /facebook|fb.com|fb.me/,
        instagram: /instagram/,
        linkedin: /linkedin|lnkd.in/,
        youtube: /youtube|youtu.be/,
        weibo: /weibo/,
        wechat: /wechat/,
      }
      const match = Object.keys(urlRegs).find((rs)=>{
        const isMatch = urlRegs[rs].test(this.href)
        // console.log(this.href, rs, isMatch)
        return isMatch
      })
      return match
    },
    icon: function (){
      const iconMap = {
        twitter: require('../assets/img/svgicons/twitter.svg'),
        facebook: require('../assets/img/svgicons/facebook.svg'),
        instagram: require('../assets/img/svgicons/instagram.svg'),
        youtube: require('../assets/img/svgicons/youtube.svg'),
        linkedin: require('../assets/img/svgicons/linkedin.svg'),
        notFound: require('../assets/img/svgicons/chrome.svg')
      }
      const hoverIconMap = {
        twitter: require('../assets/img/svgicons/twitter-white.svg'),
        facebook: require('../assets/img/svgicons/facebook-white.svg'),
        instagram: require('../assets/img/svgicons/instagram.svg'),
        youtube: require('../assets/img/svgicons/youtube.svg'),
        linkedin: require('../assets/img/svgicons/linkedin-white.svg'),
        notFound: require('../assets/img/svgicons/chrome.svg')
      }
      let foundRs = iconMap[this.socialNetwork]
      if (this.mouseOver) foundRs = hoverIconMap[this.socialNetwork]
      if (foundRs){
        return foundRs
      } else {
        return iconMap.notFound
      }
    },
  },
  methods: {
    onClick: function () {
      if (!this.disabled) this.$emit('click')
    },
  },
}
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.base-social-icon, 
.base-social-icon a{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  border: var(--default-ruler);
  border-radius: 50%;
  user-select: none;
}
.base-social-icon.disabled{
  pointer-events: none;
}
.base-social-icon.active,
.base-social-icon:hover{
  background-color: var(--primary-color);
}
.base-social-icon img {
  width: 24px;
}
</style>