<!-- 
  <BaseCauseSquare :cause-index=[0-17]>
    OR
  <BaseCauseSquare :cause-item={ full cause item }> 
-->
<template>
  <div
    :class="[
      'base-cause-square',
      { 'big-cause': bigCause },
      { colorize: hoverable },
      { 'force-color': !hoverable}
    ]"
    @click="onClick"
  >
    <template v-if="hoverable && causeItem && causeIdx != 18">
      <router-link
        :to="causeLink"
      >
        <div class="cause-image">
          <img :src="iconSrc">
        </div>
      </router-link>
    </template>
    <template v-else>
      <div class="cause-image">
        <img :src="iconSrc">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseCauseSquare',
  props: {
    causeIndex: {
      type: [Number, String],
      required: false,
      default: 0,
    },
    bigCause: {
      type: Boolean,
      required: false,
      default: false,
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: true,
    },
    causeItem: {
      type: Object,
      required: false,
      default: ()=>{return {} }
    }
  },
  data: function () {
    return { }
  },
  computed: {
    causeIdx: function () {
      return this.causeIndex || this.causeItem.id || 18
    },
    iconSrc: function () {
      if (this.causeIdx < 1 || this.causeIdx > 17) {
        return require('../../assets/img/causes_pictures/sdg.svg')
      } else {
        return require(`../../assets/img/causes_pictures/${this.causeIdx}.svg`)
      }
    },
    causeLink: function (){
      if (!this.hoverable && !this.bigCause) return '#'
      return {
        name: 'AllView',
        params: {
          itemId: this.causeIdx,
          modelName: 'cause',
          slug: this.causeItem.metadata && this.causeItem.metadata.slug || '#'
        }
      }
    }
  },
  mounted: function () {},
  methods: {
    onClick: function () {
      this.$emit('click')
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-cause-square {
  --default-width: 100px;
  box-sizing: border-box;
  height: var(--default-width);
  width: var(--default-width);
  background-color: #fff;
  color: #000;
}
.big-cause {
  --default-width: 335px;
}
.cause-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: saturate(0) brightness(0.1);
}
.colorize .cause-image img:hover,
.force-color .cause-image img{
  filter: saturate(1) brightness(1);
  cursor: var(--pointer-cursor);
}

</style>
