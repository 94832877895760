<!--
oooooooooo.
`888'   `Y8b
 888     888  .oooo.    .oooo.o  .ooooo.
 888oooo888' `P  )88b  d88(  "8 d88' `88b
 888    `88b  .oP"888  `"Y88b.  888ooo888
 888    .88P d8(  888  o.  )88b 888    .o
o888bood8P'  `Y888""8o 8""888P' `Y8bod8P'
      .o.                    .                                       oooo        
     .888.                 .o8                                       `888        
    .8"888.     oooo d8b .o888oo oooo oooo    ooo  .ooooo.  oooo d8b  888  oooo  
   .8' `888.    `888""8P   888    `88. `88.  .8'  d88' `88b `888""8P  888 .8P'   
  .88ooo8888.    888       888     `88..]88..8'   888   888  888      888888.    
 .8'     `888.   888       888 .    `888'`888'    888   888  888      888 `88b.  
o88o     o8888o d888b      "888"     `8'  `8'     `Y8bod8P' d888b    o888o o888o 

is the default component for artwork display in tap-market.
It handles all the subtleties of Artwork data guessing and display.
It accepts two slots : Buy button and Follow Button.
Animations are handled on apparition by animatedBevelMixin and base-bevelanimations.css
-->

<template>
  <div
    v-if="item"
    :class="[
      'base-artwork',
      { appear: elementIsMounted }
    ]"
  >
    <div class="item-content">
      <!-- { medium: size == 'medium' } -->
      <figure class="picture-container">
        <router-link :to="artworkLink">
          <template v-if="picture">
            <img
              ref="imgElement"
              :src="picture"
            >
            <!-- :class="{portrait: isPortrait}" -->
          </template>
          <template v-else>
            <div class="nopic" />
          </template>
        </router-link>
      </figure>
      <section
        class="details-container"
        :class="[{ appear: elementIsMounted }]"
      >
        <div class="details">
          <router-link :to="artworkLink">
            <p class="artist-name text-cdt">
              {{ item.artist && item.artist.name }}
            </p>
            <p>
              <span class="work-title">{{ item.title }}, </span>
              <span class="work-year">{{ item.year }}</span>
            </p>
            <p class="work-gallery text-cdt">
              {{ item.gallery && item.gallery.name }}
            </p>
            <p class="work-supplementary-details text-cdt">
              <!-- TODO: append unique, serie, signature, other details here -->
            </p>
            <p
              v-if="item.price != undefined && item.currency"
              class="work-price text-cdt"
            >
              {{ item.price | formatPrice(item.currency.code) }}
            </p>
          </router-link>
          <!-- TRUE follow button -->
          <div
            v-if="hasSlotFollow"
            class="follow-button"
          >
            <slot name="follow-button" />
          </div>
          <!-- MOCKED up follow button -->
          <BaseFollowButton
            v-else
            class="follow-button"
          />
        </div>
        <!-- TRUE buy button -->
        <div
          v-if="hasSlotBuy"
          class="buy-button"
        >
          <slot name="buy-button" />
        </div>
        <!-- MOCKED up buy button -->
        <BaseButton
          v-else
          class="buy-button"
          label="Buy now"
        />
      </section>
    </div>
    <!-- THE ANIMATED CURTAIN / BEVEL  -->
    <div
      class="bevel"
      :class="[{ appear: elementIsMounted }]"
    >
      <BaseBevel />
    </div>
  </div>
</template>

<script>
import {animatedBevelMixin} from '@/base/mixins/animatedBevel'

export default {
  name: 'BaseArtwork',
  mixins: [animatedBevelMixin],
  props: {
    /**
     * the artwork full item
     */
    item: {
      type: Object,
      required: true,
      default: () => {
        return null
      },
    },
    /**
     * usable values in ['medium', 'large']
     */
    size: {
      type: String,
      required: false,
      default: 'large',
    },
    /**
     * if true activates the heart
     */
    isFollowed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      // isPortrait: false,
    }
  },
  computed: {
    artworkLink: function () {
      return {
        name: 'AllView',
        params: {
          itemId: this.item.id,
          modelName: 'artwork',
          slug: (this.item.metadata && this.item.metadata.slug) || '#',
        },
      }
    },
    picture: function () {
      if (this.item.picture) return this.item.picture.web
      return (
        this.item.media_set &&
        this.item.media_set[0] &&
        this.item.media_set[0].picture.web
      )
    },
    hasSlotFollow: function () {
      return Object.keys(this.$slots).includes('follow-button')
    },
    hasSlotBuy: function () {
      return Object.keys(this.$slots).includes('buy-button')
    },
  },
  mounted: function () {
    // const hasPicture = this.$refs.imgElement != undefined
    // if (hasPicture){
    //   const isPortrait = this.$refs.imgElement.naturalWidth < this.$refs.imgElement.naturalHeight
    //   this.isPortrait = isPortrait
    // }
    // console.log(this.$refs.imgElement)
  },
  methods: {},
}
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
.default-theme .base-artwork {
  --default-width: 510px;
  --default-padding-unit: 15px;
}
.base-artwork {
  position: relative;
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  background-color: var(--white);
  width: var(--default-width);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.picture-container {
  width: 100%;
  height: var(--default-width);
  overflow: hidden;
  box-sizing: border-box;
  padding: calc(var(--default-h-padding) * 2);
  background-color: #fafafa;
}
.picture-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.picture-container img.portrait {
  height: initial;
  width: 100%;
}
.details-container {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: var(--default-padding-unit);
  padding-left: 0px;
}
.details {
  max-width: 80%;
}
.follow-button {
  position: absolute;
  top: var(--default-padding-unit);
  right: var(--default-padding-unit);
}

/* .medium .details-container {
  padding: var(--default-padding-unit) 0px 0px 0px;
  margin-bottom: var(--default-padding-unit);
  height: calc( 100% - var(--default-width) );
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.medium .details-container .base-button-container{
  width: 150px;
}
.medium .follow-button {
  top: -80px;
  right: 20px;
} */
.artist-name {
  font-family: 'Filson Pro Bold';
  text-transform: uppercase;
}
.work-title {
  font-family: 'Filson Pro Bold Italic';
}
.work-year {
  font-family: 'Filson Pro Bold';
}
.buy-button {
  margin-top: var(--default-padding-unit);
}
.small-theme .details-container {
  padding: var(--default-padding-unit) 0px;
}
p.text-cdt {
  line-height: calc(1.5 * var(--paragraph));
}
</style>
<style src="../assets/css/base-bevelanimations.css" scoped></style>