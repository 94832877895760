/*
   __       __               
  / /  ___ / /__  ___ _______
 / _ \/ -_) / _ \/ -_) __(_-<
/_//_/\__/_/ .__/\__/_/ /___/
          /_/                

  Helpers is a plugin. A plugin that helps.
  For Vue plugin documentation, please see : 
  https://vuejs.org/v2/guide/plugins.html#Writing-a-Plugin
*/

import filters from './filters.js';
import metrics from './metrics.js';

export default {
  install(Vue) {
    /* ---------- base filters ------------- */
    Object.keys(filters).forEach((fname) => {
      console.debug('Helpers: installing filter', fname);
      Vue.filter(fname, filters[fname]);
    });
    /* ---------- physical conversions ------------- */
    Object.keys(metrics).forEach((fname) => {
      console.debug('Helpers: installing converter', fname);
      Vue.filter(fname, metrics[fname]);
    });
  },
};
