<template>
  <div
    v-if="project.id"
    class="boxed-row"
  >
    <div class="boxed-cell">
      <!-- eslint-disable vue/no-v-html -->
      <h3
        class="cell-title title-level-6"
        v-html="boxTitle"
      />
      <!--eslint-enable-->
      <div class="boxed-pledge">
        <div class="boxed-cell pledge-img">
          <img :src="project.picture.web || project.picture">
        </div>
        <div class="boxed-cell">
          <template v-if="project.external_source">
            <h4 class="cell-title title-level-7">
              Donate to
            </h4>
            <p class="text-cdt">
              {{ project.name }}
              <!-- <BasePartnerLogo :partner-name="project.external_source.platform" /> -->
            </p>
          </template>
          <h4 class="cell-title title-level-7">
            Pledge to
          </h4>
          <p class="text-cdt">
            {{ project.external_source.platform }}
          </p>
        </div>
        <div class="boxed-cell">
          <h4 class="cell-title title-level-7">
            Project number
          </h4>
          <p class="text-cdt">
            #{{ project.id }}
          </p>
          <h4 class="cell-title title-level-7">
            Country
          </h4>
          <p>{{ project.country_set[0].name }}</p>
        </div>
        <!-- resume -->
      </div>
      <div 
        v-if="readMore"
        class="read-more"
      >
        <router-link
          :to="{
            name: 'project-view',
            params: { id: project.id },
          }"
        >
          <BaseCTAtxt label="Read more" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectBox',
  props: {
    project: {
      type: Object,
      required: true,
      default: () => {
        return null
      },
    },
    boxTitle: {
      type: String,
      required: false,
      default: 'Supported pledge',
    },
    readMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped>
.read-more {
  display: flex;
  justify-content: flex-end;
}
.boxed-pledge {
  flex-wrap: nowrap;
}
.mobile-theme .boxed-pledge {
  flex-direction: column;
}
.mobile-theme .review-project .pledge-img {
  width: calc(100% - 0.5 * var(--field-default-h-padding));
}
.mobile-theme .boxed-pledge .boxed-cell {
  border: none;
}
h3.cell-title {
  text-transform: uppercase;
}
.boxed-block {
  border: var(--default-ruler);
  /* margin-bottom: 30px; */
  /* margin: var(--margin-unit); */
}
.boxed-row {
  display: flex;
  border-bottom: var(--default-ruler);
  margin-bottom: var(--field-default-h-padding);
}
.boxed-row:last-child {
  border: none;
}
.boxed-cell {
  padding: calc(0.5 * var(--field-default-h-padding));
  border-right: var(--default-ruler);
  flex-grow: 1;
}
.boxed-cell:first-child {
  padding-left: 0px;
}
.boxed-cell:last-child {
  border: none;
}
h3.cell-title {
  /* font-family: 'Filson Pro Bold';
  font-size: 18px;
  letter-spacing: 1.08px;
  line-height: 23px; */
  /* text-transform: uppercase; */
  text-transform: none;
  margin-bottom: 10px;
}
h4.cell-title {
  font-family: 'Rubik Medium';
  font-size: var(--h7);
  letter-spacing: 1.5px;
  line-height: var(--h7-lh);
  text-transform: uppercase;
  /* margin-block-start: 5px; */
  margin-block-end: 5px;
  margin-block-start: 10px;
}
h4.cell-title:first-child {
  margin-block-start: 0px;
}
.text-cdt {
}
.boxed-pledge {
  display: flex;
}
.pledge-img {
  width: 25%;
  padding-top: 0px;
  padding-bottom: 0px;
}
.pledge-img img {
  width: 100%;
  object-fit: contain;
}
</style>
