<template>
  <div :class="['base-sample-component', ...styles]">
    <p>
      I'm a sample base component with inherited style from base.css
    </p>
    <p v-if="label">
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BaseSampleComponent',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    styles: {
      type: Array,
      required: false,
      default: () => { return [] }
    }
  },
  data: function () {
    return {}
  },
  methods: {}
}
</script>

<style src="../assets/css/base.css" scoped></style>
<style scoped>
p {
  font-family: sans-serif;
  text-align: center;
  background-color: var(--default-base-test-color);
}
.engaged p {
  border: 1px solid red;
}
</style>
