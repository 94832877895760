<template>
  <div class="base-rteinput-container">
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="disabled"
      id="text-area-read-only"
      v-html="value"
    />
    <!--eslint-enable-->
    <div
      v-else
      id="text-area"
      ref="textarea"
    >
      {{ content }}
    </div>
  </div>
</template>

<script>
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { baseinputs } from '../../mixins/baseinputs'

const defaultQuillConfig = {
  modules: {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  },
  theme: 'snow',
  formats: ['bold', 'underline', 'header', 'italic', 'link'],
}

const simpleQuillConfig = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['link'],
      ['clean'],
    ],
  },
  theme: 'snow',
  formats: ['bold', 'underline', 'italic', 'link'],
}

export default {
  name: 'BaseRichTextEditor',
  mixins: [baseinputs],
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Type something ...',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    simpleMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      editor: {},
      content: '',
    }
  },
  computed: {},
  watch:{
    /**
     * uses quill special ways of managing content
     */
    value: function (nv, ov) {
      if (nv && nv != this.content){
        // this.editor.root.innerHTML = this.value
        this.editor.pasteHTML(nv)
      }
    }
  },
  mounted: function () {
    // if (this.required){
    //   this.$el.querySelector('input').required = true
    // }
    if(this.disabled) {
      return null
    } else {
      this.initQuill()
    }
  },
  methods: {
    initQuill: function () {
      const config = (this.simpleMode)? simpleQuillConfig : defaultQuillConfig
      this.editor = new Quill(this.$refs.textarea, config)
      if (this.value != ''){
        /* the recommended but commented line below is responsible 
        for autofocusing the Quill editor, and unwanted page scrolling */
        // this.editor.pasteHTML(this.value)
        /* replaced by this seems to work */
        this.editor.root.innerHTML = this.value
      }
      this.editor.on('text-change', (delta, oldDelta, source) => {
        // console.debug('BASERTE: text-change', delta, oldDelta, source)
        if (source == 'user') this.onInput()
      })
    },
    onInput: function () {
      const htmlContent = this.editor.getText() ? this.editor.root.innerHTML : ''
      // if (this.content != htmlContent){
        // console.debug('BASERTE: emit input')
        this.content = htmlContent
        this.$emit('input', htmlContent)
      // }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-rteinput-container{
  width: 100%;
}
#text-area {
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  width: 100%;
  overflow-y: auto;
  white-space: pre-wrap;
  height: 250px;
}
 #text-area-read-only {
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  line-height: 1.42;
  overflow-y: auto;
  padding: 12px 15px;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: rgb(250,250,250);
  height: 250px;
}
.small-rte.base-input-container #text-area,
.small-rte.base-input-container #text-area-read-only{
  height: 50px;
  resize: vertical;
}
.base-rteinput-container >>> .ql-editor::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
.base-rteinput-container >>> .ql-editor::-webkit-scrollbar-track {
  background-color: var(--white);
}
.base-rteinput-container >>> .ql-editor::-webkit-scrollbar-thumb {
  background-color: var(--grey-medium);
}
.base-rteinput-container >>> .ql-editor {
  scrollbar-width: thin; /* pixel values still bug */
  scrollbar-color: var(--black) var(--grey-medium);
}
</style>
