<template>
  <div :class="['base-textinput-container', { disabled: disabled }, ...styles]">
    <input
      type="number"
      :placeholder="placeholder"
      v-bind="$attrs"
      :value="value"
      :disabled="disabled"
      v-on="inputListeners"
    >
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'

export default {
  name: 'BaseNumberInput',
  mixins: [baseinputs],
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    placeholder: {
      type: [String, Number],
      required: false,
      default: 'Enter a value...',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {}
  },
  computed: {
    inputListeners: function () {
      let vm = this
      return {
        ...this.$listeners,
        input: function (event) {
          vm.$emit('input', Number(event.target.value))
        },
      }
    },
  },
  methods: {},
}
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
</style>
