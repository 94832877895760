<!--
  BaseUploadImage is an input like the others.
  V-MODEL-compatible, it handles 'input', 'focus' and 'blur' events.
  Only needed prop is the v-model received here as `value`
-->
<template>
  <!-- READ-ONLY picture display -->
  <div 
    v-if="disabled"
    class="base-upload-image"
  >
    <div class="image-zone">
      <div
        v-if="value"
        class="image-preview"
      >
        <img
          :src="value.screen || value.file || value.source || value"
        >
      </div>
    </div>
  </div>
  <div 
    v-else
    class="base-upload-image"
  >
    <!-- ---- TOOL BAR ---- -->
    <div class="tool-bar">
      <button
        :disabled="isDisabled"
        @click="changeScale(1)"
      >
        Zoom in
        <img src="../../assets/img/svgicons/zoom-in.svg">
      </button>
      <button
        :disabled="isDisabled"
        @click="changeScale(-1)"
      >
        Zoom out
        <img src="../../assets/img/svgicons/zoom-out.svg">
      </button>
      <!-- ---- button to delete file ---- -->
      <button
        :disabled="!value"
        @click="deletePicture"
      >
        Delete
        <img src="../../assets/img/svgicons/trash-2.svg">
      </button>
      <!-- ---- button to change file ---- -->
      <button
        :disabled="!value"
        @click="addPicture"
      >
        Replace
        <img src="../../assets/img/svgicons/rotate-ccw.svg">
      </button>
      <button
        :disabled="isDisabled"
        @click="validPicture"
      >
        Validate
        <img src="../../assets/img/svgicons/check.svg">
      </button>
    </div>
    <div
      class="image-zone"
      :style="[
        isDisabled ? {'height': '100%'} : {'height': '80vh'}
      ]"
    >
      <!-- FILE INPUT here, will open OS file selector on click -->
      <input
        id="image-input"
        ref="imageInput"
        type="file"
        @change="onFileChange"
      >
      <!-- ---- IMAGE PREVIEW ---- -->
      <div
        v-if="value"
        class="image-preview"
      >
        <img
          v-if="isDisabled || disabledResize"
          :src="value.screen || value.file || value.source || value"
        >
      </div>
      <div
        v-if="!imgNotFound"
        class="image-preview"
        @mouseup="storeTempCrop"
        @mousewheel="storeTempCrop"
      >
        <VueCropper
          v-if="!isDisabled"
          ref="cropper"
          :img="img"
          :output-size="cropperOptions.size"
          :output-type="cropperOptions.outputType"
          :full="cropperOptions.full"
          :auto-crop="cropperOptions.autoCrop"
          :can-move="cropperOptions.canMove"
          :can-move-box="cropperOptions.canMoveBox"
          :can-scale="cropperOptions.canScale"
          :center-box="cropperOptions.centerBox"
          :fixed="cropperOptions.fixed"
          :fixed-box="cropperOptions.fixedbox"
          :fixed-number="fixedNumber"
          :original="cropperOptions.original"
          mode="cover"
          @imgLoad="storeTempCrop"
        />
      </div>
      <!-- ---- button to add file ---- -->
      <button
        v-if="!value && !img"
        class="btn plus"
        @click="addPicture"
      >
        <img src="../../assets/img/svgicons/plus.svg">
      </button>
    </div>
  </div>
</template>

<script>
import { baseinputs } from '../../mixins/baseinputs'
import {VueCropper} from 'vue-cropper'

export default {
  name: 'BaseUploadImage',
  components: {
    VueCropper,
  },
  mixins: [baseinputs],
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return null
      },
    },
    disabledResize: {
      type: Boolean,
      require: false,
      default: () => {
        return false
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     *  OPTIONS : portrait or grid-picture
     */
    ratioType: {
      type: String,
      required: false,
      default: () => {
        return null
      },
    },
  },
  /**
   * @param {Number} nv - Crop the quality of the generated image (0.1 - 1)
   * @param {string} outputType - Crop the format of the generated image (jpeg || png || webp)
   * @param {boolean} full - Screenshot of whether to output the original map scale (true | false)
   * @param {boolean} autoCrop -  Whether to generate a screenshot box by default (true || false)
   * @param {boolean} canMoveBox - Can the screenshot box be dragged? (true || false)
   * @param {boolean} canScale - Whether the image allows the wheel to zoom (true || false)
   * @param {boolean} centerBox - Is the screenshot box restricted to the image? (true || false)
   * @param {boolean} fixed - Whether to open the screenshot frame width and height fixed ratio (true || false)
   * @param {boolean} fixedbox - Fixed screenshot frame size is not allowed to change (true || false)
   * @param {boolean} original - Upload images are rendered in raw scale (true || false)
   * mode : img render mode (contain , cover, 100px, 100% auto)
   * 
   * @param {string} img - Picture address (url address || base64 || blob)
   * @param {Object} files - to keep track of filename
   * @param {boolean} isDisabled - if false enable vueCropper
   * @param {boolean} imgNotFound - true = found image, false = img not found
   * @param {string} temporaryCrop - BaseData64 of cropped image
   * @param {boolean} isValidFormat - true if file is (jpg, jpeg, png, JPG, PNG)
   * 
   */
  data: function () {
    return {
      cropperOptions: {
        size: 1, 
        outputType: '', 
        full: true, 
        autoCrop: true,
        canMove: true,
        canMoveBox: true, 
        canScale: true, 
        centerBox: true, 
        fixed: false, 
        fixedbox: false, 
        original: true, 
      },
      img: '', 
      files: '',  
      isDisabled: true,
      imgNotFound: true,
      temporaryCrop: undefined,
      isValidFormat: true,
    }
  },
  computed: {
    /**
     * The aspect ratio of the screenshot box ([1 : 1][width : height])
     * OPTION : portrait (4,3) = 4:3, grid-picture (5,2) = 16:9
     * IF null ratioType is disabled then the resize window is not blocked
     */
    fixedNumber: function () {
      const mapping = { 'portrait': [4, 3], 'grid-picture': [5, 2] }
      if(this.ratioType != null){
        this.$set(this.cropperOptions, 'fixed', true)
        this.$set(this.cropperOptions, 'fixedbox', true)
        this.$set(this.cropperOptions, 'canMoveBox', false)
      }
      return mapping[this.ratioType] || null
    },
  },
  watch: {
    /**
      * the two conditions are true if update comes from top to bottom:
      * => form 'cancel' button was pressed, reset the component
      * the two conditions are false if update comes from bottom to top
      * => vue-cropper sent an event, do nothing (save is handled in
      * storeTempCrop to store cropped image in the form + form 'save'
      * to actually send the data)
      * @param {Object} nv - new value
      * @param {Object} ov - old value
     */
    value: function (nv, ov) {
      if(nv){
        if (this.temporaryCrop != undefined && nv.file != this.temporaryCrop) {
          this.$set(this, 'img', '')
          this.$set(this, 'temporaryCrop', undefined)
          this.resetCropper()
        }
      } else {
        if (this.temporaryCrop != undefined && nv != this.temporaryCrop) {
          this.$set(this, 'img', '')
          this.$set(this, 'temporaryCrop', undefined)
          this.resetCropper()
        }
      }
    },
  },
  methods: {
    /**
      * vue-cropper significant events:
      * - when image is loaded
      * - when cropping or move is done with the mouse (mouseup)
      * - when scaling is done using the mouse (mousewheel)
      * - when scaling is done using the '+' and '-' buttons (TODO) 
     */
    storeTempCrop: function() {
      this.$refs.cropper.getCropData((data) => {
        this.temporaryCrop = data
        this.$emit('input', { file: this.temporaryCrop, name: this.files[0].name })
      })
    },
    /**
      * '+' button
      * loads the picture (using browser toolbox) but does not save the form 
      * note: input file selection event not firing upon selecting the same file
      * so need to reset the value to ""
    */
    addPicture: function () {
      this.$emit('focus')
      this.$refs.imageInput.value = ''      
      this.$refs.imageInput.click()
    },
    /**
      * 'cancel' button
      *  removes the picture but does not save the form 
     */
    deletePicture: function () {
      this.$emit('input', null)
    },
    /**
      * hidden input
      * uploads the file and loads it into vue-cropper
      * also does some validation (format, etc)
      * @param {Object} event - file object
     */
    onFileChange: function (event) {
      this.files = event.target.files || event.dataTransfer.files
      if (!this.files.length) {
        console.debug('no files')
        this.$emit('blur')
      } else if(this.checkValidFormat(event.target.value)){
        let fr = new FileReader()
        fr.onloadend = (e) => {
          if (this.disabledResize === true) {
            this.$emit('input', {
              file: e.target.result,
              name: this.files[0].name,
            })
            this.$emit('blur')
          } else {
            this.$set(this.cropperOptions, 'outputType', this.files[0].type.split('/')[1])
            this.$set(this, 'img', e.target.result)
            !this.isValidFormat ? this.$emit('valid') : null
            this.openCropper()
          }
          // notes ab
          //
          // TODO 2: Avoid base64 encoding (readAsBinary instead of readAsDataURL)
          // requires API + front modifications but will be necessary for big images
          // to save ~30% payload
        }
        fr.readAsDataURL(this.files[0])
      }
    },
    /**
      * Zoom in, Zoom out fonction
      * @param {number} num - +1 or -1
     */
    changeScale(num) {
      num = num || 1
      this.$refs.cropper.changeScale(num)
      this.storeTempCrop()
    },
    /**
     * Emit valid 
     * Save picture
     * Hide cropper
    */
    validPicture(){
      this.$emit('valid')
      this.storeTempCrop()
      this.resetCropper()
    },
    /**
      * Open cropper
     */
    openCropper() {
      this.$set(this, 'imgNotFound', false)
      this.$set(this, 'isDisabled', false)
    },
    /**
      * Hide cropper by resetting this params
     */
    resetCropper() {
      this.$set(this, 'imgNotFound', true)
      this.$set(this, 'isDisabled', true)
    },
    /**
      * Checks the validity of the format (jpg, jpeg, png, JPG, PNG)
      * @param {string} value - name of file
      * 
     */
    checkValidFormat(value){
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(value)) {
        this.$emit('invalid', "Allowed format : jpg, png, jpeg")
        return this.isValidFormat = false
      }else {
        return true
      }
    },
  },
}
</script>

<style src="../../assets/css/base.css" scoped></style>

<style scoped>
.base-upload-image{
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.image-zone {
  position: relative;
  box-sizing: border-box;
  border: 1px dashed #707070;
  min-width: min(100%, 466px);
  min-height: 350px;
  width: 100%;
  /* max-width: 618px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  display: none;
}
.image-preview {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.image-preview-cropper {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.image-preview img {
  max-width: 100%;
  max-height: 100%;
}
.tool-bar {
  display: flex;
  align-items: center;
  z-index: 2;
  flex-wrap: wrap;
}
.tool-bar > * {
  flex: 1 1 0;
}
.btn {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  filter: none;
  cursor: pointer;
  background-color: var(--white);
}
.tool-bar button {
  font-family: 'Rubik Regular';
  font-size: var(--paragraph);
  width: 136px;
  display: flex;
  height: var(--default-button-height);
  padding: var(--field-default-padding);
  border: 1px solid var(--ruler-color);
  justify-content: space-evenly;
  align-items: center;
  white-space: nowrap;
  filter: none;
  cursor: pointer;
  background-color: var(--white);
}
.tool-bar button img{
  width: 16px;
  height: 16px;
}
.tool-bar button[disabled]{
  border: 1px solid white;
  background-color: #dbdbdb;
  color: #959595;
}
.tool-bar button[disabled] img{
  filter: invert(55%);
}
.btn:hover {
  background-color: #7b7b7b80;
}
.plus {
  opacity: 1;
  border: 1px dashed #707070;
}
.has-error .btn.plus {
  background-color: var(--error-color);
}
</style>
