<template>
  <div class="top-bar-admin">
    <div class="bar-content">
      <router-link
        :to="{name:'dashboard'}"
        class="top-bar-logo"
      >
        <BaseLogo :small-screen="smallScreen" />
      </router-link>
      <MenuAdmin v-if="$store.state.user.isAuthenticated" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MenuAdmin from '@/components/MenuAdmin'
export default {
  name: 'TopBarAdmin',
  components: {
    MenuAdmin,
  },
  data: function () {
    return {}
  },
  computed: {
    ...mapState('ui', {
      smallScreen: (state) => state.isSmall,
    }),
  },
  mounted: function () {
  },
  methods: {},
}
</script>

<style scoped>
.top-bar-admin {
  box-sizing: border-box;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}
.top-bar-logo{
  width: 192px;
}
.small-theme .top-bar-logo{
  width: 120px;
}
.bar-content {
  margin: auto;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  border-bottom: 1px solid black;
  display: flex;
}
/* simple rules to visually differentiate prod / preprod / dev */
.debug-theme .top-bar-admin {
  filter: hue-rotate(-90deg);
  background: repeating-linear-gradient(
      45deg,
      #f1f1f1,
      #f1f1f1 10px,
      #f1f1f1 16px,
      #ffffff 20px
  );
}
</style>
