<template>
  <div :class="['base-radio', ...styles]">
    <template v-for="(option, oIdx) in options">
      <div 
        :key="'op-'+oIdx"
        :class="['radio-option']" 
        @click="selectOption(option)"
      > 
        <div :class="['circle', {picked:(option == selectedOption)}]">
          <div class="dot" />
        </div>
        <div
          v-if="option.label"
          class="label"
          @mouseenter="showHelpText(oIdx)"
          @mouseleave="hideHelpText(oIdx)"
        >
          {{ option.label }}
        </div>
        <div 
          v-if="option.helpText"
          :class="['help-text', {'show-help-text': option.showHelpText }]"
        >
          <!-- eslint-disable vue/no-v-html -->
          <p v-html="option.helpText" />
          <!--eslint-enable-->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {baseinputs} from '../../mixins/baseinputs'

export default {
  name: 'BaseRadio',
  mixins: [baseinputs],
  props: {
    /**
     * Received v-model value
     */
    value: {
      type: [Object, String, Number],
      required: false,
      default: null
    },
    /**
    * Field label
    */
    label: {
      type: String,
      required: false,
      default: null
    },
    /**
      * (optional)
      * Provides help texts for each option
    */
    options: {
      type: Array,
      required: false,
      default: () => { return [] }
    },
  },
  data: function () {
    return {
      newValue: {},
    };
  },
  computed: {
    selectedOption: function(){
      const s = this.options.find(o=>o.value == this.value)
      return s
    },
  },
  mounted: function(){},
  methods: {
    selectOption: function(option){
      this.newValue = option
      this.$emit('input', this.newValue.value)
    },
    hideHelpText: function(index) {
      this.options[index].showHelpText = false
    },
    showHelpText: function(index){
      this.options[index].showHelpText = true
    },
  },
};
</script>

<style src="../../assets/css/base.css" scoped></style>
<style scoped>
.base-radio{
  display: flex;
  flex-wrap: wrap;
  padding-top: 3px;
  --default-circle-height: 14px;
  --picked-margin: 2px;
  --circle-color: var(--ruler-color);
}
.base-radio.large {
  --default-circle-height: 20px;
  --picked-margin: 1px;
}
.radio-option{
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.circle{
  height: var(--default-circle-height);
  width: var(--default-circle-height);
  border: 1px solid var(--circle-color);
  border-radius: calc(var(--default-circle-height));
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.picked {
  border-color: var(--primary-color);
}
.picked .dot{
  height: calc((var(--default-circle-height) - var(--picked-margin)) / 2);
  width: calc((var(--default-circle-height) - var(--picked-margin)) / 2);
  background-color: var(--primary-color);
  border-radius: calc(var(--default-circle-height));
}
.label{
  font-family: 'Rubik Regular';
  font-size: var(--h7);
  margin-right: 40px;
}
.help-text{
  position: absolute;
  top: 100%;
  max-height: 100px;
  opacity: 0.0;
  box-sizing: border-box;
  background-color: #7B7B7B;
  box-shadow: 0px 0px 10px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1rem;
  transition: 0.3s linear;
  z-index: -1;
}
.help-text p{
  line-height: 0.9rem;
  vertical-align: middle;
  font-family: 'Rubik Medium';
  font-size: 0.75rem;
  letter-spacing: 0.09rem;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
}
.show-help-text{
  opacity: 1.0;
  z-index: 5; /* arbitrarily high */
}
</style>
