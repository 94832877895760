<template>
  <div class="errors text-cdt">
    <template v-if="Array.isArray(errors)">
      <p
        v-for="(err, errIdx) in errors"
        :key="`errmess-${errIdx}`"
      >
        {{ err }}
      </p>
    </template>
    <template 
      v-for="(err, eIdx) in Object.keys(errors)"
      v-else
    >
      <template v-if="Array.isArray(errors[err])">
        <p
          v-for="(errmessage, emIdx) in errors[err]"
          :key="`errmess-${eIdx}-${emIdx}`"
        >
          {{ errmessage }}
        </p>
      </template>
      <p
        v-else
        :key="'errf-' + eIdx"
      >
        {{ err }} : {{ errors[err] }}
      </p>
    </template>
  </div>
</template>

<script>
export default{
  name: 'BaseNonFieldErrors',
  props: {
    errors: {
      type: [Object, Array, String],
      required: true,
      default: ()=>{ return null}
    }
  }
}
</script>

<style scoped>
p{
  /* text-transform: capitalize; */
}
</style>